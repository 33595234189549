<ng-container *ngIf="isInput else pipeValue">
  <ion-input #input
             [label]="label" [labelPlacement]="labelPlacement" [value]="value"
             [disabled]="isDisabled"
             (ionInput)="onInput($event)" (ionChange)="switchToPipeValue()" (ionBlur)="switchToPipeValue()">
  </ion-input>
</ng-container>

<ng-template #pipeValue>
  <ion-input [label]="label" [labelPlacement]="labelPlacement" [value]="formattedValue"
             [disabled]="isDisabled"
             [readonly]="true" (click)="switchToInput()">
  </ion-input>
</ng-template>
