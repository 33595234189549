<ion-content class="main" *ngIf="missionDetail$ | async as missionDetail">
  <!--resume-->
  <app-tech-inventory-mission-detail-resume [missionDetail]="missionDetail"></app-tech-inventory-mission-detail-resume>
  <!--description-->
  <app-tech-inventory-mission-detail-description
    [missionDetail]="missionDetail"
  ></app-tech-inventory-mission-detail-description>
  <!--custom-values-->
  <app-tech-inventory-mission-detail-custom-values-read

  ></app-tech-inventory-mission-detail-custom-values-read>
  <!--  attachments-->
  <ion-card *ngIf="missionDetail.attachments && missionDetail.attachments.length > 0">
    <ion-card-content>
      <ion-card-title color="dark">
        {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.ATTACHMENTS' | translate }}
      </ion-card-title>

      <app-attachments [designType]="DesignTypeEnum.Box" [attachmentsInc]="missionDetail.attachments"
                       [showHeader]="false"></app-attachments>
    </ion-card-content>
  </ion-card>
</ion-content>
