import {Component} from '@angular/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {IonicModule, ModalController} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {NgForOf, NgIf} from "@angular/common";
import {SharedTenantFilterPipe} from "@shared/pipes/shared-tenant-filter.pipe";

@Component({
  selector: 'app-shared-confirm-leave-modal',
  templateUrl: './shared-confirm-leave-modal.component.html',
  styleUrls: ['./shared-confirm-leave-modal.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    NgForOf,
    SharedTenantFilterPipe,
    NgIf
  ],
  standalone: true,
})
export class SharedConfirmLeaveModalComponent {

  constructor(
    private _modalController: ModalController,
    private _sharedModalControllerService: SharedModalControllerService
  ) {
  }

  answer(response: boolean) {
    this._modalController.dismiss({ confirmed: response });
  }

  exit(): void {
    this._sharedModalControllerService.closeModal();
  }
}
