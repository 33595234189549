import {Injectable} from "@angular/core";
import {IssueContentType, IssueType} from "@server-models";
import {IssueBaseServiceInterface} from "@shared/components/issues/interfaces/issue-base-service.interface";
import {TechIssuesMessageApiService} from "@tech/pages/issues/services/tech-issues-message-api.service";
import {TechIssuesMissionApiService} from "@tech/pages/issues/services/tech-issues-mission-api.service";
import {TechIssuesTicketApiService} from "@tech/pages/issues/services/tech-issues-ticket-api.service";
import {
  TechIssuesWinterhalterServiceCallApiService
} from "@tech/pages/issues/services/winterhalter/service-call-api.service";
import {
  TechIssuesWinterhalterProductOrderApiService
} from "@tech/pages/issues/services/winterhalter/product-order-api.service";

@Injectable({
  providedIn: 'root',
})
export class TechIssuesService {
  constructor(
    private _techIssuesMessageApiService: TechIssuesMessageApiService,
    private _techIssuesMissionApiService: TechIssuesMissionApiService,
    private _techIssuesTicketApiService: TechIssuesTicketApiService,
    private _techIssuesWinterhalterServiceCallApiService: TechIssuesWinterhalterServiceCallApiService,
    private _techIssuesWinterhalterProductOrderService: TechIssuesWinterhalterProductOrderApiService,
  ) {

  }

  getApiServiceByIssueType(issueType: IssueType): IssueBaseServiceInterface {
    switch (issueType) {
      case IssueType.Message:
        return this._techIssuesMessageApiService;
      case IssueType.Mission:
        return this._techIssuesMissionApiService;
      case IssueType.Ticket:
        return this._techIssuesTicketApiService;
      case IssueType.WinterhalterServiceCall:
        return this._techIssuesWinterhalterServiceCallApiService;
      case IssueType.WinterhalterProductOrder:
        return this._techIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue type: ${issueType}`);
    }
  }

  getApiServiceByIssueContentType(issueContentType: IssueContentType): IssueBaseServiceInterface {
    switch (issueContentType) {
      case IssueContentType.Message:
        return this._techIssuesMessageApiService;
      case IssueContentType.Mission:
        return this._techIssuesMissionApiService;
      case IssueContentType.Ticket:
        return this._techIssuesTicketApiService;
      case IssueContentType.WinterhalterServiceCall:
        return this._techIssuesWinterhalterServiceCallApiService;
      case IssueContentType.WinterhalterProductOrder:
        return this._techIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue content type: ${issueContentType}`);
    }
  }


}
