import {Component, OnInit} from '@angular/core';
import {MissionDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";
import {DesignTypeEnum} from "@shared/components/attachments/enums/design-type.enum";
import {
  TechInventoryMissionDetailViewCustomValuesReadComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/mission-detail-view/components/custom-values-read/custom-values-read.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {TranslateModule} from "@ngx-translate/core";
import {
  TechInventoryMissionDetailViewResumeComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/mission-detail-view/components/resume/resume.component";
import {
  TechInventoryMissionDetailViewDescriptionComponent
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/mission-detail-view/components/description/description.component";

@Component({
  selector: 'app-tech-inventory-mission-detail-page',
  templateUrl: './mission-detail.page.html',
  styleUrls: ['./mission-detail.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TechInventoryMissionDetailViewResumeComponent, TechInventoryMissionDetailViewDescriptionComponent, TechInventoryMissionDetailViewCustomValuesReadComponent, AttachmentsComponent, TranslateModule],

})
export class TechInventoryMissionDetailPage implements OnInit {

  protected readonly DesignTypeEnum = DesignTypeEnum;
  missionDetail$: Observable<MissionDto>;

  constructor(
    private _store: Store,
  ) {
    this.missionDetail$ = of();
  }

  ngOnInit() {
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
  }

}
