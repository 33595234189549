import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TechSideBarState} from "@tech/components/side-bar/store/tech-side-bar.state";

export const techSideBarStoreSelect = createFeatureSelector<TechSideBarState>('sideBar');

export const selectSideBar = createSelector(
  techSideBarStoreSelect,
  (state: TechSideBarState) => state
);

export const selectSideBarIsLoading = createSelector(
  techSideBarStoreSelect,
  (state: TechSideBarState) => state.isLoading
);

export const selectSideBarIsLoadingId = createSelector(
  techSideBarStoreSelect,
  (state: TechSideBarState) => state.isLoadingId
);

export const selectSideBarLogo = createSelector(
  techSideBarStoreSelect,
  (state: TechSideBarState) => state.logo
);
