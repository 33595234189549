import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";
import {
  IssueCustomPropertySetFormatted
} from "@shared/components/issues/interfaces/issue-custom-property-set-formatted.interface";
import {
  HeaderDesignEnum
} from "@shared/components/issues/components/custom/read/property-sets/enums/header-design.enum";
import {
  IssueCustomPropertySetsReadComponent
} from "@shared/components/issues/components/custom/read/property-sets/issue-custom-property-sets-read.component";

@Component({
  selector: 'app-tech-inventory-mission-detail-custom-values-read',
  templateUrl: './custom-values-read.component.html',
  styleUrls: ['./custom-values-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, IssueCustomPropertySetsReadComponent],

})
export class TechInventoryMissionDetailViewCustomValuesReadComponent implements OnInit {
  protected readonly HeaderDesignEnum = HeaderDesignEnum;
  customPropertySetFormattedList$: Observable<IssueCustomPropertySetFormatted[]>;

  constructor(
    private _store: Store
  ) {
    this.customPropertySetFormattedList$ = of();

  }

  ngOnInit() {
    this.customPropertySetFormattedList$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionCustomPropertySetFormattedList));
  }

}
