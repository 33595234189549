import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-tech-inventory-mission-type-options',
  templateUrl: './tech-inventory-mission-type-options.component.html',
  styleUrls: ['./tech-inventory-mission-type-options.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],

})
export class TechInventoryMissionTypeOptionsComponent implements OnInit {

  @Input() stereotypeNameType: string;

  constructor(
  ) {
    this.stereotypeNameType = ""
  }

  ngOnInit() {
  }

}
