import {Component, Input} from '@angular/core';
import {MissionDto} from '@server-models';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {SharedCapacitorBrowserService} from '@shared/services/capacitor/browser/shared-capacitor-browser.service';

@Component({
  selector: 'app-tech-inventory-mission-detail-container-ship-address-modal',
  templateUrl: './tech-inventory-mission-detail-container-ship-address-modal.component.html',
  styleUrls: ['./tech-inventory-mission-detail-container-ship-address-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  providers: [SharedModalControllerService, SharedCapacitorBrowserService]
})
export class TechInventoryMissionDetailContainerShipAddressModalComponent {
  @Input() missionDetail: MissionDto;

  constructor(
    private _modalService: SharedModalControllerService,
    private _cBrowser: SharedCapacitorBrowserService
  ) {
    this.missionDetail = {};
  }

  onCloseModal(): void {
    this._modalService.closeModal();
  }

  onOpenGoogleMaps(): void {
    let query: string;
    const point = this.missionDetail.shippingAddress?.point;
    const address = this.missionDetail.shippingAddress;

    if (point?.lat && point.lat > 0 && point?.lng && point.lng > 0) {
      query = `${ point.lng },${ point.lat }`;
    } else {
      query = `${ address?.street ?? '' } ${ address?.houseNumber ?? '' }, ${ address?.zipcode ?? '' }, ${ address?.city ?? '' }, ${ address?.countryCode ?? '' }`;
    }

    const url = 'https://maps.google.com/?q=' + encodeURIComponent(query);
    this._cBrowser.open(url);
  }
}
