import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SideBarState} from "@tech/components/side-bar/store/side-bar.state";

export const selectSideBarStore = createFeatureSelector<SideBarState>('sideBar');

export const selectSideBar = createSelector(
  selectSideBarStore,
  (state: SideBarState) => state
);

export const selectSideBarIsLoading = createSelector(
  selectSideBarStore,
  (state: SideBarState) => state.isLoading
);

export const selectSideBarIsLoadingId = createSelector(
  selectSideBarStore,
  (state: SideBarState) => state.isLoadingId
);

export const selectSideBarLogo = createSelector(
  selectSideBarStore,
  (state: SideBarState) => state.logo
);
