import {Component, OnInit} from '@angular/core';
import {MissionDto, MissionState} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {ButtonTechSideMenuComponent} from "@tech/components/button-tech-side-menu/button-tech-side-menu.component";
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of, switchMap} from "rxjs";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";
import {AssignedUserEnum} from "@tech/pages/inventory/enums/segment-settings.emun";
import {FormsModule} from "@angular/forms";
import {map} from "rxjs/operators";
import {
  TechInventoryMissionPagesService
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/services/mission-pages.service";

@Component({
  selector: 'app-tech-inventory-mission-detail',
  templateUrl: './tech-inventory-mission-detail.component.html',
  styleUrls: ['./tech-inventory-mission-detail.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ButtonTechSideMenuComponent, RouterOutlet, FormsModule],

})
export class TechInventoryMissionDetailComponent implements OnInit {
  protected readonly MissionState = MissionState;
  missionDetail$: Observable<MissionDto>;
  missionId$: Observable<string | null>;
  isLoading$: Observable<boolean>;

  protected readonly AssignedUserEnum = AssignedUserEnum;

  constructor(
    private _store: Store,
    private _translateService: TranslateService,
    public missionPagesService: TechInventoryMissionPagesService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.missionDetail$ = of();
    this.missionId$ = this._activatedRoute.paramMap.pipe(
      map((params) => {
        return params.get('id')
      }),
      switchMap((id) => id ? of(id) : of(null))
    );
    this.isLoading$ = of();
  }

  ngOnInit() {
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
    this.isLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionLoading));

    this.missionId$.subscribe((missionId) => {
      if (missionId) {
        const urlSegments = this._router.url.split('/');
        // Find the index of the missionId
        const idIndex = urlSegments.findIndex(segment => segment === missionId);

        if (idIndex !== -1) {
          const existingUrl = urlSegments.slice(idIndex + 1);
          this._store.dispatch(TechInventoryActions.getMissionDetailById({ missionId: missionId, reportDemand: false, existingUrl  }));

        }
      }
    });
  }

  formatTitle(id: number): string {
    return `${this._translateService.instant('COMPONENTS.MISSIONS.TITLE_ONE')} #${id ? id : ''}`;
  }

  back() {
    this.missionPagesService.resetSegment();
    this._store.dispatch(TechInventoryActions.getMissionReportPreviewByIdReset());
    this._store.dispatch(TechInventoryActions.navigateToInventory());
  }

}
