<ion-card>
  <ion-card-content>
    <!--    Mission State-->
    <app-tech-inventory-mission-state-actions
      [stateOption]="model.state!"
      [currentMissionId]="model.missionId!">
    </app-tech-inventory-mission-state-actions>
    <div class="spacer-1rem"></div>
    <ion-row>
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
        <ion-item class="status">
          <ion-button slot="start" fill="outline">
            <ion-icon name="hammer"></ion-icon>
          </ion-button>
          <ion-input [label]="'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.RESOURCE' | translate"
                     labelPlacement="stacked" [value]="model.resource?.name"
                     [readonly]="true"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
        <ion-item class="status" (click)="onShowLocationModal()">
          <ion-button slot="start" fill="outline">
            <ion-icon name="location"></ion-icon>
          </ion-button>
          <ion-input [label]="'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.LOCATION' | translate"
                     labelPlacement="stacked"
                     [value]="prepareLocationValue(model.location?.name, model.location?.locationNumber)"
                     [readonly]="true"></ion-input>

        </ion-item>
      </ion-col>
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
        <ion-item class="status" (click)="onShowAssignEditorModal()">
          <ion-button slot="start" fill="outline">
            <ion-icon name="person"></ion-icon>
          </ion-button>
          <ion-input [label]="'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.EDITOR' | translate" labelPlacement="stacked"
                     [value]="prepareEditorValue(model.solutionContact?.displayName)" [readonly]="true"></ion-input>

        </ion-item>
      </ion-col>
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
        <ion-item class="status">
          <ion-button slot="start" fill="outline" color="medium" disabled>
            <ion-icon name="calendar-clear-outline" size="small"></ion-icon>
          </ion-button>
          <ion-input [label]="'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.DATE.PLANNED.START' | translate"
                     labelPlacement="stacked"
                     [value]="techInventoryService.formatDate(model.plannedStart!) + '.'"
                     [readonly]="true"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
        <ion-item class="status">
          <ion-button slot="start" fill="outline" color="medium" disabled>
            <ion-icon name="calendar-clear-outline" size="small"></ion-icon>
          </ion-button>
          <ion-input [label]="'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.DATE.PLANNED.END' | translate"
                     labelPlacement="stacked" [value]="techInventoryService.formatDate(model.plannedEnd!) + '.'"
                     [readonly]="true"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>
