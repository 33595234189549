import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MissionIssueDto, MissionIssuePreviewDto, SubmitMissionIssueDto} from "@server-models";
import {TechIssuesApiService} from "@tech/pages/issues/services/tech-issues-api.service";

@Injectable({
  providedIn: 'root',
})
export class TechIssuesMissionApiService extends TechIssuesApiService {

  override getIssueTemplatePreviewListById(id: number): Observable<MissionIssuePreviewDto> {
    return super.get<MissionIssuePreviewDto>(`/tech/v2/issue/mission/template/${id}/preview`, undefined);
  }

  override getIssueById(issueId: number): Observable<MissionIssueDto> {
    return super.get<MissionIssueDto>(`/tech/v2/issue/mission/${issueId}`);
  }

  override sendIssueTemplatePreviewById(templateId: number, issue: SubmitMissionIssueDto): Observable<void> {
    return super.post<void>(`/tech/v2/issue/mission/template/${templateId}/submitIssue`, undefined, issue)
  }
}
