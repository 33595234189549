import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {InfoCardComponent} from '@team/pages/infos/components/info-card/info-card.component';
import {ButtonTeamSideMenuComponent} from '@team/components/button-team-side-menu/button-team-side-menu.component';
import {InputDropdownComponent} from '@shared/components/custom-property/inputs/dropdown/input-dropdown.component';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

@Component({
  selector: 'app-tech-inventory-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  standalone: true,
  providers: [
    CurrencyPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TechInventoryCurrencyInputComponent),
      multi: true
    }
  ],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    InfoCardComponent,
    ButtonTeamSideMenuComponent,
    InputDropdownComponent,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class TechInventoryCurrencyInputComponent implements OnInit, ControlValueAccessor {

  @ViewChild('input') input!: { setFocus: () => void; };

  @Input() label!: string;
  @Input() labelPlacement!: string;
  @Input() disabled: boolean = false;
  @Input() control: AbstractControl | null = null;

  @Input() set value(value: number | string) {
    value = +value.toString().replace(',', '.');
    if (!isNaN(value)) {
      this.rawValue = value;
      this.formattedValue = this.formatCurrency(this.rawValue.toString());
      this.control?.setValue(value);
    }
  };

  get value(): number {
    return this.rawValue;
  }

  rawValue: number = 0;
  formattedValue: string = '';

  isInput: boolean = true;

  constructor(
    private currencyPipe: CurrencyPipe,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.isInput = this.formattedValue === '' && !this.disabled;
  }

  formatCurrency(value: string): string {
    return this.currencyPipe.transform(value, 'EUR', true, '1.2', this.translateService.currentLang) ?? '';
  }

  switchToInput(): void {
    this.isInput = true;
    setTimeout(() => this.input.setFocus());
  }

  onInput(event: CustomEvent): void {
    this.value = event.detail.value ?? 0;
    this.input.setFocus();
  }

  switchToCurrency(): void {
    this.isInput = false;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = () => fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
