import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, mergeMap, of, withLatestFrom} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {AppType} from "@shared/models/AppType";
import {Clients, RegisterUserDto, ResetPasswordDto, TokenDto} from "@server-models";
import {TranslateService} from "@ngx-translate/core";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {LoginBaseEffects} from "@shared/stores/login-base/store/login-base.effects";
import {LoginBaseStorageService} from "@shared/stores/login-base/services/login-base-storage.service";
import {AlertBaseControllerService} from "@shared/services/alert-controller.service";
import {LoginBaseActions} from "@shared/stores/login-base/store/login-base.actions-type";
import {RegisterGuestAction} from "@tech/pages/login/interfaces/register-guest-action.interface";
import {LanguageService} from "@shared/services/language.service";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {TechLoginSideEffectsService} from "@tech/pages/login/services/tech-login-side-effects.service";
import {TechLoginApiService} from "@tech/pages/login/services/tech-login-api.service";
import {TechLoginStorageService} from "@tech/pages/login/services/tech-login-storage.service";

@Injectable({
  providedIn: 'root'
})
export class TechLoginEffects extends LoginBaseEffects {

  constructor(
    _store: Store,
    actions$: Actions,
    private _techLoginApiService: TechLoginApiService,
    private loginTechSideEffectsService: TechLoginSideEffectsService,
    private _techLoginStorage: TechLoginStorageService,
    loginStorage: LoginBaseStorageService,
    _alertControllerService: AlertBaseControllerService,
    _translationService: TranslateService,
    _modalControllerService: ModalControllerService,
    _router: Router,
    _languageService: LanguageService,
  ) {
    super(
      _store,
      actions$,
      loginStorage,
      _alertControllerService,
      _translationService,
      _modalControllerService,
      _router,
      _languageService
    )
  }

  changePasswordStart$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.changePassword),
    exhaustMap(action => {
      return this._techLoginApiService.changePassword(action.email, action.oldPasswordBase64, action.newPasswordBase64).pipe(
        map(() => TechLoginActions.changePasswordSuccess()),
        catchError(error => of(TechLoginActions.changePasswordFail({error})))
      )
    })
  ));

  changePasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.changePasswordSuccess),
    map(() => this._router.navigateByUrl("tech/login"))
  ), {dispatch: false});

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.resetPassword),
    withLatestFrom(this._store.select(TechLoginSelectors.selectToken)),
    exhaustMap(([action, tokenDto]) => {
      const resetData: ResetPasswordDto = {
        token: tokenDto?.token,
        ...action.resetData
      }
      return this._techLoginApiService.resetPassword(resetData).pipe(
        map(() => TechLoginActions.resetPasswordSuccess({
          email: action.resetData.email!,
          password: action.resetData.newPassword!
        })),
        catchError(error => of(TechLoginActions.resetPasswordFail({error})))
      )
    })
  ));

  resetPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.resetPasswordSuccess),
    exhaustMap((action) => {
      this._modalControllerService.closeModal();
      return [TechLoginActions.byPassword({login: action.email, password: action.password})];
    })
  ));

  resetRequestPassword$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.resetRequestPassword),
    exhaustMap(action => this._techLoginApiService.resetRequestPassword(action.email)
      .pipe(
        map(() => {
          this.loginTechSideEffectsService.resetRequestPasswordAlert();
          return TechLoginActions.resetRequestPasswordSuccess();
        }),
        catchError(error => [TechLoginActions.resetRequestPasswordFail({error})])
      ))
  ));

  loginByPassword$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.byPassword),
    exhaustMap(action => {
      return this._techLoginApiService.login(action.login, action.password!, action.passwordBase64).pipe(
        map(data => TechLoginActions.byPasswordSuccess({token: data})),
        catchError((error) => [TechLoginActions.byPasswordFail({error})])
      )
    })
  ));

  loginByPasswordSuccess = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.byPasswordSuccess),
      exhaustMap(action => {
        if (action.token?.info?.isGuest) {
          this._modalControllerService.closeModal();
          this._router.navigateByUrl("tech/welcome");
        }
        return [TechLoginActions.initSuccess({token: action.token, app: AppType.Tech, isLogging: true})]
      }),
      catchError((error) => [TechLoginActions.initFail({error})])
    )
  );

  // Registration Guest - Start -

  initRegistrationGuest$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.initRegistrationGuest),
      exhaustMap(action => {
        return [TechLoginActions.initRegistrationGuestDialogOpen({currentEmail: action.currentEmail})]
      }),
      catchError((error) => [TechLoginActions.initRegistrationGuestFail({error})])
    )
  );

  initRegistrationGuestDialogOpen$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.initRegistrationGuestDialogOpen),
      exhaustMap((action: { currentEmail: string | undefined }) => {
        const controlEmail = action.currentEmail ? action.currentEmail : "";

        this.loginTechSideEffectsService.askRegistrationData(controlEmail);
        return [TechLoginActions.initRegistrationGuestSuccess()]
      }),
    )
  );

  registerGuestPinRequest$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.registerGuestPinRequest),
      exhaustMap((action: { registerGuest: RegisterGuestAction, isLoadingId?: string | number }) => {
        return this._techLoginApiService.requestGuestPin(action.registerGuest.email).pipe(
          mergeMap(() =>
            [TechLoginActions.registerGuestPinRequestSuccess({
              isRetry: action.registerGuest.isRetry,
              email: action.registerGuest.email,
              passwordBase64: action.registerGuest.passwordBase64
            })]
          ),
          catchError((error) =>
            [TechLoginActions.registerGuestPinRequestFail({error: error})]
          )
        )
      })
    )
  );

  registerGuestPinRequestSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.registerGuestPinRequestSuccess),
      exhaustMap((action: RegisterGuestAction) => {
        if (!action.isRetry) {
          this.loginTechSideEffectsService.askRegistrationPinGuest(action.passwordBase64, action.email);
        }
        return [TechLoginActions.registerGuestPinDialog({})]
      }),
    )
  );

  registerGuestAsUser$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.registerGuestAsUser),
      exhaustMap((action: { data: RegisterUserDto, isLoadingId: number | string, isRetry?: boolean }) => {
        return this._techLoginApiService.requestGuestAsUser(action.data.pinFromEmail!, action.data.email!, action.data.passwordBase64!).pipe(
          map(() => {
              return TechLoginActions.registerGuestAsUserSuccess({
                isRetry: action.isRetry!,
                email: action.data.email!,
                passwordBase64: action.data.passwordBase64!
              })
            }
          ),
          catchError((error) =>
            [TechLoginActions.registerGuestAsUserFail({error: error})]
          )
        )
      })
    )
  );

  registerGuestAsUserSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.registerGuestAsUserSuccess),
      exhaustMap((action: {
          isRetry: boolean;
          email: string;
          passwordBase64: string;
        }) => {
          return [TechLoginActions.byPassword({
            login: action.email!,
            password: undefined,
            passwordBase64: action.passwordBase64
          })]
        }
      )
    )
  );

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.initSuccess),
    filter(action => action.app == AppType.Tech),
    mergeMap((action) => {
      const info = action.token!?.info!;
      this._languageService.setLanguage(info.userCulture!);

      const multipleTenants = this.loginTechSideEffectsService.multipleTenants(info);

      if (!multipleTenants) {

        const tenant = info?.tenants![0];
        const tenantId = tenant?.tenantId;

        return [TechLoginActions.successUniqueTenant({
          app: action.app,
          token: action.token,
          currentTenant: {
            tenantId,
            isGuest: info?.isGuest!,
            tenantLanguage: info?.userCulture,
            tenantDisplayName: tenant?.displayName
          },
          isLogging: action.isLogging!
        })];
      } else {
        return [TechLoginActions.successMultiTenant({tokenInfo: info})];
      }
    })
  ));

  successUniqueTenant$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.successUniqueTenant),
      exhaustMap((action) => {
          const tokenInfo = action.token!?.info!;
          this._techLoginStorage.set({
            app: action.app,
            token: this._stripJwt(action.token!),
            currentTenant: {
              tenantLanguage: tokenInfo?.userCulture,
              tenantId: action.currentTenant?.tenantId,
              isGuest: tokenInfo?.isGuest!,
              tenantDisplayName: this._selectedTenantDisplayName(tokenInfo?.tenants!, action.currentTenant?.tenantId!)
            },
            isLogging: action.isLogging
          });

          return [TechLoginActions.loginRefresh({clientType: Clients.Tech, isLogging: action.isLogging})];
        }
      )
    )
  );

  successMultiTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.successMultiTenant),
    tap((action) => {
      this.loginTechSideEffectsService.getTenants(action.tokenInfo);
    })
  ), {dispatch: false});

  existingMultiTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.existingMultiTenant),
    map((action) => {
      return TechLoginActions.successSelectedTenant({tenantId: action.tenantId});
    })
  ));

  cancelSelectedTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.cancelSelectedTenant),
    tap(() => {
      this._router.navigateByUrl("tech/login")
    })
  ), {dispatch: false});

  successSelectedTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.successSelectedTenant),
    withLatestFrom(this._store.select(TechLoginSelectors.selectCurrentApp)),
    withLatestFrom(this._store.select(TechLoginSelectors.selectToken)),
    exhaustMap(([[action, app], tokenDto]) => {
      this._techLoginStorage.set({
        app: app,
        token: this._stripJwt(tokenDto!)!,
        currentTenant: {
          tenantId: action.tenantId,
        },
        isLogging: action.isLogging
      })
      return [
        TechLoginActions.loginRefresh({clientType: Clients.Tech}),
      ]
    })
  ));

  loginInit$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.init),
    mergeMap(() => {
      return [TechLoginActions.loadStorage()]
    })
  ));

  loadStorage$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.loadStorage),
    mergeMap(() => {
      return this._techLoginStorage.getSt().pipe(
        mergeMap((data) => {
          let tenantDisplayName: string;
          if (data) {
            if (data.token) {
              if (data.currentTenant?.tenantId) {
                tenantDisplayName = this._selectedTenantDisplayName(data.token!.info!.tenants!, data.currentTenant.tenantId!)!;
              }
            }
            return [TechLoginActions.loadStorageSuccess(
              {
                tenantSettings: data.currentTenant?.tenantSettings,
                tenantId: data.currentTenant?.tenantId!,
                tenantLanguage: data.currentTenant?.tenantLanguage!,
                isGuest: data.currentTenant?.isGuest!,
                tenantDisplayName: tenantDisplayName!,
                tokenDto: data.token!,
                app: data.app!,
                isLogging: data.isLogging!
              }
            )]
          }
          return [TechLoginActions.loadStorageFail({error: 'There is not Storage data'})]
        })
      )
    })
  ));

  loadStorageSuccess$ = createEffect((() => this.actions$.pipe(
    ofType(TechLoginActions.loadStorageSuccess),
    exhaustMap((action) => {
      if (action?.tenantSettings?.primaryColor?.value) {
        this._setTenantColor(action.tenantSettings?.primaryColor!.value!);
      }

      return [TechLoginActions.initSuccess({
        app: action.app!,
        token: {
          ...action.tokenDto,
          token: action.tokenDto?.token || undefined
        },
        currentTenant: {
          tenantLanguage: action.tenantLanguage,
          isGuest: action.isGuest,
          tenantId: action.tenantId,
          tenantDisplayName: action.tenantDisplayName,
          tenantSettings: action.tenantSettings,
        },
        isLogging: action.isLogging!
      })]

    })
  )));

  loginRefreshStart$ = createEffect(() => this.actions$.pipe(
      ofType(TechLoginActions.loginRefresh),
      withLatestFrom(this._store.select(TechLoginSelectors.selectToken)),
      withLatestFrom(this._store.select(TechLoginSelectors.selectTenantId)),
      exhaustMap(([[action, token], tenantId]) => {
          return this._techLoginApiService.loginRefresh(token?.refreshToken!, tenantId!).pipe(
            map((tokenDto: TokenDto) => {
              return TechLoginActions.loginRefreshSuccess({
                tokenDto,
                tenantId: tenantId!,
                clientType: Clients.Tech,
                isLogging: action.isLogging!
              })
            }),
            catchError((error) => [TechLoginActions.loginRefreshFail({error})])
          )
        }
      )
    )
  );

  loginRefreshSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.loginRefreshSuccess),
    withLatestFrom(this._store.select(TechLoginSelectors.selectToken)),
    withLatestFrom(this._store.select(TechLoginSelectors.selectCurrentApp)),
    exhaustMap(([[action], appType]) => {
        this._techLoginStorage.set({
          app: appType,
          token: this._stripJwt(action.tokenDto!),
          currentTenant: {
            tenantId: action.tenantId,
          },
          isLogging: action.isLogging
        });

        if (!action.tenantId) {
          return [TechLoginActions.fetchTenantSettingsCancel()]
        }
        return [TechLoginActions.fetchTenantSettings({tenantId: action.tenantId})]
      }
    )
  ));

  fetchTenantSettingsCancel$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.fetchTenantSettingsCancel),
    exhaustMap(() => [TechLoginActions.navigationToWelcome()])
  ));

  navigationToWelcome$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.navigationToWelcome),
    tap(() => {
      this._modalControllerService.closeModal();
      this._router.navigateByUrl("tech/welcome")
    })
  ), {dispatch: false});


  loginFetchTenantSettings$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.fetchTenantSettings),
    withLatestFrom(this._store.select(TechLoginSelectors.selectTenantId)),
    withLatestFrom(this._store.select(TechLoginSelectors.selectToken)),
    exhaustMap(([[_, tenantId], tokenDto]) => {
      const tokenInfo = tokenDto!?.info!;
      return this._techLoginApiService.getSettings(tenantId!).pipe(
        map((settings) => {
          return TechLoginActions.fetchTenantSettingsSuccess({
            currentTenant: {
              tenantId: tenantId,
              tenantLanguage: tokenInfo?.userCulture,
              isGuest: tokenInfo?.isGuest!,
              tenantDisplayName: this._selectedTenantDisplayName(tokenInfo?.tenants!, tenantId!),
              tenantSettings: settings
            }
          })
        }),
        catchError(error => [LoginBaseActions.fetchTenantSettingsFail({error})])
      )
    })
  ));

  loginFetchTenantSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.fetchTenantSettingsSuccess),
    withLatestFrom(this._store.select(TechLoginSelectors.selectCurrentApp)),
    withLatestFrom(this._store.select(TechLoginSelectors.selectToken)),
    exhaustMap(([[action, appType], tokenDto]) => {
      if (action?.currentTenant?.tenantSettings?.primaryColor?.value) {
        this._setTenantColor(action.currentTenant.tenantSettings!?.primaryColor!?.value!);
      }
      this._techLoginStorage.set({
        app: appType,
        token: this._stripJwt(tokenDto!),
        currentTenant: action.currentTenant
      });
      return [TechLoginActions.isLoggingNavigation()]
    }),
  ));

  isLoggingNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.isLoggingNavigation),
    withLatestFrom(this._store.select(TechLoginSelectors.selectIsLogging)),
    exhaustMap(([_, isLogging]) => {
      if (isLogging) {
        return [TechLoginActions.isLoggingNavigationDone()]
      } else {
        return [TechLoginActions.isLoggingNavigationCanceled()]
      }
    })
  ));

  isLoggingNavigationDone$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.isLoggingNavigationDone),
    exhaustMap(() => [TechLoginActions.navigationToInfos()])
  ));

  navigationToInfos$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.navigationToInfos),
    tap(() => this._router.navigateByUrl("tech/logged/infos"))
  ), {dispatch: false});

  loginRefreshFail$ = createEffect(() => this.actions$.pipe(
    ofType(TechLoginActions.loginRefreshFail), exhaustMap(() =>
      this._alertControllerService.observableRefreshToken(Clients.Tech))
  ), {dispatch: false});
}
