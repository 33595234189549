import {Component, OnInit} from '@angular/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {select, Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {ArticleListDto} from '@server-models';
import {TechInventoryActions} from '@tech/pages/inventory/store/tech-inventory.actions-type';
import {TechInventorySelectors} from '@tech/pages/inventory/store/tech-inventory.selector-type';
import {Observable} from 'rxjs';
import {SharedSearchBarComponent} from '@shared/components/search-bar/shared-search-bar.component';

@Component({
  selector: 'app-tech-inventory-mission-detail-report-article-selection-modal',
  templateUrl: './tech-inventory-mission-detail-report-article-selection-modal.component.html',
  styleUrls: ['./tech-inventory-mission-detail-report-article-selection-modal.component.scss'],
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule, SharedSearchBarComponent],
  standalone: true,
})
export class TechInventoryMissionDetailReportArticleSelectionModalComponent implements OnInit {
  isLoading$!: Observable<boolean>;
  articles$!: Observable<ArticleListDto[]>;
  articleSelectFn: (article: ArticleListDto) => void = () => undefined;

  constructor(
    private _modalCtrlService: SharedModalControllerService,
    private _store: Store
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddIsLoading));
    this._store.dispatch(TechInventoryActions.getMissionReportArticleList({}));
    this.articles$ = this._store.select(
      TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddData
    );
  }

  onSearch(searchTerm: string): void {
    this._store.dispatch(
      TechInventoryActions.getMissionReportArticleList({ searchTerm })
    );
  }

  onSelectArticle(article: ArticleListDto): void {
    this.articleSelectFn(article);
    this.onClose();
  }

  onClose(): void {
    this._modalCtrlService.closeModal();
  }

  getArticleName(article: ArticleListDto): string {
    return article.number
      ? `${ article.number } ${ article.name }`
      : article.name ?? '';
  }
}
