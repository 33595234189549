import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  ArticleListDto,
  ChangeStateOfMissionDto,
  FilterDto, LocationListDto,
  MissionCompleteDto,
  MissionDto, MissionReportDto, MissionReportPreviewDto,
  MissionState, Orders, ResourceModelListDto,
  StereotypeDto,
  StereotypeListDto
} from "@server-models";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {map} from "rxjs/operators";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {RequestOptions} from "@shared/interfaces/request-options.interface";
import {TechHttpClientBaseApiService} from "@tech/services/http-client-base-api/tech-http-client-base-api.service";
import {StereotypeApiService} from "@shared/components/issues/services/stereotype/stereotype-api.service";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryApiService extends TechHttpClientBaseApiService implements StereotypeApiService {


  pagePostMissionPaginated(paramsPaginated: PaginationParams, body: FilterDto[]): Observable<OrgaResponse<MissionCompleteDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        SotField: paramsPaginated.sortField as string,
        Sort: paramsPaginated.sort!.toString()
      }
    }
    return super.post<OrgaResponse<MissionCompleteDto[]>>(`/tech/mission/filter`,
      undefined, body, options).pipe(
      map((res: OrgaResponse<MissionCompleteDto[]>) => res)
    );
  }

  getMissionDetailById(id: string): Observable<MissionDto> {
    const options = {
      params: {
        SortField: 'plannedStart',
        Sort: Orders.Descending.toString(),
      }
    }
    return super.get<MissionDto>(`/tech/mission/${id}`,undefined, options);
  }

  getStereotypeById(id: number): Observable<StereotypeDto> {
    return super.get<StereotypeDto>(`/tech/stereotype/${id}`, undefined);
  }

  getStereotypeFilterByEntityType(entityType: string, cacheControl: string): Observable<OrgaResponse<StereotypeListDto>> {
    const options = {
      params: {
        entityType: entityType.toString(),
        Cols: 'CustomPropertySets',
        'cache-control': cacheControl
      }
    }
    return super.get<OrgaResponse<StereotypeListDto>>(`/tech/stereotype`, undefined, options);
  }

  postChangeMissionStateById(id: number, newState: MissionState): Observable<void> {
    const body: ChangeStateOfMissionDto = {
      missionId: id,
      wantedState: newState
    };
    return super.post<void>(`/tech/mission/${id}/changeState`, undefined, body);
  }

  postLocationFilter(params: PaginationParams, body: any): Observable<OrgaResponse<LocationListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols
      }
    }
    return super.post<OrgaResponse<LocationListDto[]>>(`/tech/location/filter`, undefined, body, options);
  }

  postModelFilter(params: PaginationParams, body: any): Observable<OrgaResponse<ResourceModelListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols
      }
    }
    return super.post<OrgaResponse<ResourceModelListDto[]>>(`/tech/v2/model/filter`, undefined, body, options);
  }

  getMissionReportById(reportId: string): Observable<MissionReportDto> {
    return super.get<MissionReportDto>(`/tech/missionreport/${reportId}`, undefined);
  }

  getMissionReportPreviewById(missionId: string): Observable<MissionReportPreviewDto> {
    return super.get<MissionReportPreviewDto>(`/tech/mission/${missionId}/report/preview`, undefined);
  }

  postMissionReportArticleFilter(body: any): Observable<OrgaResponse<ArticleListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: '500',
        PageNumber: '1',
        Cols: 'Content',
        SortField: 'name',
        Sort: '1'
      }
    }
    return super.post<OrgaResponse<ArticleListDto[]>>(`/tech/article/filter`, undefined, body, options);
  }

  submitMissionReport(missionReport: MissionReportDto): Observable<MissionReportDto> {
    return super.post<MissionReportDto>(`/tech/missionreport`, undefined, missionReport);
  }
}
