import {Component, OnInit} from '@angular/core';
import {MissionDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";
import {EDesignType} from "@shared/components/attachments/enums/design-type.enum";
import {TranslateModule} from "@ngx-translate/core";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {
  TechInventoryMissionDetailContainerResumeComponent
} from "@tech/pages/inventory/components/mission/components/detail/pages/container/components/resume/tech-inventory-mission-detail-container-resume.component";
import {
  TechInventoryMissionDetailContainerDescriptionComponent
} from "@tech/pages/inventory/components/mission/components/detail/pages/container/components/description/tech-inventory-mission-detail-container-description.component";
import {
  SharedCustomPropertySetsReadOnlyCardComponent
} from "@shared/components/custom-property-sets-read-only-card/shared-custom-property-sets-read-only-card.component";
import {
  ICustomPropertySetFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-set-formatted.interface";

@Component({
  selector: 'app-tech-inventory-mission-detail-container',
  templateUrl: './tech-inventory-mission-detail-container.page.html',
  styleUrls: ['./tech-inventory-mission-detail-container.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedAttachmentsComponent,
    TechInventoryMissionDetailContainerResumeComponent,
    TechInventoryMissionDetailContainerDescriptionComponent,
    SharedCustomPropertySetsReadOnlyCardComponent
  ],

})
export class TechInventoryMissionDetailContainerPage implements OnInit {

  protected readonly DesignTypeEnum = EDesignType;
  missionDetail$: Observable<MissionDto | undefined>;
  inventoryIsLoading$: Observable<boolean>;
  customPropertySetFormattedList$: Observable<ICustomPropertySetFormatted[]>;

  constructor(
    private _store: Store,
  ) {
    this.missionDetail$ = of();
    this.inventoryIsLoading$ = of();
    this.customPropertySetFormattedList$ = of();
  }

  ngOnInit() {
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
    this.inventoryIsLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionLoading));
    this.customPropertySetFormattedList$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionCustomPropertySetFormattedList));
  }

}
