<ion-content *ngIf="((missionDetailReportId$ | async) || (needModify$ | async)) else addReportButton" class="main">

  <!-- SERVICE TIMES ARE PERMANENT ITEMS ON EVERY REPORT -->
  <ion-text>
    <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.HEADER' | translate }}</h1>
  </ion-text>
  <app-tech-inventory-report-article
    [name]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.WORK.LABEL' | translate"
    [amountLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.WORK.AMOUNT' | translate"
    [amountUnitLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.WORK.UNIT' | translate"
    [disableForm]="setFormDisable"
    [amount]="workDurationInHours"
    [unitPrice]="setWorkCost(workDurationCost)"
    [isRemovable]="false"
    (articleChanged)="onWorkingCostsChanged($event)">
  </app-tech-inventory-report-article>
  <app-tech-inventory-report-article
    [name]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.TRAVEL.LABEL' | translate"
    [amountLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.TRAVEL.AMOUNT' | translate"
    [amountUnitLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.TRAVEL.UNIT' | translate"
    [disableForm]="setFormDisable"
    [amount]="travelDurationInHours"
    [unitPrice]="setTravelCost(travelDurationCost)"
    [isRemovable]="false"
    (articleChanged)="onTravelCostsChanged($event)">
  </app-tech-inventory-report-article>


  <!-- MATERIAL COSTS ARE OPTIONAL AND GET BUILD BY + FAB BUTTON MENU -->
  <ion-text *ngIf="usedArticles.length > 0">
    <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.MATERIAL.HEADER' | translate }}</h1>
  </ion-text>
  <app-tech-inventory-report-article
    *ngFor="let article of usedArticles"
    [article]="article"
    [disableForm]="setFormDisable"
    (articleChanged)="onArticleChanged($event)"
    (remove)="onRemoveArticle($event)">
  </app-tech-inventory-report-article>

  <div class="total-costs">
    <ion-text>
      <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.COSTS.TOTAL' | translate }}</h1>
    </ion-text>
    <ion-text><h1>{{ totalCosts | currency:'EUR':true:'1.2':currentLang }}</h1></ion-text>
  </div>

  <hr class="ion-margin-vertical"/>

  <!-- REPORT TYPE SELECT -->
  <!--    <ion-text>-->
  <!--      <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.TYPE.HEADER' | translate }}</h1>-->
  <!--    </ion-text>-->
  <!--    <ion-card>-->
  <!--      <ion-card-content class="report-custom-values">-->
  <!--        <ion-item class="article" lines="none">-->
  <!--          <ion-select label="Type" label-placement="stacked" interface="popover" [value]="1">-->
  <!--            <ion-select-option [value]="1">-->
  <!--              {{ 'Standard Report' }}-->
  <!--            </ion-select-option>-->
  <!--          </ion-select>-->
  <!--        </ion-item>-->
  <!--      </ion-card-content>-->
  <!--    </ion-card>-->

  <!-- CUSTOM VALUE PROPERTIES START HERE -->
  <!--    <ion-text>-->
  <!--      <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.CUSTOM_PROPERTY.HEADER' | translate }}</h1>-->
  <!--    </ion-text>-->
  <!--    <ion-card>-->
  <!--      <ion-card-content class="report-custom-values">-->
  <!--        <ion-item class="article" lines="none">-->
  <!--          <ion-input label="Customer name" label-placement="stacked" [value]="'Backelfe GmbH'">-->
  <!--          </ion-input>-->
  <!--        </ion-item>-->
  <!--        <ion-item class="article" lines="none">-->
  <!--          <ion-textarea label="Done subjects" labelPlacement="stacked"-->
  <!--                        [value]="'This is my report'" [readonly]="true"></ion-textarea>-->
  <!--        </ion-item>-->
  <!--      </ion-card-content>-->
  <!--    </ion-card>-->

  <ion-button [disabled]="setFormDisable" class="submit" color="primary" (click)="submitReport()">
    <ion-spinner *ngIf="setFormDisable"></ion-spinner>
    <span
      *ngIf="!setFormDisable">
            {{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.FORM.BUTTONS.SUBMIT' | translate }}
          </span>
  </ion-button>


  <ion-fab #articleMenu slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button (click)="requestArticlesList()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top" class="ion-float-end">
      <ion-card>
        <ion-card-content>
          <ion-text><h1>Add</h1></ion-text>
          <ion-spinner *ngIf="articlesToAddIsLoading$ | async"></ion-spinner>
          <ion-list *ngIf="articlesToAddData$ | async as articlesToAdd" class="add-menu" lines="none">
            <ion-item *ngFor="let article of articlesToAdd"
                      class="fab" (click)="onAddArticle(article)">
              <ion-text>{{ getArticleName(article) }}</ion-text>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-fab-list>
  </ion-fab>

</ion-content>
<ng-template #addReportButton>
  <div class="ion-text-center">
    <div class="ion-margin-top">
      <ion-text color="medium">
        <p>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.NO_REPORT.INFO' | translate }}</p>
        <p>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.NO_REPORT.DESCRIPTION' | translate }}</p>
      </ion-text>
    </div>
    <ion-button class="ion-margin-top" fill="outline" (click)="requestPreview()">
      <ion-icon slot="start" name="add"></ion-icon>
      {{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.NO_REPORT.BUTTON' | translate }}
    </ion-button>
  </div>
</ng-template>


