import {createAction, props} from "@ngrx/store";
import {
  KnowledgeArticleListDto,
  KnowledgeArticleStartDto,
  KnowledgePathListDto,
  KnowledgePathStartDto
} from "@server-models";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {
  KnowledgePathRequestPagination
} from "@tech/pages/knowledge/interfaces/knowledge-path-request-pagination.interface";
import {KnowledgePathStartIdRequest} from "@tech/pages/knowledge/interfaces/knowledge-path-start-id-request.interface";


export const getPathItemsPaged = createAction(
  '[Knowledge-Tech] Get Path Items paged',
  props<KnowledgePathRequestPagination>()
);

export const getPathItemsPagedCancel = createAction(
  '[Knowledge-Tech] Get Path Items paged Cancel',
);

export const getPathItemsPagedSuccess = createAction(
  '[Knowledge-Tech] Get Path Items paged success',
  props<{ data: OrgaResponse<KnowledgePathListDto[]> }>()
);

export const getPathItemsPagedFail = createAction(
  '[Knowledge-Tech] Get Path Items paged fail',
  props<{ error: any }>()
);

export const getPathItemsPagedRefresh = createAction(
  '[Knowledge-Tech] Get Path Items paged Refresh',
  props<{ data: OrgaResponse<KnowledgePathListDto[]> }>()
);

export const getPathItemsByIdStart = createAction(
  '[Knowledge-Tech] Get Path start by Id',
  props<KnowledgePathStartIdRequest>()
);

export const getPathItemsByIdStartSuccess = createAction(
  '[Knowledge-Tech] Get Path start by Id Success',
  props<{ data: KnowledgePathStartDto }>()
);

export const getPathItemsByIdStartFail = createAction(
  '[Knowledge-Tech] Get Path start by Id Fail',
  props<{error: any}>()
);

export const getPathItemsByIdStartRefresh = createAction(
  '[Knowledge-Tech] Get Path start by Id Refresh',
  props<{ data: KnowledgePathStartDto }>()
);

export const getItemsSearched = createAction(
  '[Knowledge-Tech] Get Searched',
  props<{toSearch: string}>()
);

export const getItemsSearchedSuccess = createAction(
  '[Knowledge-Tech] Get Searched Success',
  props<{ data: OrgaResponse<KnowledgeArticleListDto[]> }>()
);

export const getItemsSearchedFail = createAction(
  '[Knowledge-Tech] Get Searched Fail',
  props<{ error: any }>()
);

export const getArticlePage = createAction(
  '[Knowledge-Tech] Get Article Page Start',
  props<{ articleId: number }>()
);

export const getArticlePageSuccess = createAction(
  '[Knowledge-Tech] Get Article Page Success',
  props<{ data: KnowledgeArticleStartDto }>()
);

export const getArticlePageFail = createAction(
  '[Knowledge-Tech] Get Article Page Fail',
  props<{ error: any }>()
);
