import {Component, Input, OnInit} from '@angular/core';
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {IonicModule} from "@ionic/angular";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import * as _ from 'lodash';
import {Store} from "@ngrx/store";
import {TranslateModule} from "@ngx-translate/core";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";

@Component({
  selector: 'app-viewer-tenants',
  templateUrl: './viewer-tenants.component.html',
  styleUrls: ['./viewer-tenants.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule
  ],
  standalone: true
})
export class ViewerTenantsComponent implements OnInit {

  @Input() data: any;

  selectedRoles!: { tenantId: number };

  constructor(
    private _modalCtrlService: ModalControllerService,
    private _store: Store
  ) {
  }

  get sortedData() {
    return _.sortBy(this.data, ['displayName'])
  }

  ngOnInit() {
  }

  submitSelection(): void {
    this._store.dispatch(TechLoginActions.successSelectedTenant(this.selectedRoles));
    this._modalCtrlService.closeModal();
  }

  exit(): void {
    this._modalCtrlService.closeModal();
    this._store.dispatch(TechLoginActions.cancelSelectedTenant())
  }

}
