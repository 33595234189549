import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ResourceListDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {TechMissionFilterService} from "@tech/pages/inventory/services/tech-mission-filter.service";
import {ETreeMenuSegment} from "@tech/pages/inventory/enums/tree-menu-segment.enum";

@Component({
  selector: 'app-tech-inventory-child-tree-resource-list',
  templateUrl: './tech-inventory-child-tree-resource-list.component.html',
  styleUrls: ['./tech-inventory-child-tree-resource-list.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],

})
export class TechInventoryChildTreeResourceListComponent implements OnInit, OnChanges {

  @Input() resourceListDto: ResourceListDto[];
  @Input() paging: IPagination;
  @Input() segmentType: ETreeMenuSegment;
  @Output() onNeedMore: EventEmitter<any>;


  selectedResource: any;

  constructor(
    private _store: Store,
    private _missionFilterService: TechMissionFilterService
  ) {
    this.resourceListDto = <ResourceListDto[]>{};
    this.onNeedMore = new EventEmitter<any>();
    this.paging = <IPagination>{};
    this.segmentType = ETreeMenuSegment.Type;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  needMore() {
    let mainItemId;
    switch (this.segmentType) {
      case ETreeMenuSegment.Type: {
        mainItemId = this.resourceListDto[0].stereotypeId;
        break;
      }
      case ETreeMenuSegment.Location: {
        mainItemId = this.resourceListDto[0].locationId;
        break;
      }
      case ETreeMenuSegment.Model: {
        mainItemId = this.resourceListDto[0].model?.resourceModelId;
        break;
      }
    }
    this.onNeedMore.emit({ parentId: mainItemId, paging: this.paging });
  }

  applyResourceFilter(resource: ResourceListDto) {
    this.selectedResource = this.selectedResource === resource ? null : resource;
    if (this.selectedResource) {
      this._missionFilterService.filterSettingsSubject.next({
        ...this._missionFilterService.filterSettingsSubject.getValue(),
        resource: resource,
        stereotypeId: undefined,
        parentResource: undefined
      });
    } else {
      this._missionFilterService.filterSettingsSubject.next({
        ...this._missionFilterService.filterSettingsSubject.getValue(),
        parentResource: {
          id: this._formatResourceGroupId(resource, this.segmentType),
          type: this.segmentType
        },
        resource: undefined
      });
    }
  }

  private _formatResourceGroupId(resource: ResourceListDto, type: ETreeMenuSegment): number {
    switch (type) {
      case ETreeMenuSegment.Type: {
        return resource.stereotypeId!;
      }
      case ETreeMenuSegment.Location: {
        return resource.locationId!;
      }
      case ETreeMenuSegment.Model: {
        return resource.model?.resourceModelId!;
      }
    }
  }
}
