<div class="ion-page" id="mission" *ngIf="missionDetail$ | async as missionDetail">
  <ion-header [translucent]="true">
    <ion-toolbar color="primary">
      <ion-title>{{ formatTitle(missionDetail.missionId!) }}</ion-title>
      <ion-buttons slot="start">
        <ion-button (click)="back()">
          <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ng-container *ngIf="(formReportService.formReportSubject | async) as formReport">
        <ion-buttons slot="end" *ngIf="checkReportRoute()">
          <ion-button [disabled]="formReport?.form?.invalid" class="submit" (click)="submitReport()">
          <span>
            {{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.FORM.BUTTONS.SUBMIT' | translate }}
          </span>
          </ion-button>
        </ion-buttons>
      </ng-container>

    </ion-toolbar>
    <ion-toolbar color="primary">
      <ion-spinner *ngIf="(isLoading$ | async); else segments"></ion-spinner>
      <ng-template #segments>
        <ion-segment (ionChange)="missionPagesService.changeSegmentSetting($event.detail.value)"
                     [(ngModel)]="missionPagesService.selectedSegment">
          <ion-segment-button [value]="AssignedUserEnum.Mine">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.DETAILS.SEGMENTS.VIEW' | translate }}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button *ngIf="missionDetail.origin?.primaryIssue?.issueId"
                              [value]="AssignedUserEnum.PrimaryIssue">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.DETAILS.SEGMENTS.PRIMARY_ISSUE' | translate }}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button [value]="AssignedUserEnum.Unassigned">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.DETAILS.SEGMENTS.REPORT' | translate }}
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ng-template>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <router-outlet></router-outlet>
  </ion-content>
</div>
