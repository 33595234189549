import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonFab, IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  AppEntityType,
  ArticleInUsageDto,
  ArticleListDto,
  ArticleUsageDto,
  FilterDto,
  MissionDto,
  MissionReportDto,
  MissionReportPreviewDto,
  StereotypeDto,
  StereotypeListDto,
} from '@server-models';
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {filter, Observable, of, Subscription} from "rxjs";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-inventory.actions-type";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {startWith, tap} from 'rxjs/operators';
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {
  SharedCustomPropertyFormInputComponent
} from "@shared/components/custom-property-form/components/input/shared-custom-property-form-input.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  SharedCustomPropertyFormComponent
} from "@shared/components/custom-property-form/shared-custom-property-form.component";
import {
  SharedCustomPropertySetsReadOnlyCardComponent
} from "@shared/components/custom-property-sets-read-only-card/shared-custom-property-sets-read-only-card.component";
import {
  TechInventoryFormReportService
} from "@tech/pages/inventory/components/mission/components/detail/services/tech-form-report.service";
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {
  IReportArticleForm
} from "@tech/pages/inventory/components/mission/components/detail/pages/report/interfaces/report-article-form.interface";
import {
  TechInventoryMissionDetailReportArticleSelectionModalComponent
} from "@tech/pages/inventory/components/mission/components/detail/pages/report/components/article-selection-modal/tech-inventory-mission-detail-report-article-selection-modal.component";
import {
  TechInventoryMissionDetailReportArticleComponent
} from "@tech/pages/inventory/components/mission/components/detail/pages/report/components/article/tech-inventory-mission-detail-report-article.component";
import {CanLeave} from "@tech/guards/tech-can-leave-page.guard";

@Component({
  selector: 'app-tech-inventory-mission-detail-report',
  templateUrl: './tech-inventory-mission-detail-report.page.html',
  styleUrls: ['./tech-inventory-mission-detail-report.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedCustomPropertyFormInputComponent,
    ReactiveFormsModule,
    FormsModule,
    SharedCustomPropertyFormComponent,
    SharedCustomPropertySetsReadOnlyCardComponent,
    TechInventoryMissionDetailReportArticleComponent
  ],
  providers: [
    SharedModalControllerService
  ]
})
export class TechInventoryMissionDetailReportPage implements CanLeave, OnInit, OnDestroy {
  @ViewChild('articleMenu') articleMenu!: IonFab;
  @Output() formChange = new EventEmitter<FormGroup>();
  parentForm: FormGroup;

  missionDetail$: Observable<MissionDto | undefined>;
  stereotypeTypes$: Observable<{
    data: StereotypeListDto[] | undefined;
    paging: IPagination | undefined;
    allStereotypes: StereotypeDto[] | undefined;
    formattedData: any[] | undefined;
    isLoading: boolean;
  }>;
  stereotypeTypesData$: Observable<StereotypeListDto[] | undefined>;
  stereotypeTypesFormattedData$: Observable<any[] | undefined>;
  reportData$: Observable<MissionReportDto | undefined>;
  missionDetailReportId$: Observable<number | null | undefined>;
  missionDetailMissionId$: Observable<number | null | undefined>;
  articlesToAddData$: Observable<any[]>;
  inventoryIsLoading$: Observable<boolean>;
  reportIsLoading$: Observable<boolean>;
  articlesToAddIsLoading$: Observable<boolean>;
  reportStereotypesTypesIsLoading$: Observable<boolean>;
  reportSubmitLoading$: Observable<boolean>;
  previewDefaultCost$: Observable<MissionReportPreviewDto>;
  isModificationNeeded$: Observable<boolean>;

  currentMission: MissionDto;
  currentStereotypesData: StereotypeListDto[] | undefined;
  currentFormattedData: any[] | undefined;
  currentReportData: MissionReportDto | undefined;
  currentMissionId: number | null | undefined;
  currentReportId: number | null | undefined;
  currentArticlesToAddData: ArticleListDto[];
  currentIsModificationNeeded: boolean;
  allSubscriptions: Subscription[];

  setFormDisable: boolean = false;

  defaultTravelDurationInHours: number = 1;
  defaultTravelDurationCost: number = 50;
  defaultWorkDurationInHours: number = 1;
  defaultWorkDurationCost: number = 50;
  defaultUsedArticles: ArticleUsageDto[] = [];

  usedArticles: ArticleUsageDto[] = [];

  travelDurationInHours: number = 1;
  travelDurationCost: number = 50;
  workDurationInHours: number = 1;
  workDurationCost: number = 50;
  totalCosts: number = 0;
  incValues: any;

  currentLang: string = 'de-DE';
  localIdCount: number = 1;
  paginationReportParams: IPaginationParams = {
    pageNumber: 1,
    pageSize: 100,
    cols: 'CustomPropertySets'
  };
  infinityReportLoading: boolean = false;
  selectedReportStereotypeType: any;
  reportType: any | undefined;

  constructor(
    private _translateService: TranslateService,
    private _store: Store,
    private _fb: FormBuilder,
    private _formReportService: TechInventoryFormReportService,
    private _modalCtrlService: SharedModalControllerService
  ) {
    this.parentForm = this._fb.group({});
    this.selectedReportStereotypeType = undefined;
    this.reportType = undefined;
    this.allSubscriptions = [];

    this.currentLang = this._translateService.currentLang;
    this.missionDetail$ = of();
    this.stereotypeTypes$ = of();
    this.stereotypeTypesData$ = of();
    this.stereotypeTypesFormattedData$ = of();
    this.reportData$ = of();
    this.missionDetailReportId$ = of();
    this.missionDetailMissionId$ = of();
    this.articlesToAddData$ = of();
    this.inventoryIsLoading$ = of();
    this.reportIsLoading$ = of();
    this.articlesToAddIsLoading$ = of();
    this.reportStereotypesTypesIsLoading$ = of();
    this.reportSubmitLoading$ = of();
    this.previewDefaultCost$ = of();

    this.currentMission = {};
    this.currentStereotypesData = [];
    this.currentFormattedData = [];
    this.currentReportData = undefined;
    this.currentMissionId = NaN;
    this.currentReportId = NaN;
    this.currentArticlesToAddData = [];
    this.currentIsModificationNeeded = false;
    this.isModificationNeeded$ = of();

    this.incValues = undefined;

  }

  ngOnInit(): void {
    this.initSelectors();
    this.subscribeSelectors();
    this._calculateTotalCosts();
    this._formReportService.resetFormReport();

    this.allSubscriptions.push(
      this.stereotypeTypes$.pipe(
        filter(stereotypesTypes => !!stereotypesTypes?.data),
      ).subscribe(stereotypes => this.reportTypeDefaultSelected(stereotypes.data!))
    );
  }

  private _hasServiceTimeAndUsedArticlesDifferences(currentData: any, serviceForm: any): boolean {
    const tempData = currentData ?? {
      travelDurationInHours: this.defaultTravelDurationInHours,
      travelDurationCost: this.defaultTravelDurationCost,
      workDurationInHours: this.defaultWorkDurationInHours,
      workDurationCost: this.defaultWorkDurationCost,
      usedArticles: this.defaultUsedArticles
    };

    const numericProperties: (keyof MissionReportDto)[] = [
      'travelDurationInHours',
      'travelDurationCost',
      'workDurationInHours',
      'workDurationCost'
    ];

    for (const prop of numericProperties) {
      if (tempData[prop] !== serviceForm[prop]) {
        return true;
      }
    }

    const currentArticles = currentData?.usedArticles ?? this.defaultUsedArticles;
    const serviceArticles = serviceForm?.usedArticles ?? this.defaultUsedArticles;

    return !this._compareUsedArticles(currentArticles, serviceArticles);
  }

  private _compareUsedArticles(articles1: any[], articles2: any[]): boolean {
    if (articles1.length !== articles2.length) {
      return false;
    }

    for (let i = 0; i < articles1.length; i++) {
      const article1 = articles1[i];
      const article2 = articles2[i];

      const basicProps: (keyof ArticleUsageDto)[] = [
        'count',
        'price',
        'total',
        'note'
      ];

      for (const prop of basicProps) {
        if (article1[prop] !== article2[prop]) {
          return false;
        }
      }
    }

    return true;
  }

  canLeave(): boolean {
    const IsAnyDifference = this._hasServiceTimeAndUsedArticlesDifferences(this.currentReportData, this._formReportService.formReportSubject.getValue());
    return !this.parentForm.touched && !this.parentForm.invalid && !IsAnyDifference;
  }

  initSelectors() {
    this.inventoryIsLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionLoading));
    this.reportIsLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportIsLoading));
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
    this.stereotypeTypes$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypes));
    this.stereotypeTypesData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypesData));
    this.stereotypeTypesFormattedData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypesFormattedData))
    this.isModificationNeeded$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportNeedModify));
    this.missionDetailReportId$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetailReportId));
    this.missionDetailMissionId$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetailMissionId));
    this.reportData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportData));
    this.articlesToAddData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddData));
    this.articlesToAddIsLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddIsLoading), startWith(false));
    this.reportStereotypesTypesIsLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypesIsLoading));
    this.reportSubmitLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportIsSubmitLoading));
    this.previewDefaultCost$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportPreviewCost));
  }

  subscribeSelectors() {
    this.allSubscriptions.push(
      this.missionDetail$.subscribe((missionDetail) => {
        if (missionDetail) this.currentMission = missionDetail;
      })
    );
    this.allSubscriptions.push(
      this.previewDefaultCost$.subscribe((defaultData) => {
        this.travelDurationCost = defaultData.defaultTravelDurationCost!;
        this.workDurationCost = defaultData.defaultWorkDurationCost!;
      })
    );
    this.allSubscriptions.push(
      this.isModificationNeeded$.subscribe((modify) => {
        this.currentIsModificationNeeded = modify;
      })
    );

    this.allSubscriptions.push(
      this.stereotypeTypes$.pipe(
        filter((stereotypeTypes) => {
          return !stereotypeTypes.data
        }),
        filter((stereotypeTypes) => {
          return !stereotypeTypes.isLoading
        })
      ).subscribe(() => {
        this.loadReportStereotypesByEntity([], true);
      })
    );
    this.allSubscriptions.push(
      this.stereotypeTypesData$.subscribe((data: StereotypeListDto[] | undefined) => {
        if (data) {
          if (!this.currentStereotypesData) {
            this.currentStereotypesData = data;
          }
        }
      })
    );
    this.allSubscriptions.push(
      this.stereotypeTypesFormattedData$.pipe(
      ).subscribe((formattedData) => {
        this.currentFormattedData = formattedData;
      })
    );

    this.allSubscriptions.push(
      this.missionDetailReportId$.pipe(
      ).subscribe((reportId) => {
          // when it is a new to add report currentReportId is set to null on create component it is on NaN
          if (this.currentReportId != null) {
            this.currentReportId = reportId;
            if (reportId) {
              this._store.dispatch(TechInventoryActions.getMissionReportById({ reportId: reportId.toString() }));
            }
          }
        }
      )
    );
    this.allSubscriptions.push(
      this.reportData$
        .subscribe((missionReport) => {
          if (missionReport) {
            this.currentReportData = missionReport;
            this.fillForm(missionReport);
          }
        })
    );
    this.allSubscriptions.push(
      this.missionDetailMissionId$.subscribe((missionId) => {
        this.currentMissionId = missionId;
      })
    );
    this.allSubscriptions.push(
      this.articlesToAddData$.subscribe((articles) => {
        this.currentArticlesToAddData = articles;
      })
    );
    this.allSubscriptions.push(
      this.reportSubmitLoading$.subscribe((loading) => {
        this.setFormDisable = loading;
      })
    );
  }

  loadReportStereotypesByEntity(filters: FilterDto[], isRefresh: boolean = false) {
    if (isRefresh) this.paginationReportParams.pageNumber = 1;
    this._store.dispatch(
      TechInventoryActions.requestReportStereotypesByEntity({
        entityType: AppEntityType.MissionReport,
        request: {
          pagination: this.paginationReportParams,
          filters: filters,
          isRefresh: isRefresh
        }
      })
    );
  }

  fillForm(reportData: MissionReportDto) {
    if (reportData) {
      this.incValues = reportData.customPropertyValues?.length! > 0 ? reportData.customPropertyValues : [];
    }
    this.travelDurationInHours = reportData.travelDurationInHours!;
    this.travelDurationCost = reportData.travelDurationCost!;
    this.workDurationInHours = reportData.workDurationInHours!;
    this.workDurationCost = reportData.workDurationCost!;
    for (const usedArticle of reportData.usedArticles!) {
      this.usedArticles.push(this._mapToUsageArticle(usedArticle)!)
    }
    this.totalCosts = reportData.total!;
  }

  onAddArticle(article: ArticleListDto): void {
    this.usedArticles.push(this._mapToUsageArticle(article)!);
    this.articleMenu.close();
    this._calculateTotalCosts();
  }

  onRemoveArticle(article: IReportArticleForm): void {
    this.usedArticles = this.usedArticles.filter(
      (usedArticle) => usedArticle.id !== article.localId
    );
    this._formReportService.formReportSubject.next({
      ...this._formReportService.formReportSubject.getValue(),
      usedArticles: this.usedArticles
    });
    this._calculateTotalCosts();
  }

  onArticleChanged(article: IReportArticleForm): void {
    const index = this.usedArticles.findIndex(
      (usedArticle) => usedArticle.id === article.localId
    );

    // Map ReportArticleForm to ArticleUsageDto
    if (index > -1) {
      this.usedArticles[index].count = article.amount;
      this.usedArticles[index].price = article.unitPrice;
      this.usedArticles[index].note = article.note;
      this.usedArticles[index].total = article.totalPrice;
      this._calculateTotalCosts();
    }
  }

  onTravelCostsChanged(article: IReportArticleForm): void {
    this.travelDurationCost = article.unitPrice;
    this.travelDurationInHours = article.amount;
    this._formReportService.formReportSubject.next({
      ...this._formReportService.formReportSubject.getValue(),
      travelDurationInHours: this.travelDurationInHours,
      travelDurationCost: this.travelDurationCost,
    });
    this._calculateTotalCosts();
  }

  onWorkingCostsChanged(article: IReportArticleForm): void {
    this.workDurationCost = article.unitPrice;
    this.workDurationInHours = article.amount;
    this._formReportService.formReportSubject.next({
      ...this._formReportService.formReportSubject.getValue(),
      workDurationInHours: this.workDurationInHours,
      workDurationCost: this.workDurationCost
    });
    this._calculateTotalCosts();
  }

  requestPreview() {
    this._store.dispatch(TechInventoryActions.getMissionReportPreviewById({ missionId: this.currentMissionId!.toString() }))
  }

  setWorkCost(incomingWorkCost: number): number {
    return incomingWorkCost ? incomingWorkCost : this.workDurationCost;
  }

  setTravelCost(incomingTravelCost: number): number {
    return incomingTravelCost ? incomingTravelCost : this.travelDurationCost;
  }

  openArticlePickerModal(): void {
    this._modalCtrlService.showModal(TechInventoryMissionDetailReportArticleSelectionModalComponent, '', {
      articleSelectFn: (article: ArticleListDto) => this.onAddArticle(article),
    });
  }

  private _calculateTotalCosts(): void {
    const roundedWorkCost = Math.round(this.workDurationInHours * this.workDurationCost * 100) / 100;
    const roundedTravelCost = Math.round(this.travelDurationInHours * this.travelDurationCost * 100) / 100;

    const serviceSum = roundedWorkCost + roundedTravelCost;

    const usedArticleSum = this.usedArticles.reduce(
      (accumulator: number, article: ArticleUsageDto): number =>
        accumulator + Math.round((article.total ?? 0) * 100) / 100,
      0
    );

    this.totalCosts = Math.round((serviceSum + usedArticleSum) * 100) / 100;
  }

  isArticleUsageDto(obj: any): obj is ArticleUsageDto {
    return (
      typeof obj.articleUsageId === 'number' || typeof obj.total === 'number' || typeof obj.article === 'object'
    );
  }

  isArticleListDto(obj: any): obj is ArticleListDto {
    return (
      typeof obj.articleId === 'number' || typeof obj.kind === 'object' || Array.isArray(obj.customPropertyValues)
    );
  }

  private _mapToUsageArticle(article: ArticleUsageDto | ArticleListDto): ArticleUsageDto | undefined {
    if (this.isArticleUsageDto(article)) {
      return {
        id: article.articleUsageId,
        articleUsageId: article.articleUsageId,
        count: article.count,
        price: article.price,
        position: article.position,
        unit: article.unit,
        tenantId: article.tenantId,
        total: article.total,
        note: article.note,
        article: article.article
      }
    }
    if (this.isArticleListDto(article)) {
      return {
        id: this.localIdCount++,
        count: isNaN(Number(article.number)) ? Number(article.number) : 1,
        price: article.sellingPrice,
        position: 1,
        unit: article.unit,
        tenantId: article.tenantId,
        total: article.purchasingPrice,
        note: null,
        article: {
          articleId: article.articleId,
          name: article.name,
          isArchived: article.isArchived,
        } as ArticleInUsageDto
      }
    }
    return undefined;
  }

  reportTypeSelected(ev: CustomEvent) {
    const selectedStereotypeReportType: StereotypeDto = ev.detail.value;
    this.reportType = this.currentFormattedData?.find((formattedData) => formattedData.issueId == selectedStereotypeReportType.stereotypeId);
    this.selectedReportStereotypeType = selectedStereotypeReportType;

    if (!this.reportType) {
      this.reportType = this.selectedReportStereotypeType;
    }
  }

  reportTypeDefaultSelected(typeOptions: StereotypeListDto[]): StereotypeListDto | undefined {
    let defaultSelected;
    if (this.currentReportId) {
      const fromDataBase = this.currentReportData?.stereotypeId;
      defaultSelected = typeOptions?.find((stereotypeListDto) => fromDataBase == stereotypeListDto.stereotypeId)
    } else {
      defaultSelected = typeOptions?.find((stereotypeListDto) => stereotypeListDto.isDefault);
    }
    if (defaultSelected) {
      if (!this.selectedReportStereotypeType) {
        this.reportTypeSelected({ detail: { value: defaultSelected } } as CustomEvent);
        return defaultSelected;
      } else {
        this.reportTypeSelected({ detail: { value: this.selectedReportStereotypeType } } as CustomEvent);
        return this.selectedReportStereotypeType;
      }
    } else {
      return undefined;
    }

  }

  // TODO: this is the idea behind load more stereotypes not now because page size 100
  loadMoreStereotypeReportType(event: any): void {
    this.paginationReportParams.pageNumber++;
    this.infinityReportLoading = true;
    this.loadReportStereotypesByEntity([]);
    this.stereotypeTypes$.pipe(
      filter((data: any) => !data.isLoading),
      tap(() => {
        event.target.complete();
        this.infinityReportLoading = false;
      })
    ).subscribe();
  }

  formInnerModified(ev: FormGroup) {
    this.parentForm = ev;
    this.formChange.emit(this.parentForm);
    this._formReportService.formReportSubject.next({
      form: this.parentForm,
      formattedData: this.currentFormattedData,
      hasReportId: !!this.currentReportId,
      reportId: this.currentReportId!,
      customPropertySets: this.selectedReportStereotypeType.customPropertySets,
      reportType: this.reportType,
      stereotypeId: this.reportType.stereotypeId,
      stereotypeRowVersion: this.reportType.rowVersion,
      missionId: this.currentMissionId!,
      totalCosts: this.totalCosts!,
      tenantId: this.currentMission.tenantId!,
      travelDurationInHours: this.travelDurationInHours,
      travelDurationCost: this.travelDurationCost,
      workDurationInHours: this.workDurationInHours,
      workDurationCost: this.workDurationCost,
      usedArticles: this.usedArticles,
    });
  }

  ngOnDestroy() {
    this.currentReportData = undefined;
    this.usedArticles = [];
    this.selectedReportStereotypeType = undefined;
    this._formReportService.resetFormReport();
    this.allSubscriptions.map((subscription: Subscription) => subscription.unsubscribe());
    this._store.dispatch(TechInventoryActions.missionReportDataReset());
  }

}
