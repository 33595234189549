import {Component, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonFab, IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {InfoCardComponent} from '@team/pages/infos/components/info-card/info-card.component';
import {ButtonTeamSideMenuComponent} from '@team/components/button-team-side-menu/button-team-side-menu.component';
import {
  ArticleInUsageDto,
  ArticleListDto,
  ArticleUsageDto,
  MissionDto,
  MissionReportDto
} from '@server-models';
import {
  TechInventoryReportArticleComponent, ReportArticleForm
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/pages/report-detail/components/report-article/report-article.component";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";

@Component({
  selector: 'app-tech-inventory-report-detail-page',
  templateUrl: './report-detail-page.component.html',
  styleUrls: ['./report-detail-page.component.scss'],
  standalone: true,
  providers: [
    TranslateService
  ],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    InfoCardComponent,
    ButtonTeamSideMenuComponent,
    TechInventoryReportArticleComponent
  ],
})
export class TechInventoryReportDetailPage implements OnInit {
  @ViewChild('articleMenu') articleMenu!: IonFab;

  missionDetail$: Observable<MissionDto>;
  reportData$: Observable<MissionReportDto | undefined>;
  missionDetailReportId$: Observable<number | null | undefined>;
  missionDetailMissionId$: Observable<number | null | undefined>;
  articlesToAddData$: Observable<any[]>;
  articlesToAddIsLoading$: Observable<boolean>;
  reportSubmitLoading$: Observable<boolean>;
  needModify$: Observable<boolean>;

  currentMission: MissionDto;
  currentMissionId: number;
  currentReportId: number;
  currentArticlesToAddData: any[];

  setFormDisable: boolean = false;

  usedArticles: ArticleUsageDto[] = [];

  travelDurationInHours: number = 1;
  travelDurationCost: number = 50;
  workDurationInHours: number = 1;
  workDurationCost: number = 50;
  totalCosts: number = 0;

  currentLang: string = 'de-DE';
  localIdCount: number = 1;

  constructor(
    private translateService: TranslateService,
    private _store: Store
  ) {
    this.currentLang = this.translateService.currentLang;
    this.missionDetail$ = of();
    this.reportData$ = of();
    this.missionDetailReportId$ = of();
    this.missionDetailMissionId$ = of();
    this.articlesToAddData$ = of();
    this.articlesToAddIsLoading$ = of();
    this.reportSubmitLoading$ = of();

    this.currentMission = {};
    this.currentMissionId = NaN;
    this.currentReportId = NaN;
    this.currentArticlesToAddData = [];
    this.needModify$ = of();

  }

  ngOnInit(): void {
    this.initSelectors();
    this.subscribeSelectors();
    this._calculateTotalCosts();
  }

  initSelectors() {
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
    this.needModify$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportNeedModify));
    this.missionDetailReportId$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetailReportId));
    this.missionDetailMissionId$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetailMissionId));
    this.reportData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportData));
    this.articlesToAddData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddData));
    this.articlesToAddIsLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportArticlesToAddIsLoading));
    this.reportSubmitLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionReportIsSubmitLoading));
  }

  subscribeSelectors() {
    this.missionDetail$.subscribe((missionDetail) => {
      if (missionDetail) this.currentMission = missionDetail;
    });
    this.missionDetailReportId$.subscribe((reportId) => {
        if (reportId) this.currentReportId = reportId;
      }
    );
    this.missionDetailMissionId$.subscribe((missionId) => {
      if (missionId) this.currentMissionId = missionId;
    });
    this.reportData$.subscribe((reportData) => {
      if (this.currentReportId) {
        !reportData ? this._store.dispatch(TechInventoryActions.getMissionReportById({reportId: this.currentReportId.toString()})) : this.fillForm(reportData);
      }
    });
    this.articlesToAddData$.subscribe((articles) => {
      this.currentArticlesToAddData = articles;
    });
    this.reportSubmitLoading$.subscribe((loading) => {
      this.setFormDisable = loading;
    });
  }

  fillForm(reportData: MissionReportDto) {
    this.travelDurationInHours = reportData.travelDurationInHours!;
    this.travelDurationCost = reportData.travelDurationCost!;
    this.workDurationInHours = reportData.workDurationInHours!;
    this.workDurationCost = reportData.workDurationCost!;
    for (const usedArticle of reportData.usedArticles!) {
      this.usedArticles.push(this._mapToUsageArticle(usedArticle)!)
    }
    this.totalCosts = reportData.total!;
  }

  requestArticlesList() {
    if (this.currentArticlesToAddData.length <= 0) {
      this._store.dispatch(TechInventoryActions.getMissionReportArticleList());
    }
  }

  onAddArticle(article: ArticleListDto): void {
    this.usedArticles.push(this._mapToUsageArticle(article)!);
    this.articleMenu.close();
    this._calculateTotalCosts();
  }

  onRemoveArticle(article: ReportArticleForm): void {
    this.usedArticles = this.usedArticles.filter(
      (usedArticle) => usedArticle.id !== article.localId
    );
    this._calculateTotalCosts();
  }

  onArticleChanged(article: ReportArticleForm): void {
    const index = this.usedArticles.findIndex(
      (usedArticle) => usedArticle.id === article.localId
    );

    // Map ReportArticleForm to ArticleUsageDto
    if (index > -1) {
      this.usedArticles[index].count = article.amount;
      this.usedArticles[index].price = article.unitPrice;
      this.usedArticles[index].note = article.note;
      this.usedArticles[index].total = article.totalPrice;
      this._calculateTotalCosts();
    }
  }

  onTravelCostsChanged(article: ReportArticleForm): void {
    this.travelDurationCost = article.unitPrice;
    this.travelDurationInHours = article.amount;
    this._calculateTotalCosts();
  }

  onWorkingCostsChanged(article: ReportArticleForm): void {
    this.workDurationCost = article.unitPrice;
    this.workDurationInHours = article.amount;
    this._calculateTotalCosts();
  }

  getArticleName(article: ArticleListDto): string {
    return article.number
      ? `${article.number} ${article.name}`
      : article.name ?? '';
  }

  requestPreview() {
    this._store.dispatch(TechInventoryActions.getMissionReportPreviewById({missionId: this.currentMissionId.toString()}))
  }

  setWorkCost(incomingWorkCost: number): number {
    return incomingWorkCost ? incomingWorkCost : this.workDurationCost;
  }

  setTravelCost(incomingTravelCost: number): number {
    return incomingTravelCost ? incomingTravelCost : this.travelDurationCost;
  }

  private _calculateTotalCosts(): void {
    const roundedWorkCost = Math.round(this.workDurationInHours * this.workDurationCost * 100) / 100;
    const roundedTravelCost = Math.round(this.travelDurationInHours * this.travelDurationCost * 100) / 100;

    const serviceSum = roundedWorkCost + roundedTravelCost;

    const usedArticleSum = this.usedArticles.reduce(
      (accumulator: number, article: ArticleUsageDto): number =>
        accumulator + Math.round((article.total ?? 0) * 100) / 100,
      0
    );

    this.totalCosts = Math.round((serviceSum + usedArticleSum) * 100) / 100;
  }

  isArticleUsageDto(obj: any): obj is ArticleUsageDto {
    return (
      typeof obj.articleUsageId === 'number' || typeof obj.total === 'number' || typeof obj.article === 'object'
    );
  }

  isArticleListDto(obj: any): obj is ArticleListDto {
    return (
      typeof obj.articleId === 'number' || typeof obj.kind === 'object' || Array.isArray(obj.customPropertyValues)
    );
  }

  private _mapToUsageArticle(article: ArticleUsageDto | ArticleListDto): ArticleUsageDto | undefined {
    if (this.isArticleUsageDto(article)) {
      return {
        id: article.article!.articleId,
        articleUsageId: article.articleUsageId,
        count: article.count,
        price: article.price,
        position: article.position,
        unit: article.unit,
        tenantId: article.tenantId,
        total: article.total,
        note: article.note,
        article: article.article
      }
    }
    if (this.isArticleListDto(article)) {
      return {
        id: this.localIdCount++,
        articleUsageId: article.articleId,
        count: Number(article.number),
        price: article.sellingPrice,
        position: 1,
        unit: article.unit,
        tenantId: article.tenantId,
        total: article.purchasingPrice,
        note: null,
        article: {
          articleId: article.articleId,
          id: 0,
          name: article.name,
          isArchived: article.isArchived,
        } as ArticleInUsageDto
      }
    }
    return undefined;
  }

  submitReport() {
    const reportToSend: MissionReportDto = {
      missionId: this.currentMissionId,
      total: this.totalCosts,
      tenantId: this.currentMission.tenantId,
      travelDurationInHours: this.travelDurationInHours,
      travelDurationCost: this.travelDurationCost,
      workDurationInHours: this.workDurationInHours,
      workDurationCost: this.workDurationCost,
      customPropertyValues: [],
      usedArticles: this.usedArticles,
    };

    // when there is not existing report, we don't have reportId
    if (this.currentReportId != null && !isNaN(this.currentReportId)) {
      reportToSend.missionReportId = this.currentReportId;
    }
    this._store.dispatch(TechInventoryActions.submitMissionReport({missionReport: reportToSend}))
  }
}
