<ion-item>
  <ion-input class="select" [formControl]="formControl"
             [labelPlacement]="config?.labelPlacement ?? 'stacked'"
             [label]="required ? (label | translate) + ' *': (label | translate)"
             [readonly]="true"
             [disabled]="disabled || Boolean(isLoading$ | async)"
             [required]="required"
             (click)="onClick()">
    <ion-spinner *ngIf="isLoading$ | async" slot="end"></ion-spinner>
    <ion-icon *ngIf="(isLoading$ | async) === false" slot="end" name="chevron-expand-outline"></ion-icon>
  </ion-input>
</ion-item>
