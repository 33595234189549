import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule, NavController} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {KnowledgeArticleStartDto} from "@server-models";
import {
  KnowledgeArticleDetailStartComponent
} from "@shared/components/knowledge-article-detail-start/knowledge-article-detail-start.component";
import {ButtonTechSideMenuComponent} from "@tech/components/button-tech-side-menu/button-tech-side-menu.component";


@Component({
  selector: 'app-infos',
  templateUrl: './articles.page.html',
  styleUrls: ['./articles.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ButtonTechSideMenuComponent, KnowledgeArticleDetailStartComponent],
})
export class ArticlesPage implements OnInit {

  article: KnowledgeArticleStartDto;

  constructor(
    private _activatedRouter: ActivatedRoute,
    private _nav: NavController
  ) {
    this.article = {};

  }

  ngOnInit() {
    this.article = this._activatedRouter.snapshot.data['article'];
  }

  back() {
    this._nav.back()
  }

}
