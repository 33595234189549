<ion-card *ngIf="info.isActive || info.isExpired">
  <ion-item>
    <ion-card-header>
      <div class="title-container">
        <div class="title">
          <ion-card-title [innerText]="info.title"></ion-card-title>
          <div>
            <ion-badge *ngIf="info.isExpired" color="medium"
                       [innerText]="'TECH.INFOS.EXPIRED' | translate"></ion-badge>
          </div>
        </div>
      </div>
      <ion-card-subtitle [innerText]="formatCardSubtitle(info)"></ion-card-subtitle>
    </ion-card-header>
  </ion-item>
  <app-tech-info-buttons-item [info]="info"></app-tech-info-buttons-item>

  <ion-card-content>
    <app-shared-user-html [html]="info.content "></app-shared-user-html>
    <app-shared-attachments [attachmentsInc]="info.attachments"></app-shared-attachments>
  </ion-card-content>
</ion-card>
