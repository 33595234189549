<ion-split-pane when="md" contentId="main">

  <ion-menu contentId="main">
    <ion-header [translucent]="true">
      <ion-toolbar color="primary">
        <ion-title>
          {{ 'COMPONENTS.MISSIONS.TREE_MENU.TITLE' | translate }}
        </ion-title>

      </ion-toolbar>
      <ion-toolbar color="primary">
        <ion-segment (ionChange)="changeTreeMenuSegment($event)" [(ngModel)]="selectedTreeMenuSegment">
          <ion-segment-button [value]="TreeMenuSegmentEnum.Type">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.TREE_MENU.SEGMENTS.TYPE' | translate }}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button [value]="TreeMenuSegmentEnum.Location">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.TREE_MENU.SEGMENTS.LOCATION' | translate }}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button [value]="TreeMenuSegmentEnum.Model">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.TREE_MENU.SEGMENTS.MODEL' | translate }}
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <!--  content menu tree list left side -->
      <ion-spinner
        *ngIf="!ownLoad && (isLoadingMenuTreeMainList$ | async) && !infinityMainTreeLoading"></ion-spinner>

      <ng-container *ngIf="!(!ownLoad && (isLoadingMenuTreeMainList$ | async) && !infinityMainTreeLoading)">
        <ng-container *ngIf="inventoryMenuTree$ | async as currentTree">
          <ng-container *ngIf="currentTree.list.items.length > 0; else noStereotype">
            <ion-list class="stereotype-list">
              <ng-container *ngFor="let item of currentTree.list.items; trackBy: trackById">
                <ion-item
                  [ngClass]="{'is-selected': selectedItem?.mainItem && inventoryHelperService.getMainItemId(selectedItem.mainItem) === inventoryHelperService.getMainItemId(item.mainItem)}"
                  (click)="openResources(item, inventoryHelperService.getMainItemId(item.mainItem)!)">
                  <ion-icon
                    [name]="selectedItem?.mainItem && inventoryHelperService.getMainItemId(selectedItem.mainItem) === inventoryHelperService.getMainItemId(item.mainItem) ? 'chevron-down' : 'chevron-forward'">
                  </ion-icon>
                  <div class="clamped-text">
                    {{ formatNameToDisplay(item.mainItem) }}
                  </div>
                </ion-item>
                <app-tech-child-tree-resources
                  *ngIf="item.resourceItems && selectedItem?.mainItem && inventoryHelperService.getMainItemId(selectedItem.mainItem) === inventoryHelperService.getMainItemId(item.mainItem)"
                  [resourceListDto]="item.resourceItems"
                  [paging]="item.mainItemPaging"
                  [segmentType]="selectedTreeMenuSegment"
                  (onNeedMore)="requestMore($event)">
                </app-tech-child-tree-resources>
                <ion-spinner
                  *ngIf="selectedItem?.mainItem && inventoryHelperService.getMainItemId(selectedItem.mainItem) === inventoryHelperService.getMainItemId(item.mainItem) && item.mainItemIsLoading">
                </ion-spinner>
              </ng-container>
            </ion-list>
          </ng-container>
        </ng-container>

      </ng-container>

      <ion-infinite-scroll
        *ngIf="!(isLoadingMenuTreeMainList$ | async)"
        (ionInfinite)="loadMoreInventoryTreeMenu($event)">
        <ion-infinite-scroll-content>
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <ng-template #noStereotype>
        <ion-card class="card-container">
          <ion-item>
            <ion-label class="ion-text-center"
                       [innerText]="'COMPONENTS.MISSIONS.TREE_MENU.EMPTY' | translate"></ion-label>
          </ion-item>
        </ion-card>
      </ng-template>
    </ion-content>
  </ion-menu>
  <div class="ion-page" id="main">
    <ion-header [translucent]="true">
      <ion-toolbar color="primary">
        <app-button-tech-side-menu [title]="'COMPONENTS.MISSIONS.TITLE' | translate">
        </app-button-tech-side-menu>
      </ion-toolbar>
      <ion-toolbar color="primary">
        <ion-segment (ionChange)="changeSegmentSetting($event)" [(ngModel)]="selectedSegmentSettings">
          <ion-segment-button [value]="SegmentSettingsEnum.Mine">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.SEGMENTS.ME' | translate }}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button [value]="SegmentSettingsEnum.Team">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.SEGMENTS.TEAM' | translate }}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button [value]="SegmentSettingsEnum.Unassigned">
            <ion-label>
              {{ 'COMPONENTS.MISSIONS.SEGMENTS.UNASSIGNED' | translate }}
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>

    </ion-header>
    <ion-content>
      <!--  content mission list right side -->
      <app-tech-inventory-settings [stereotypes]="currentStereotypes">
      </app-tech-inventory-settings>

      <ng-container *ngIf="listMode === 'list'">
        <ion-spinner *ngIf="!ownLoad && (isLoading$ | async) && !infinityLoading"></ion-spinner>
        <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
          <ion-refresher-content pulling-icon="chevron-down"></ion-refresher-content>
        </ion-refresher>

        <ng-container *ngIf="!(!ownLoad && (isLoading$ | async) && !infinityLoading)">
          <ng-container *ngIf="(missionDataList$ | async)?.length! > 0; else noMission">
            <app-tech-inventory-mission-list
              [missionList]="(missionDataList$ | async)!"></app-tech-inventory-mission-list>
          </ng-container>
        </ng-container>

        <ion-infinite-scroll
          (ionInfinite)="loadMoreInventoryMission($event)">
          <ion-infinite-scroll-content>
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>

      </ng-container>
    </ion-content>

    <ng-template #noMission>
      <ion-card class="card-container">
        <ion-item>
          <ion-label class="ion-text-center" [innerText]="'COMPONENTS.MISSIONS.EMPTY' | translate"></ion-label>
        </ion-item>
      </ion-card>
    </ng-template>
  </div>
</ion-split-pane>
