<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ label | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" (click)="onClose()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <app-shared-base-search-bar class="ion-margin-top"
                                  (valueToSearch)="onSearch($event)"
                                  (clear)="onClear()">
      </app-shared-base-search-bar>

      <div class="ion-text-center">
        <ion-spinner *ngIf="isLoading$ | async"></ion-spinner>
      </div>

      <ion-list>
        <ion-item *ngFor="let item of items$ | async; trackBy:trackByFn" (click)="onSelectItem(item)" button detail>
          {{ getDisplayName(item) }}
        </ion-item>
      </ion-list>

      <ion-infinite-scroll (ionInfinite)="onLoadMoreInfinite($event)">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-card-content>
  </ion-card>
</ion-content>
