import {lastValueFrom, take} from 'rxjs';
import {ECoreApiProduct} from '@core/enums/core-api-product.enum';
import {FilterDto, Orders} from '@server-models';
import {IPagination} from '@shared/interfaces/pagination.interface';
import {Injectable} from '@angular/core';
import {
  IEntitySelectConfig
} from "@shared/components/input-entity-select/interfaces/input-entity-select-config.interface";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root'
})
export class SharedInputEntitySelectApiService extends CoreHttpClientApiBaseService {

  private _config?: IEntitySelectConfig;

  async getPage(
    filters: FilterDto[] = [],
    config?: IEntitySelectConfig,
    paging?: IPagination
  ): Promise<any> {
    this._config = config;
    return await lastValueFrom(
      super.post(this._buildPath(), true, [...(this._config?.defaultFilters ?? []), ...filters], {
        params: {
          pageNumber: (paging?.pageNumber ?? 1).toString(),
          pageSize: (paging?.pageSize ?? 20).toString(),
          sortField: this._config?.displayKey ?? '',
          sort: Orders.Ascending.toString()
        }
      })
        .pipe(take(1))
    );
  }

  private _buildPath(): string {
    return `/${ this._getProductSegment(this._config?.apiProduct) }/${ this._config?.apiEndpoint }/filter`;
  }

  private _getProductSegment(product: ECoreApiProduct = ECoreApiProduct.Tech): string {
    switch (product) {
      case ECoreApiProduct.Identity:
        return 'identity';
      case ECoreApiProduct.Orga:
        return 'orga';
      case ECoreApiProduct.Tech:
        return 'tech';
      case ECoreApiProduct.Team:
        return 'team';
      case ECoreApiProduct.Link:
        return 'link';
    }
  }
}
