import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MissionState, StereotypeListDto} from '@server-models';
import {CommonModule} from '@angular/common';
import {IonicModule, ModalController} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {TechInventoryService} from '@tech/pages/inventory/services/tech-inventory.service';
import {TechMissionFilterService} from '@tech/pages/inventory/services/tech-mission-filter.service';
import {TechMenuTreeFilterService} from "@tech/pages/inventory/services/tech-menu-tree-filter.service";
import {SharedFilterListModalComponent} from "@shared/components/filter-list-modal/shared-filter-list-modal.component";
import {IFilterListModal} from "@shared/interfaces/filter-list-modal.interface";
import {EFilterListType} from "@shared/enums/filter-list-type.enum";
import {IInventoryMissionFilterSettings} from "@tech/pages/inventory/interfaces/mission-filter.interface";
import {EMissionDisplayMode} from "@tech/pages/inventory/enums/mission-display-mode.enum";

@Component({
  selector: 'app-tech-inventory-settings',
  templateUrl: './tech-inventory-settings.component.html',
  styleUrls: ['./tech-inventory-settings.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, FormsModule],
})
export class TechInventorySettingsComponent implements OnInit, OnChanges {
  @Input() stereotypes: StereotypeListDto[];
  @Input() displayType: EMissionDisplayMode = EMissionDisplayMode.LIST;
  @Output() displayTypeChange: EventEmitter<EMissionDisplayMode> = new EventEmitter<EMissionDisplayMode>();
  @Output() missionMapDistanceChanged!: EventEmitter<number | string | null>;
  @Output() missionListSortByChanged: EventEmitter<any> = new EventEmitter<any>();

  currentSelectedObject: {
    list?: EMissionDisplayMode;
    state?: MissionState | null | '';
    stereotype?: number | null | '';
    onlyMap?: {
      distance?: number | string | null
    },
    onlyList?: {
      sortBy?: string,
      locationNumber?: string;
    }
  };

  protected readonly MissionState = MissionState;

  constructor(
    private _inventoryService: TechInventoryService,
    private _missionFilterService: TechMissionFilterService,
    private _menuTreeFilterService: TechMenuTreeFilterService,
    private _modalController: ModalController,
    private _translateService: TranslateService
  ) {
    this.missionMapDistanceChanged = new EventEmitter<any>();

    this.currentSelectedObject = <{
      list?: EMissionDisplayMode;
      state?: MissionState | null | '';
      stereotype?: number | null | '';
      onlyMap?: {
        distance: number | string | undefined
      },
      onlyList?: {
        sortBy: string,
        locationNumber?: string;
      }
    }>{};

    this.stereotypes = [];
  }

  ngOnInit() {
    this.currentSelectedObject = {
      list: EMissionDisplayMode.LIST,
      state: MissionState.Instructed,
      stereotype: null,
      onlyMap: {
        distance: undefined
      },
      onlyList: {
        sortBy: undefined,
        locationNumber: undefined
      }
    };
    this._inventoryService.getSettingReset().subscribe(({ state, stereotype }) => {
      this.currentSelectedObject.state = state;
      this.currentSelectedObject.stereotype = stereotype;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['displayType']) {
      this.currentSelectedObject.list = this.displayType;
    }
  }

  get isStereotypeSelected(): boolean {
    return this.currentSelectedObject?.stereotype !== null &&
      this.currentSelectedObject?.stereotype !== undefined &&
      this.currentSelectedObject?.stereotype !== '';
  }

  /**
   * @name showFilterModal
   * @description
   * show up the filter modal
   * @memberof TechInventorySettingsComponent
   * @return {Promise<void>}
   * @async
   */
  async showFilterModal(): Promise<void> {
    const filterModal = await this._modalController.create({
      component: SharedFilterListModalComponent,
      componentProps: {
        filterList: this.getCurrentFilters(),
      },
    });
    await filterModal.present();

    const { data } = await filterModal.onDidDismiss();

    if (data) {
      this.handleFilterUpdate(data);
    }

  }

  /**
   * @name getCurrentFilters
   * @description prepare the object of the current filters to be pass to the filter modal
   * @memberof TechInventorySettingsComponent
   * @return {IFilterListModal[]}
   */
  getCurrentFilters(): IFilterListModal[] {
    const filterList: IFilterListModal[] = [
      {
        type: EFilterListType.select,
        keyId: 'stereotype',
        placeholder: this._translateService.instant('COMPONENTS.MISSIONS.RESOURCE.PLACEHOLDER'),
        currentValue: this.currentSelectedObject.stereotype,
        options: [
          { display: this._translateService.instant('COMPONENTS.MISSIONS.RESOURCE.ALL'), value: '' },
          ...this.stereotypes.map(stereotype => ({
            display: stereotype.name,
            value: stereotype.stereotypeId,
          })),
        ],
        isHidden: false
      },
      {
        type: EFilterListType.select,
        keyId: 'state',
        placeholder: this._translateService.instant('COMPONENTS.MISSIONS.STATE.PLACEHOLDER'),
        currentValue: this.currentSelectedObject.state,
        options: [
          { display: this._translateService.instant('COMPONENTS.MISSIONS.STATE.ALL'), value: '' },
          {
            display: this._translateService.instant('COMPONENTS.MISSIONS.STATE.INSTRUCTED'),
            value: MissionState.Instructed
          },
          { display: this._translateService.instant('COMPONENTS.MISSIONS.STATE.CREATED'), value: MissionState.Created },
          { display: this._translateService.instant('COMPONENTS.MISSIONS.STATE.DONE'), value: MissionState.Done },
        ],
        isHidden: false
      }
    ];

    if (this.displayType === EMissionDisplayMode.LIST) {
      filterList.push(
        {
          type: EFilterListType.select,
          keyId: 'sortBy',
          placeholder: this._translateService.instant('COMPONENTS.MISSIONS.SORT_BY.PLACEHOLDER'),
          currentValue: this.currentSelectedObject.onlyList?.sortBy || 'plannedStartDesc',
          options: [
            {
              display: this._translateService.instant('COMPONENTS.MISSIONS.SORT_BY.OPTIONS.START.DSC'),
              value: 'plannedStartDesc'
            },
            {
              display: this._translateService.instant('COMPONENTS.MISSIONS.SORT_BY.OPTIONS.START.ASC'),
              value: 'plannedStartAsc'
            },
            {
              display: this._translateService.instant('COMPONENTS.MISSIONS.SORT_BY.OPTIONS.END.DSC'),
              value: 'plannedEndDesc'
            },
            {
              display: this._translateService.instant('COMPONENTS.MISSIONS.SORT_BY.OPTIONS.END.ASC'),
              value: 'plannedEndAsc'
            }
          ],
          isHidden: true
        },
        {
          type: EFilterListType.input,
          keyId: 'locationNumber',
          placeholder: this._translateService.instant('COMPONENTS.MISSIONS.LOCATION_NUMBER.PLACEHOLDER'),
          currentValue: this.currentSelectedObject.onlyList?.locationNumber,
          isHidden: true
        }
      );
    }

    if (this.displayType === EMissionDisplayMode.MAP) {
      filterList.push(
        {
          type: EFilterListType.select,
          keyId: 'distance',
          placeholder: this._translateService.instant('COMPONENTS.MISSIONS.DISTANCE.PLACEHOLDER'),
          currentValue: this.currentSelectedObject.onlyMap?.distance || '',
          options: [
            { display: this._translateService.instant('COMPONENTS.MISSIONS.DISTANCE.OPTIONS.DEFAULT'), value: '' },
            { display: this._translateService.instant('10km'), value: 10 },
            { display: this._translateService.instant('25km'), value: 25 },
            { display: this._translateService.instant('50km'), value: 50 },
            { display: this._translateService.instant('100km'), value: 100 },
            { display: this._translateService.instant('250km'), value: 250 },
            { display: this._translateService.instant('500km'), value: 500 },
          ],
          isHidden: false
        });
    }

    return filterList;
  }

  /**
   * @name handleFilterUpdate
   * @description
   * on the filter modal is close this function will update the values on the settings bar
   * @memberof TechInventorySettingsComponent
   * @param updatedFilters
   */
  handleFilterUpdate(updatedFilters: IFilterListModal[]): void {
    const mapDistance = updatedFilters.find(filter => filter.keyId === 'distance')
    if (mapDistance) {
      this.missionMapDistance(<CustomEvent>{ detail: { value: mapDistance.currentValue } });
      return;
    }

    const currentFilters = this._missionFilterService.filterSettingsSubject.getValue();

    let updatedFilterSettings: IInventoryMissionFilterSettings = { ...currentFilters };

    updatedFilters.forEach((filter: IFilterListModal) => {
      switch (filter.keyId) {
        case 'stereotype':
          updatedFilterSettings.stereotypeId = this.changeStereotypeMode(<CustomEvent>{ detail: { value: filter.currentValue } });
          break;

        case 'state':
          updatedFilterSettings.state = this.changeStatusMode(<CustomEvent>{ detail: { value: filter.currentValue } });
          break;

        case 'locationNumber':
          if (updatedFilterSettings.onlyList) {
            updatedFilterSettings.onlyList.locationNumber = this.missionListLocationNumber(filter.currentValue) || undefined;
          }
          break;
        case 'sortBy':
          if (updatedFilterSettings.onlyList) {
            updatedFilterSettings.onlyList.sortBy = this.missionListSortBy(filter.currentValue);
          }
          break;
      }
    });
    this._missionFilterService.filterSettingsSubject.next(updatedFilterSettings);
  }

  toggleDisplayType() {
    switch (this.displayType) {
      case EMissionDisplayMode.LIST:
        this.displayType = EMissionDisplayMode.MAP;
        break;
      case EMissionDisplayMode.MAP:
        this.displayType = EMissionDisplayMode.LIST;
        break;
    }
    this.displayTypeChange.emit(this.displayType);
  }

  /**
   * @name changeStatusMode
   * @description
   * emit the value for bodyfilter status request
   * @memberof
   * @param ev
   */
  changeStatusMode(ev: CustomEvent) {
    const missionState = ev.detail.value;
    missionState == '' ? this.currentSelectedObject.state = null : this.currentSelectedObject.state = missionState;

    if ('isTrusted' in ev) {
      this._missionFilterService.filterSettingsSubject.next({
        ...this._missionFilterService.filterSettingsSubject.getValue(),
        state: missionState === '' ? undefined : missionState,
      });
    }

    return missionState;
  }

  /**
   * @name changeStereotypeMode
   * @description
   * emit the value bodyfilter resource request
   * @memberof TechInventorySettingsComponent
   * @param ev
   */
  changeStereotypeMode(ev: CustomEvent) {
    const stereotype = ev.detail.value;
    stereotype == '' ? this.currentSelectedObject.stereotype = null : this.currentSelectedObject.stereotype = stereotype;

    if ('isTrusted' in ev) {
      this._missionFilterService.filterSettingsSubject.next({
        ...this._missionFilterService.filterSettingsSubject.getValue(),
        stereotypeId: stereotype === '' ? undefined : stereotype,
      });
    }
    return stereotype;
  }

  missionMapDistance(ev: CustomEvent): void {
    const newDistance = ev.detail.value;
    if (this.currentSelectedObject.onlyMap) {
      newDistance == '' ? this.currentSelectedObject.onlyMap.distance = null : this.currentSelectedObject.onlyMap.distance = newDistance;
      this.missionMapDistanceChanged.emit(this.currentSelectedObject.onlyMap.distance);
    }
  }

  missionListSortBy(newSort: string | undefined) {
    if (this.currentSelectedObject.onlyList) {
      this.currentSelectedObject.onlyList.sortBy = newSort;
      this.missionListSortByChanged.emit(this.currentSelectedObject.onlyList.sortBy);
    }
    return newSort;
  }

  missionListLocationNumber(newLocationNumber: string | undefined) {
    if (this.currentSelectedObject.onlyList) {
      this.currentSelectedObject.onlyList.locationNumber = newLocationNumber;
    }
    return newLocationNumber;
  }

  clearSettingsFilters() {
    this._missionFilterService.filterSettingsSubject.next({
      ...this._missionFilterService.filterSettingsSubject.getValue(),
      state: undefined,
      stereotypeId: undefined,
      resource: undefined,
      parentResource: undefined,
      onlyList: {
        sortBy: undefined,
        locationNumber: undefined
      }
    });
    this.currentSelectedObject.stereotype = null;
    this.currentSelectedObject.state = null;
    if (this.currentSelectedObject.onlyMap) {
      this.currentSelectedObject.onlyMap.distance = undefined;
    }
    if (this.currentSelectedObject.onlyList) {
      this.currentSelectedObject.onlyList.sortBy = undefined;
      this.currentSelectedObject.onlyList.locationNumber = undefined;
    }
    this._menuTreeFilterService.requestSettingSubject.next({
        ...this._menuTreeFilterService.requestSettingSubject.getValue(),
        isRefresh: true
      }
    );

  }

  protected readonly EMissionDisplayMode = EMissionDisplayMode;
}
