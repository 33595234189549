import {Injectable} from "@angular/core";
import {TechHttpClientBaseApiService} from "@tech/services/http-client-base-api/tech-http-client-base-api.service";
import {StereotypeApiService} from "@shared/components/issues/services/stereotype/stereotype-api.service";
import {Observable} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {StereotypeDto, StereotypeListDto} from "@server-models";
import {RequestOptions} from "@shared/interfaces/request-options.interface";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";

@Injectable({
  providedIn: 'root',
})
export class TechStereotypeApiService extends TechHttpClientBaseApiService implements StereotypeApiService {
  /**
   * @name getStereotypeById
   * @description
   * make a request to stereotype by id
   * @memberof TechStereotypeApiService
   * @param id
   * @returns {Observable<StereotypeDto>}
   */
  getStereotypeById(id: number): Observable<StereotypeDto> {
    return super.get<StereotypeDto>(`/tech/stereotype/${id}`, undefined);
  }

  /**
   * @name getStereotypeFilterByEntityType
   * @description
   * make a request to stereotype using entityType and cache-control
   * @memberof TechStereotypeApiService
   * @param entityType
   * @param cacheControl
   * @returns {Observable<OrgaResponse<StereotypeListDto>>}
   */
  getStereotypeFilterByEntityType(entityType: string, cacheControl: string): Observable<OrgaResponse<StereotypeListDto>> {
    const options = {
      params: {
        entityType: entityType.toString(),
        Cols: 'CustomPropertySets',
        'cache-control': cacheControl
      }
    }
    return super.get<OrgaResponse<StereotypeListDto>>(`/tech/stereotype`, undefined, options);
  }

  postStereotypeFilterByEntityTypePaginated(paramsPaginated: PaginationParams, body: any): Observable<OrgaResponse<StereotypeListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        ...(paramsPaginated.entityType && { entityType: paramsPaginated.entityType.toString() }),
        ...(paramsPaginated.archived && { archived: paramsPaginated.archived.toString() }),
      }

    }
    return super.post<OrgaResponse<StereotypeListDto[]>>(`/tech/stereotype/filter`, undefined, body, options);
  }
}
