<ion-card-title>
  <app-shared-issue-custom-read-only-mission-state-options
    [stateOption]="stateOption"></app-shared-issue-custom-read-only-mission-state-options>
</ion-card-title>

<ion-toolbar class="actions" *ngIf="availableActions.length > 0">
  <ng-container *ngIf="!(changeStateLoading$ | async); else loading">
    <ion-button [color]="action.color" *ngFor="let action of availableActions" size="small" fill="outline"
                (click)="dispatchActionState(action.state)">
      <app-shared-issue-custom-read-only-mission-state-actions
        [stateOption]="action.state"></app-shared-issue-custom-read-only-mission-state-actions>
    </ion-button>
  </ng-container>
</ion-toolbar>

<ng-template #loading>
  <ion-spinner></ion-spinner>
</ng-template>

