import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {mergeMap, Observable, withLatestFrom} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {IOrgaResponse} from '@shared/interfaces/orga-response.interface';
import {
  AppEntityType,
  ArticleListDto,
  FilterDto,
  FilterKind,
  FilterOperators,
  FilterTypes,
  IssueType,
  LocationListDto,
  MissionCompleteDto,
  MissionDto,
  MissionReportDto,
  MissionReportPreviewDto,
  MissionState,
  ResourceDto,
  ResourceModelListDto,
  StereotypeDto,
  StereotypeListDto,
} from '@server-models';
import {IPagination} from '@shared/interfaces/pagination.interface';
import {TechInventoryApiService} from '@tech/pages/inventory/services/tech-inventory-api.service';
import {TechInventoryBaseEffects} from '@tech/pages/inventory/store/tech-inventory-base.effects';
import {IInventoryRequestPagination} from '@tech/pages/inventory/interfaces/inventory-request-pagination.interface';
import {TechInventorySelectors} from '@tech/pages/inventory/store/tech-inventory.selector-type';
import {TechInventoryActions} from '@tech/pages/inventory/store/tech-inventory.actions-type';
import {SharedCacheService} from '@shared/services/cache/shared-cache.service';
import {TechStereotypeApiService} from '@tech/services/stereotype/tech-stereotype-api.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastControllerService} from '@shared/services/toast-controller.service';
import {
  ITechInventoryPageMenuTreeItemList,
  ITechInventoryPageMenuTreeItemListMainItem,
} from '@tech/pages/inventory/store/tech-inventory.state';
import {TechResourceApiService} from '@tech/pages/inventory/services/tech-resource-api.service';
import {ETreeMenuSegment} from '@tech/pages/inventory/enums/tree-menu-segment.enum';
import {TechInventoryHelperService} from '@tech/pages/inventory/services/tech-iventory-helper.service';
import {
  TechInventoryMissionPagesService
} from '@tech/pages/inventory/components/mission/components/detail/services/tech-mission-pages.service';
import {SharedStereotypeService} from '@shared/services/stereotype/shared-stereotype.service';
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryApiEffects extends TechInventoryBaseEffects {
  constructor(
    actions$: Actions,
    store: Store,
    private _techInventoryApiService: TechInventoryApiService,
    private _techStereotypeApiService: TechStereotypeApiService,
    private _techResourceApiService: TechResourceApiService,
    private _stereotypeBaseService: SharedStereotypeService,
    private _cacheService: SharedCacheService,
    private _router: Router,
    private _toastService: ToastControllerService,
    private _translateService: TranslateService,
    private _techInventoryHelperService: TechInventoryHelperService,
    private _missionPagesService: TechInventoryMissionPagesService
  ) {
    super(store, actions$);
  }

  postMissionPaginated(
    action$: Observable<IInventoryRequestPagination>
  ): Observable<IOrgaResponse<MissionCompleteDto[]> | any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMissionPagination
          )
        )
      ),
      mergeMap(([action, paginationState]) => {
        if (action.isRefresh) {
          return this._requestPostMissionPaginated(action);
        } else if (this._isLastPagePaginationState(action, paginationState)) {
          return this._requestPostMissionPaginated(action);
        } else {
          return [TechInventoryActions.postMissionPagePaginatedCancel()];
        }
      })
    );
  }

  private _isLastPagePaginationState(
    action: IInventoryRequestPagination,
    state: IPagination
  ): boolean {
    return !state || action.pagination.pageNumber <= state.totalPages;
  }

  private _requestPostMissionPaginated(
    action: IInventoryRequestPagination
  ): Observable<IOrgaResponse<MissionCompleteDto[]> | any> {
    return this._techInventoryApiService
      .pagePostMissionPaginated(action.pagination, action.filters)
      .pipe(
        map((data: IOrgaResponse<MissionCompleteDto[]>) =>
          action.isRefresh
            ? TechInventoryActions.postMissionPagePaginatedRefresh({ data })
            : TechInventoryActions.postMissionPagePaginatedSuccess({ data })
        ),
        catchError((error) => [
          TechInventoryActions.postMissionPagePaginatedFail({ error }),
        ])
      );
  }

  postMissionPaginatedSuccess(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      mergeMap(() => {
        return this._requestStereotypeFilterByEntityType();
      })
    );
  }

  private _requestStereotypeFilterByEntityType(): Observable<any> {
    const entityType = AppEntityType.Mission;
    const cacheControl = this._cacheService.generateGuid();
    return this._techStereotypeApiService
      .getStereotypeFilterByEntityType(entityType, cacheControl)
      .pipe(
        map((data: IOrgaResponse<StereotypeListDto>) => {
          return TechInventoryActions.postMissionPagePaginatedDone({ data });
        }),
        catchError((error) => [
          TechInventoryActions.postMissionPagePaginatedFail({ error }),
        ])
      );
  }

  navigateToMissionDetail(
    action$: Observable<MissionCompleteDto>
  ): Observable<any> {
    return action$.pipe(
      tap((mission) => {
        this._router.navigate([
          `tech/logged/inventory/mission/${ mission.missionId }`,
        ]);
      })
    );
  }

  getMissionDetailById(
    action$: Observable<{
      missionId: string;
      existingUrl: string[];
    }>
  ): Observable<any> {
    return action$.pipe(
      mergeMap(({ missionId, existingUrl }) => {
        return this._requestMissionDetailById(
          missionId,
          existingUrl
        );
      })
    );
  }

  private _requestMissionDetailById(
    missionId: string,
    existingUrl: string[]
  ): Observable<any> {
    return this._techInventoryApiService.getMissionDetailById(missionId!).pipe(
      map((data: MissionDto) => {
        return TechInventoryActions.getMissionDetailByIdSuccess({
          data,
          existingUrl,
        });
      }),
      catchError((error) => [
        TechInventoryActions.getMissionDetailByIdFail({ error }),
      ])
    );
  }

  getMissionDetailByIdSuccess(
    action$: Observable<{
      data: MissionDto;
      existingUrl: string[];
    }>
  ): Observable<any> {
    return action$.pipe(
      mergeMap(({ data, existingUrl }) => {
        return [
          TechInventoryActions.getMissionDetailStereotypeById({
            id: data.stereotypeId!,
            existingUrl,
          }),
        ];
      })
    );
  }

  getMissionDetailStereotypeById(
    action$: Observable<{
      id: number;
      existingUrl: string[];
    }>
  ): Observable<any> {
    return action$.pipe(
      mergeMap(({ id, existingUrl }) =>
        this._requestGetStereotypeById({ id, existingUrl })
      )
    );
  }

  private _requestGetStereotypeById(action: {
    id: number;
    existingUrl: string[];
  }): Observable<
    | {
    data: StereotypeDto;
  }
    | any
  > {
    const cacheGuid = this._cacheService.generateGuid();
    return this._techStereotypeApiService.getStereotypeById(action.id).pipe(
      map((data: StereotypeDto) => {
        return TechInventoryActions.getMissionDetailStereotypeByIdSuccess({
          stereotype: data,
          cacheGuid,
          existingUrl: action.existingUrl,
        });
      }),
      catchError((error) => [
        TechInventoryActions.getMissionDetailStereotypeByIdFail({ error }),
      ])
    );
  }

  getMissionDetailStereotypeByIdSuccess(
    action$: Observable<{
      stereotype: StereotypeDto;
      cacheGuid: string;
      existingUrl: string[];
    }>
  ): Observable<any> {
    return action$.pipe(
      withLatestFrom(
        this.store.select(
          TechInventorySelectors.selectTechInventoryPageMissionDetail
        )
      ),
      mergeMap(([{ stereotype, existingUrl }, mission]) => {
        const formattedData =
          this._stereotypeBaseService.customPropertiesFormattedFromStereotype(
            mission?.missionId!,
            mission?.title!,
            IssueType.Mission,
            mission?.customPropertyValues!,
            stereotype
          );
        const segmentString = existingUrl[0];
        const segmentType =
          this._missionPagesService.checkSegmentByString(segmentString);
        this._missionPagesService.changeSegmentSetting(segmentType);
        return [
          TechInventoryActions.getMissionDetailByIdDone({
            customPropertySets: formattedData.customPropertySets,
          }),
        ];
      })
    );
  }

  setMissionState(
    action$: Observable<{
      id: number;
      missionToChange: MissionState;
    }>
  ): Observable<any> {
    return action$.pipe(
      mergeMap(({ id, missionToChange }) =>
        this._requestSetMissionState({ id, missionToChange })
      )
    );
  }

  private _requestSetMissionState(action: {
    id: number;
    missionToChange: MissionState;
  }): Observable<any> {
    return this._techInventoryApiService
      .postChangeMissionStateById(action.id, action.missionToChange)
      .pipe(
        map(() => {
          return TechInventoryActions.setMissionStateSuccess({
            missionState: action.missionToChange,
          });
        }),
        catchError((error) => [
          TechInventoryActions.setMissionStateFail({ error }),
        ])
      );
  }

  setMissionStateSuccess(
    action$: Observable<{ missionState: MissionState }>
  ): Observable<any> {
    return action$.pipe(
      tap(({ missionState }) => {
        return this._toastService.observableToast({
          message: `${ this._translateService.instant(
            'COMPONENTS.MISSIONS.STATE.ALERT.MESSAGE'
          ) }`,
        });
      })
    );
  }

  navigateToInventory(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      tap(() => {
        this._router.navigateByUrl('tech/logged/inventory');
      })
    );
  }

  // report
  getMissionReportById(
    action$: Observable<{ reportId: string }>
  ): Observable<any> {
    return action$.pipe(
      mergeMap(({ reportId }) => {
        return this._requestMissionReportById(reportId);
      })
    );
  }

  private _requestMissionReportById(reportId: string): Observable<any> {
    return this._techInventoryApiService.getMissionReportById(reportId).pipe(
      map((data: MissionReportDto) => {
        return TechInventoryActions.getMissionReportByIdSuccess({ data });
      }),
      catchError((error) => [
        TechInventoryActions.getMissionReportByIdFail({ error }),
      ])
    );
  }

  getMissionReportPreviewById(
    action$: Observable<{ missionId: string }>
  ): Observable<any> {
    return action$.pipe(
      mergeMap(({ missionId }) => {
        return this._requestMissionReportPreviewById(missionId);
      })
    );
  }

  private _requestMissionReportPreviewById(missionId: string): Observable<any> {
    return this._techInventoryApiService
      .getMissionReportPreviewById(missionId)
      .pipe(
        map((data: MissionReportPreviewDto) => {
          return TechInventoryActions.getMissionReportPreviewByIdSuccess({
            data,
          });
        }),
        catchError((error) => [
          TechInventoryActions.getMissionReportPreviewByIdFail({ error }),
        ])
      );
  }

  getMissionReportArticleList(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      mergeMap(({ searchTerm }) => {
        return this._requestMissionReportArticleList(searchTerm);
      })
    );
  }

  private _requestMissionReportArticleList(
    searchTerm?: string
  ): Observable<any> {
    const body: FilterDto[] = [];

    if (searchTerm?.length) {
      body.push({
        kind: FilterKind.Default,
        type: FilterTypes.DataTransferObject,
        property: 'name',
        operator: FilterOperators.Contains,
        value: searchTerm,
      });
    }

    return this._techInventoryApiService
      .postMissionReportArticleFilter(body)
      .pipe(
        map((data: IOrgaResponse<ArticleListDto[]>) => {
          return TechInventoryActions.getMissionReportArticleListSuccess({
            data: data.items,
          });
        }),
        catchError((error) => [
          TechInventoryActions.getMissionReportArticleListFail({ error }),
        ])
      );
  }

  submitMissionReport(
    action$: Observable<{
      missionReport: MissionReportDto;
      hasReportId: boolean;
    }>
  ): Observable<any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypesFormattedData
          )
        )
      ),
      mergeMap(([{ missionReport, hasReportId }]) => {
        return this._submitMissionReport(missionReport, hasReportId);
      })
    );
  }

  private _submitMissionReport(
    missionReport: MissionReportDto,
    hasReportId: boolean
  ): Observable<any> {
    if (hasReportId) {
      return this._techInventoryApiService
        .putSubmitMissionReportById(missionReport)
        .pipe(
          map((data) => {
            return TechInventoryActions.submitMissionReportSuccess({
              response: data,
              hasReportId,
            });
          }),
          catchError((error) => [
            TechInventoryActions.submitMissionReportFail({ error }),
          ])
        );
    } else {
      return this._techInventoryApiService
        .postSubmitMissionReport(missionReport)
        .pipe(
          map((data) => {
            return TechInventoryActions.submitMissionReportSuccess({
              response: data,
              hasReportId,
            });
          }),
          catchError((error) => [
            TechInventoryActions.submitMissionReportFail({ error }),
          ])
        );
    }
  }

  submitMissionReportSuccess(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      tap(({ hasReportId }) => {
        if (hasReportId) {
          this._toastService.observableToast({
            message: this._translateService.instant(
              'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.FORM.SUCCESS.PUT_MESSAGE'
            ),
          });
        } else {
          this._toastService.observableToast({
            message: this._translateService.instant(
              'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.FORM.SUCCESS.POST_MESSAGE'
            ),
          });
        }
        const urlSegments = this._router.url.split('/');
        urlSegments.push('success');
        this._router.navigate(urlSegments, {
          state: { isPost: hasReportId },
        });
      })
    );
  }

  requestReportStereotypesByEntity(
    action$: Observable<{
      entityType: AppEntityType;
      request: IInventoryRequestPagination;
    }>
  ): Observable<IOrgaResponse<StereotypeListDto[]> | any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypes
          )
        )
      ),
      mergeMap(([{ entityType, request }, reportStereotype]) => {
        if (request.isRefresh) {
          return this._requestReportStereotypesByEntity(entityType, request);
        } else if (
          this._isLastPagePaginationState(request, reportStereotype.paging!)
        ) {
          return this._requestReportStereotypesByEntity(entityType, request);
        } else {
          return [
            TechInventoryActions.requestReportStereotypesByEntityCancel(),
          ];
        }
      })
    );
  }

  private _requestReportStereotypesByEntity(
    entityType: AppEntityType,
    request: IInventoryRequestPagination
  ): Observable<IOrgaResponse<StereotypeListDto[]> | any> {
    const params = {
      pageNumber: request.pagination.pageNumber,
      pageSize: request.pagination.pageSize,
      cols: request.pagination.cols,
      entityType: entityType
    };
    return this._techStereotypeApiService
      .postStereotypeFilterByEntityTypePaginated(params, [
        {
          property: 'isArchived',
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Equal,
          value: 'false'
        }
      ])
      .pipe(
        map((data: IOrgaResponse<StereotypeListDto[]>) => {
          return request.isRefresh
            ? TechInventoryActions.requestReportStereotypesByEntityRefresh({
              data,
            })
            : TechInventoryActions.requestReportStereotypesByEntitySuccess({
              data,
            });
        }),
        catchError((error) => [
          TechInventoryActions.requestReportStereotypesByEntityFail({ error }),
        ])
      );
  }

  requestReportStereotypesByEntityRefresh(
    action$: Observable<any>
  ): Observable<any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypes
          )
        )
      ),
      mergeMap(([_]) => {
        return this._requestGetAllStereotypesByEntity().pipe(
          map((results) => {
            return TechInventoryActions.requestReportAllStereotypesByEntitySuccess(
              { data: results.items }
            );
          }),
          catchError((error) => {
            return [
              TechInventoryActions.getMissionDetailStereotypeByIdFail({
                error,
              }),
            ];
          })
        );
      })
    );
  }

  requestReportStereotypesByEntitySuccess(
    action$: Observable<any>
  ): Observable<any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypes
          )
        )
      ),
      mergeMap(([_]) => {
        return this._requestGetAllStereotypesByEntity().pipe(
          map((results) => {
            return TechInventoryActions.requestReportAllStereotypesByEntitySuccess(
              { data: results.items }
            );
          }),
          catchError((error) => {
            return [
              TechInventoryActions.getMissionDetailStereotypeByIdFail({
                error,
              }),
            ];
          })
        );
      })
    );
  }

  requestReportAllStereotypesByEntitySuccess(
    action$: Observable<any>
  ): Observable<any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMissionReportStereotypeTypes
          )
        )
      ),
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMissionReportData
          )
        )
      ),
      mergeMap(([[_, stereotypes], reportType]) => {
        const formattedStereotypeToCustomProperty: any[] = [];
        if (reportType) {
          stereotypes.data!.map((stereotypeListDto, index) => {
            if (stereotypes.allStereotypes) {
              formattedStereotypeToCustomProperty.push(
                this._stereotypeBaseService.customPropertiesFormattedFromStereotype(
                  stereotypes.allStereotypes[index].stereotypeId!,
                  stereotypes.allStereotypes[index].name!,
                  stereotypes.allStereotypes[index].entityType,
                  reportType.customPropertyValues!,
                  stereotypes.allStereotypes[index]
                )
              );
            }
          });
        }
        return [
          TechInventoryActions.requestReportStereotypesByEntityFormat({
            formattedData: formattedStereotypeToCustomProperty,
          }),
        ];
      })
    );
  }

  _requestGetAllStereotypesByEntity(): Observable<| { data: StereotypeDto; } | any> {
    const cacheGuid = this._cacheService.generateGuid();

    return this._techStereotypeApiService.getStereotypeFilterByEntityType(
      AppEntityType.MissionReport,
      cacheGuid
    );
  }

  selectedStereotypeReportType(
    action$: Observable<{ reportType: any }>
  ): Observable<any> {
    return action$.pipe(
      mergeMap((_) => {
        return TechInventoryActions.requestReportStereotypesByEntityDone({});
      })
    );
  }

  // tree
  requestMenuTreeMainListPaginated(
    action$: Observable<{
      currentSegment: ETreeMenuSegment;
      request: IInventoryRequestPagination;
    }>
  ): Observable<any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMenuTreeMainListPaging
          )
        )
      ),
      mergeMap(([action, paginationState]) => {
        const segmentHandlers = {
          [ETreeMenuSegment.Type]: () =>
            this._requestMenuTreeMainListPaginatedStereotype(action.request),
          [ETreeMenuSegment.Location]: () =>
            this._requestMenuTreeMainListPaginatedLocation(action.request),
          [ETreeMenuSegment.Model]: () =>
            this._requestMenuTreeMainListPaginatedModel(action.request),
        };

        if (
          action.request.isRefresh ||
          this._isLastPagePaginationState(action.request, paginationState)
        ) {
          return segmentHandlers[action.currentSegment]?.() ?? [];
        } else {
          return [
            TechInventoryActions.requestMenuTreeMainListPaginatedCancel(),
          ];
        }
      })
    );
  }

  private _requestMenuTreeMainListPaginatedStereotype(
    action: IInventoryRequestPagination
  ): Observable<IOrgaResponse<StereotypeListDto[]> | any> {
    return this._techStereotypeApiService
      .postStereotypeFilterByEntityTypePaginated(
        action.pagination,
        action.filters
      )
      .pipe(
        map((data: IOrgaResponse<StereotypeListDto[]>) => {
          const formattedData = this._mainTreeFormattedData(data);
          return action.isRefresh
            ? TechInventoryActions.requestMenuTreeMainListPaginatedRefresh({
              data: formattedData,
            })
            : TechInventoryActions.requestMenuTreeMainListPaginatedSuccess({
              data: formattedData,
            });
        }),
        catchError((error) => [
          TechInventoryActions.requestMenuTreeMainListPaginatedFail({ error }),
        ])
      );
  }

  private _requestMenuTreeMainListPaginatedLocation(
    action: IInventoryRequestPagination
  ): Observable<IOrgaResponse<LocationListDto[]> | any> {
    return this._techInventoryApiService
      .postLocationFilter(action.pagination, action.filters)
      .pipe(
        map((data: IOrgaResponse<LocationListDto[]>) => {
          const formattedData = this._mainTreeFormattedData(data);
          return action.isRefresh
            ? TechInventoryActions.requestMenuTreeMainListPaginatedRefresh({
              data: formattedData,
            })
            : TechInventoryActions.requestMenuTreeMainListPaginatedSuccess({
              data: formattedData,
            });
        }),
        catchError((error) => [
          TechInventoryActions.requestMenuTreeMainListPaginatedFail({ error }),
        ])
      );
  }

  private _requestMenuTreeMainListPaginatedModel(
    action: IInventoryRequestPagination
  ): Observable<IOrgaResponse<ResourceModelListDto[]> | any> {
    return this._techInventoryApiService
      .postModelFilter(action.pagination, action.filters)
      .pipe(
        map((data: IOrgaResponse<ResourceModelListDto[]>) => {
          const formattedData = this._mainTreeFormattedData(data);
          return action.isRefresh
            ? TechInventoryActions.requestMenuTreeMainListPaginatedRefresh({
              data: formattedData,
            })
            : TechInventoryActions.requestMenuTreeMainListPaginatedSuccess({
              data: formattedData,
            });
        }),
        catchError((error) => [
          TechInventoryActions.requestMenuTreeMainListPaginatedFail({ error }),
        ])
      );
  }

  private _mainTreeFormattedData(
    response: IOrgaResponse<(StereotypeListDto | LocationListDto | ResourceModelListDto)[]>):
    ITechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto> {
    const env = CoreEnvironmentService.getEnvironment();
    return {
      paging: response.paging,
      isLoading: false,
      items: response.items.map((stereotype) => ({
        mainItem: stereotype,
        mainItemPaging: {
          totalItems: 0,
          pageNumber: 0,
          pageSize: env?.apiUrl?.pageDefaultSize || 1,
          totalPages: 1,
        },
        isMainItemLoading: true,
      })),
      selectedMainItem: {},
    };
  }

  requestMenuTreeResourcePaginated(
    action$: Observable<{
      mainItemId: number;
      request: IInventoryRequestPagination;
    }>
  ): Observable<IOrgaResponse<ResourceDto[]> | any> {
    return action$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(
            TechInventorySelectors.selectTechInventoryPageMenuTreeMainListItems
          )
        )
      ),
      mergeMap(([{ mainItemId, request }, stereotypeItems]) => {
        const pagination = this._getPaginationResourceByMainItemId(
          mainItemId,
          stereotypeItems
        );

        if (request.isRefresh) {
          return this._requestPostResourceTreeMenuPaginated({
            parentId: mainItemId,
            request,
          });
        } else if (this._isLastPagePaginationState(request, pagination!)) {
          return this._requestPostResourceTreeMenuPaginated({
            parentId: mainItemId,
            request,
          });
        } else {
          return [
            TechInventoryActions.requestMenuTreeResourcePaginatedCancel({
              mainItemId,
            }),
          ];
        }
      })
    );
  }

  private _getPaginationResourceByMainItemId(
    mainItemId: number,
    items: ITechInventoryPageMenuTreeItemListMainItem<
      StereotypeListDto | LocationListDto | ResourceModelListDto
    >[]
  ): IPagination | null {
    const item = items.find(
      (item) =>
        this._techInventoryHelperService.getMainItemId(item.mainItem) ===
        mainItemId
    );
    if (item) {
      return item.mainItemPaging;
    }
    return null;
  }

  private _requestPostResourceTreeMenuPaginated(action: {
    parentId: number;
    request: IInventoryRequestPagination;
  }): Observable<IOrgaResponse<ResourceDto[]> | any> {
    return this._techResourceApiService
      .postResourceByParentIdPaginated(
        action.parentId,
        action.request.pagination,
        action.request.filters
      )
      .pipe(
        withLatestFrom(
          this.store.pipe(
            select(
              TechInventorySelectors.selectTechInventoryPageMenuTreeMainListItems
            )
          )
        ),
        mergeMap(([data, mainItemList]) => {
          const formattedMainListItems = this._formatMainTreeDataWithResources(
            mainItemList,
            action.parentId,
            data
          );
          return action.request.isRefresh
            ? [
              TechInventoryActions.requestMenuTreeResourcePaginatedRefresh({
                mainItemId: action.parentId,
                data: formattedMainListItems!,
              }),
            ]
            : [
              TechInventoryActions.requestMenuTreeResourcePaginatedSuccess({
                mainItemId: action.parentId,
                data: formattedMainListItems!,
              }),
            ];
        }),
        catchError((error) => [
          TechInventoryActions.requestMenuTreeResourcePaginatedFail({
            mainItemId: action.parentId,
            error,
          }),
        ])
      );
  }

  private _formatMainTreeDataWithResources(
    mainItemList: ITechInventoryPageMenuTreeItemListMainItem<
      StereotypeListDto | LocationListDto | ResourceModelListDto
    >[],
    mainItemId: number,
    data: IOrgaResponse<ResourceDto[]>
  ):
    | ITechInventoryPageMenuTreeItemListMainItem<
    StereotypeListDto | LocationListDto | ResourceModelListDto
  >
    | undefined {
    const foundItem = mainItemList.find(
      (item) =>
        this._techInventoryHelperService.getMainItemId(item.mainItem) ===
        mainItemId
    );
    if (foundItem) {
      return {
        ...foundItem,
        mainItem: foundItem.mainItem,
        mainItemPaging: data.paging,
        resourceItems: [...data.items],
        isMainItemLoading: false,
      };
    }

    return undefined;
  }
}
