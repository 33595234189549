import {createFeatureSelector, createSelector} from "@ngrx/store";
import {
  TechInventoryPageMenuTreeItemList,
  TechInventoryState,
  TechInventoryStatePage,
  TechInventoryStatePageMenuTree,
  TechInventoryStatePageMission
} from "@tech/pages/inventory/store/tech-invetory.state";
import {LocationListDto, MissionDto, ResourceModelListDto, StereotypeListDto} from "@server-models";

export const selectTechInventoryStore = createFeatureSelector<TechInventoryState>('techInventory');

export const selectTechInventoryPage = createSelector(
  selectTechInventoryStore,
  (state: TechInventoryState) => state.page
);

// mission
export const selectTechInventoryPageMission = createSelector(
  selectTechInventoryPage,
  (state: TechInventoryStatePage) => state.mission
);

export const selectTechInventoryPageMissionList = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.items
);

export const selectTechInventoryPageMissionStereotypes = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.stereotypes
);

export const selectTechInventoryPageMissionSelectedStereotype = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.selectedStereotype
);

// detail
export const selectTechInventoryPageMissionDetail = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.detail
);

export const selectTechInventoryPageMissionDetailMissionId = createSelector(
  selectTechInventoryPageMissionDetail,
  (state: MissionDto) => state.missionId
);

export const selectTechInventoryPageMissionDetailReportId = createSelector(
  selectTechInventoryPageMissionDetail,
  (state: MissionDto) => state.reportId
);

export const selectTechInventoryPageMissionCustomPropertySetFormattedList = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.customPropertySetFormattedList
);

export const selectTechInventoryPageMissionPagination = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.paging
);

export const selectTechInventoryPageMissionChangeStateCurrent = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.changeState.current
);

export const selectTechInventoryPageMissionChangeStateLoading = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.changeState.loading
);

export const selectTechInventoryPageMissionLoading = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.isLoading
);

export const selectTechInventoryPageMissionLoadingId = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.isLoadingId
);

// report
export const selectTechInventoryPageMissionReport = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report
);

export const selectTechInventoryPageMissionReportData = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report.data
);

export const selectTechInventoryPageMissionReportPreviewCost = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report.previewCost
);

export const selectTechInventoryPageMissionReportNeedModify = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report.needModify
);

export const selectTechInventoryPageMissionReportIsSubmitLoading = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report.isSubmitLoading
);

export const selectTechInventoryPageMissionReportArticlesToAdd = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report.articlesToAdd
);

export const selectTechInventoryPageMissionReportArticlesToAddData = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report.articlesToAdd.data
);

export const selectTechInventoryPageMissionReportArticlesToAddIsLoading = createSelector(
  selectTechInventoryPageMission,
  (state: TechInventoryStatePageMission) => state.report.articlesToAdd.isLoading
);

// treeMenu
export const selectTechInventoryPageMenuTree = createSelector(
  selectTechInventoryPage,
  (state: TechInventoryStatePage) => state.menuTree
);

// treeList
export const selectTechInventoryPageMenuTreeMainList = createSelector(
  selectTechInventoryPageMenuTree,
  (state: TechInventoryStatePageMenuTree) => state.list
);

export const selectTechInventoryPageMenuTreeMainListItems = createSelector(
  selectTechInventoryPageMenuTreeMainList,
  (state: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto>) => state.items
);

export const selectTechInventoryPageMenuTreeMainListItemsStereotype = createSelector(
  selectTechInventoryPageMenuTreeMainList,
  (state: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto>) => state.items as StereotypeListDto
);

export const selectTechInventoryPageMenuTreeMainListItemsLocation = createSelector(
  selectTechInventoryPageMenuTreeMainList,
  (state: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto>) => state.items as LocationListDto
);

export const selectTechInventoryPageMenuTreeMainListItemsModel = createSelector(
  selectTechInventoryPageMenuTreeMainList,
  (state: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto>) => state.items as ResourceModelListDto
);

export const selectTechInventoryPageMenuTreeMainListSelected = createSelector(
  selectTechInventoryPageMenuTreeMainList,
  (state: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto>) => state.selectedMainItem
);

export const selectTechInventoryPageMenuTreeMainListPaging = createSelector(
  selectTechInventoryPageMenuTreeMainList,
  (state: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto>) => state.paging
);

export const selectTechInventoryPageMenuTreeMainListLoading = createSelector(
  selectTechInventoryPageMenuTreeMainList,
  (state: TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto>) => state.isLoading
);
