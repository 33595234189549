import {mapToCanActivate, Route, RouterStateSnapshot} from "@angular/router";
import {
  TechKnowledgeDetailStartComponent
} from "@tech/pages/knowledge/components/knowledge-detail-start/tech-knowledge-detail-start.component";
import {techKnowledgeDetailResolver} from "@tech/pages/knowledge/store/tech-knowledge-detail.resolver";
import {inject} from "@angular/core";
import {EAppType} from "@shared/models/AppType.enum";
import {TechResetPasswordPage} from "@tech/pages/reset-password/tech-reset-password.page";
import {SharedIssueReadOnlyPage} from "@shared/components/issues/pages/read-only/shared-issue-read-only.page";
import {
  SharedIssueNewTemplateDetailPage
} from "@shared/components/issues/pages/new-template-detail/shared-issue-new-template-detail.page";
import {
  SharedIssueSuccessPage
} from "@shared/components/issues/pages/success/shared-issue-success.page";
import {TechKnowledgeArticlePage} from "@tech/pages/knowledge/components/articles/tech-knowledge-article.page";
import {techArticlePageResolver} from "@tech/pages/knowledge/store/tech-article-page.resolver";
import {
  SharedIssueNewTemplateListPage
} from "@shared/components/issues/pages/new-template-list/shared-issue-new-template-list.page";
import {TechMainPage} from "@tech/pages/tech-main.page";
import {TechAppLoginGuard} from "@tech/guards/tech-app-login.guard";
import {techLoginChangePasswordResolver} from "@tech/pages/login/store/tech-login-change-password.resolver";
import {TechLoginChangePasswordGuard} from "@tech/guards/tech-login-change-password.guard";
import {TechChangePasswordPage} from "@tech/pages/change-password/tech-change-password.page";
import {TechLoginPage} from "@tech/pages/login/tech-login.page";
import {TechIssuePage} from "@tech/pages/issues/tech-issue.page";
import {TechIssueReadGuard} from "@tech/guards/tech-issue-read.guards";
import {TechLoginPageGuard} from "@tech/guards/tech-login-page.guard";
import {TechInfoPage} from "@tech/pages/infos/tech-info.page";
import {TechKnowledgePage} from "@tech/pages/knowledge/tech-knowledge.page";
import {TechInventoryPage} from "@tech/pages/inventory/tech-inventory.page";
import {
  TechInventoryMissionDetailComponent
} from "@tech/pages/inventory/components/mission/components/detail/tech-inventory-mission-detail.component";
import {
  TechInventoryMissionDetailIssueDetailPage
} from "@tech/pages/inventory/components/mission/components/detail/pages/issue-detail/tech-inventory-mission-detail-issue-detail.page";
import {
  TechInventoryMissionDetailContainerPage
} from "@tech/pages/inventory/components/mission/components/detail/pages/container/tech-inventory-mission-detail-container.page";
import {
  TechInventoryMissionDetailReportPage
} from "@tech/pages/inventory/components/mission/components/detail/pages/report/tech-inventory-mission-detail-report.page";
import {
  TechInventoryMissionDetailReportSuccessPage
} from "@tech/pages/inventory/components/mission/components/detail/pages/report/pages/success/tech-inventory-mission-detail-report-success.page";
import {SharedDocumentPersonalPage} from "@shared/components/documents/pages/personal/shared-document-personal.page";
import {SharedDocumentPage} from "@shared/components/documents/shared-document.page";
import {techMenuResolver} from "@tech/resolvers/tech-menu.resolver";
import {techCanLeavePageGuard} from "@tech/guards/tech-can-leave-page.guard";

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tech',
  },
  {
    path: 'tech',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMath: 'full'
      },
      {
        path: 'login',
        component: TechLoginPage,
        canActivate: mapToCanActivate([TechLoginPageGuard])
      },
      {
        path: 'login/reset-password',
        component: TechResetPasswordPage
      },
      {
        path: 'login/change-password',
        component: TechChangePasswordPage,
        resolve: { loginData: techLoginChangePasswordResolver },
        canActivate: mapToCanActivate([TechLoginChangePasswordGuard])
      },
      {
        path: 'logged',
        component: TechMainPage,
        canActivate: [
          (_: any, state: RouterStateSnapshot) => inject(TechAppLoginGuard).canActivate((EAppType.Tech), state)
        ],
        children: [
          {
            path: '',
            redirectTo: 'infos',
          },
          {
            path: 'infos',
            component: TechInfoPage,
          },
          {
            path: 'inventory',
            component: TechInventoryPage,
          },
          {
            path: 'inventory/mission/:id',
            component: TechInventoryMissionDetailComponent,
            children: [
              {
                path: '',
                redirectTo: 'view',
                pathMatch: 'full',
              },
              {
                path: 'view',
                component: TechInventoryMissionDetailContainerPage
              },
              {
                path: 'report',
                component: TechInventoryMissionDetailReportPage,
                canDeactivate: [techCanLeavePageGuard]
              },
              {
                path: 'report/success',
                component: TechInventoryMissionDetailReportSuccessPage
              },
              {
                path: 'issue',
                component: TechInventoryMissionDetailIssueDetailPage
              },

            ]
          },
          {
            path: 'knowledge',
            component: TechKnowledgePage,
          },
          {
            path: 'knowledge/:id',
            component: TechKnowledgeArticlePage,
            resolve: { article: techArticlePageResolver }
          },
          {
            path: 'knowledge/:id/start',
            component: TechKnowledgeDetailStartComponent,
            resolve: { detail: techKnowledgeDetailResolver },
          },
          {
            path: 'documents',
            component: SharedDocumentPage,
            resolve: { data: techMenuResolver },
          },
          {
            path: 'documents/me',
            component: SharedDocumentPersonalPage,
            resolve: { data: techMenuResolver },
          },
          {
            path: 'documents/:path',
            component: SharedDocumentPage,
            resolve: { data: techMenuResolver },
            pathMatch: 'full',
          },
          {
            path: 'issues',
            component: TechIssuePage,
          },
          {
            path: 'issues/new',
            component: SharedIssueNewTemplateListPage
          },
          {
            path: 'issues/new/:id',
            component: SharedIssueNewTemplateDetailPage,
            pathMatch: 'full',
          },
          {
            path: 'issues/success',
            component: SharedIssueSuccessPage,
            pathMatch: 'full'
          },
          {
            path: 'issues/:id',
            component: SharedIssueReadOnlyPage,
            canActivate: mapToCanActivate([TechIssueReadGuard]),
            pathMatch: 'full',
          },
        ]
      },

    ],
  },
] as Route[];

