import {createAction, props} from "@ngrx/store";
import {DocumentsRequestPagination} from "@tech/pages/documents/interfaces/documents-request-pagination.interface";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {OrgaFolderItemDto} from "@server-models";

export const getItemsPaginated = createAction(
  '[Documents-Tech] Get Paginated',
  props<DocumentsRequestPagination>()
);

export const getItemsPaginatedCancel = createAction(
  '[Documents-Tech] Get Paginated Cancel'
);

export const getItemsPaginatedRefresh = createAction(
  '[Documents-Tech] Get Paginated Refresh',
  props<{ data: OrgaResponse<OrgaFolderItemDto[]> }>()
);

export const getItemsPaginatedSuccess = createAction(
  '[Documents-Tech] Get Paginated Success',
  props<{ data: OrgaResponse<OrgaFolderItemDto[]> }>()
);
export const getItemsPaginatedFail = createAction(
  '[Documents-Tech] Get Paginated Fail',
  props<{ error: any }>()
);

export const getItemsSearched = createAction(
  '[Documents-Tech] Get Searched',
  props<{toSearch: string, path: string}>()
);

export const getItemsSearchedSuccess = createAction(
  '[Documents-Tech] Get Searched Success',
  props<{ data: OrgaResponse<OrgaFolderItemDto[]> }>()
);

export const getItemsSearchedFail = createAction(
  '[Documents-Tech] Get Searched Fail',
  props<{ error: any }>()
);
