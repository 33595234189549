import {Component, Input, OnInit} from '@angular/core';
import {MissionState} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-mission-state-actions',
  templateUrl: './mission-state-actions.component.html',
  styleUrls: ['./mission-state-actions.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule ],

})
export class MissionStateActionsComponent implements OnInit {

  @Input() stateOption: MissionState;
  protected readonly MissionState = MissionState;

  constructor(
  ) {
    this.stateOption = NaN;
  }

  ngOnInit() {
  }

}
