<ion-card class="settings">
  <ion-grid>
    <ion-row>
      <ion-col size="auto">
        <ion-buttons>
          <ion-button expand="full" (click)="toggleDisplayType()">
            <ion-icon class="is-selected" size="small" slot="icon-only"
                      [name]="displayType === EMissionDisplayMode.LIST ? 'map' : 'list'"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
      <ion-col class="middle-col">
        <div class="scrollable-content">
          <ion-select
            [placeholder]="'COMPONENTS.MISSIONS.RESOURCE.PLACEHOLDER' | translate"
            interface="popover"
            [(ngModel)]="currentSelectedObject.stereotype"
            (ionChange)="changeStereotypeMode($event)"
            [ngClass]="{'is-selected': isStereotypeSelected}">
            <ion-select-option *ngIf="stereotypes.length > 0" [value]="''">
              {{ 'COMPONENTS.MISSIONS.RESOURCE.ALL' | translate }}
            </ion-select-option>
            <ion-select-option *ngFor="let stereotype of stereotypes" [value]="stereotype.stereotypeId">
              {{ stereotype.name }}
            </ion-select-option>
          </ion-select>
          <ion-select
            [placeholder]="'COMPONENTS.MISSIONS.STATE.PLACEHOLDER' | translate"
            interface="popover"
            [(ngModel)]="currentSelectedObject.state"
            (ionChange)="changeStatusMode($event)"
            [ngClass]="{'is-selected': currentSelectedObject.state !== null}">
            <ion-select-option [value]="''">
              {{ 'COMPONENTS.MISSIONS.STATE.ALL' | translate }}
            </ion-select-option>
            <ion-select-option [value]="MissionState.Instructed">
              {{ 'COMPONENTS.MISSIONS.STATE.INSTRUCTED' | translate }}
            </ion-select-option>
            <ion-select-option [value]="MissionState.Created">
              {{ 'COMPONENTS.MISSIONS.STATE.CREATED' | translate }}
            </ion-select-option>
            <ion-select-option [value]="MissionState.Done">
              {{ 'COMPONENTS.MISSIONS.STATE.DONE' | translate }}
            </ion-select-option>
          </ion-select>
          <ion-select
            *ngIf="currentSelectedObject.list == EMissionDisplayMode.MAP"
            [placeholder]="'COMPONENTS.MISSIONS.DISTANCE.PLACEHOLDER' | translate"
            interface="popover"
            (ionChange)="missionMapDistance($event)"
            [ngModel]="currentSelectedObject.onlyMap?.distance"
            [ngClass]="{'is-selected': currentSelectedObject.onlyMap?.distance != null}"
          >
            <ion-select-option [value]="''">
              {{ 'COMPONENTS.MISSIONS.DISTANCE.OPTIONS.DEFAULT' | translate }}
            </ion-select-option>
            <ion-select-option [value]="10">
              {{ '10km' | translate }}
            </ion-select-option>
            <ion-select-option [value]="25">
              {{ '25km' | translate }}
            </ion-select-option>
            <ion-select-option [value]="50">
              {{ '50km' | translate }}
            </ion-select-option>
            <ion-select-option [value]="100">
              {{ '100km' | translate }}
            </ion-select-option>
            <ion-select-option [value]="250">
              {{ '250km' | translate }}
            </ion-select-option>
            <ion-select-option [value]="500">
              {{ '500km' | translate }}
            </ion-select-option>
          </ion-select>
        </div>
      </ion-col>
      <ion-col size="auto">
        <ion-button class="reduce-padding" fill="clear" expand="full" (click)="showFilterModal()">
          <ion-icon class="is-selected" size="small" slot="icon-only"
                    [name]="'options'"></ion-icon>
        </ion-button>
        <ion-button class="reduce-padding" fill="clear" expand="full" (click)="clearSettingsFilters()">
          <ion-icon size="small" slot="icon-only"
                    [name]="'close'"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
