<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.EDITOR' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="onCloseModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card *ngIf="model.solutionContact">
    <ion-card-content>
      <ion-list lines="none">
        <ion-item class="ion-no-padding">
          <ion-icon slot="start" name="person-circle-outline" color="primary" class="user"/>
          <ion-label>
            <h2>{{ model.solutionContact.displayName }}</h2>
            <p *ngIf="model.solutionContact.email" (click)="onSendMail()">
              <ion-text color="primary">{{ model.solutionContact.email }}</ion-text>
            </p>
            <p *ngIf="model.solutionContact.phone" (click)="onStartPhoneCall()">
              <ion-text color="primary">{{ model.solutionContact.phone }}</ion-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
  <div class="ion-text-center">
    <ion-spinner *ngIf="isLoadingOnAssignMe"></ion-spinner>
  </div>
  <ion-card *ngIf="!isAssignOther">
    <ion-card-content>
      <div class="action-button-container">
        <ion-button color="medium" [disabled]="!model.solutionContact || isLoadingOnAssignMe"
                    (click)="onUnassignEditor()">
          <ion-icon slot="start" name="close-circle"></ion-icon>
          {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.UNASSIGN' | translate }}
        </ion-button>
        <ion-button [disabled]="isLoadingOnAssignMe" (click)="onAssignMe()">
          <ion-icon slot="start" name="person-circle"></ion-icon>
          {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.ASSIGN_ME' | translate }}
        </ion-button>
        <ion-button [disabled]="isLoadingOnAssignMe" (click)="toggleIsAssign()">
          <ion-icon slot="start" name="people-circle"></ion-icon>
          {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.ASSIGN_OTHER' | translate }}
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
  <ion-card *ngIf="isAssignOther">
    <ion-card-content>
      <div class="ion-margin-bottom">
        <app-shared-input-entity-select-item
          [label]="'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.SELECT_EDITOR' | translate"
          [required]="true"
          [config]="contactSelectConfig"
          [value]="undefined"
          (valueChange)="contactToAssign = $event">
        </app-shared-input-entity-select-item>
      </div>
      <div class="action-button-container">
        <ion-button color="medium" (click)="toggleIsAssign()">
          <ion-icon slot="start" name="close-circle"></ion-icon>
          {{ 'GENERAL.ALERT.BUTTONS.CANCEL' | translate }}
        </ion-button>
        <ion-button [disabled]="contactToAssign === undefined" (click)="onAssignEditor()">
          <ion-icon slot="start" name="people-circle"></ion-icon>
          {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.ASSIGN' | translate }}
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
