import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ResourceListDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {MissionFilterService} from "@tech/pages/inventory/services/mission-filter.service";
import {TreeMenuSegmentEnum} from "@tech/pages/inventory/enums/tree-menu-segment.enum";

@Component({
  selector: 'app-tech-child-tree-resources',
  templateUrl: './tech-child-tree-resources.component.html',
  styleUrls: ['./tech-child-tree-resources.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],

})
export class TechChildTreeResourcesComponent implements OnInit, OnChanges {

  @Input() resourceListDto: ResourceListDto[];
  @Input() paging: Pagination;
  @Input() segmentType: TreeMenuSegmentEnum;
  @Output() onNeedMore: EventEmitter<any>;


  selectedResource: any;

  constructor(
    private _store: Store,
    private _missionFilterService: MissionFilterService
  ) {
    this.resourceListDto = <ResourceListDto[]>{};
    this.onNeedMore = new EventEmitter<any>();
    this.paging = <Pagination>{};
    this.segmentType = TreeMenuSegmentEnum.Type;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  needMore() {
    let mainItemId;
    switch (this.segmentType) {
      case TreeMenuSegmentEnum.Type: {
        mainItemId = this.resourceListDto[0].stereotypeId;
        break;
      }
      case TreeMenuSegmentEnum.Location: {
        mainItemId = this.resourceListDto[0].locationId;
        break;
      }
      case TreeMenuSegmentEnum.Model: {
        mainItemId = this.resourceListDto[0].model?.resourceModelId;
        break;
      }
    }
    this.onNeedMore.emit({parentId: mainItemId, paging: this.paging});
  }

  applyResourceFilter(resource: ResourceListDto) {
    this.selectedResource = this.selectedResource === resource ? null : resource;
    if (this.selectedResource) {
      this._missionFilterService.filterSettings$.next({
        ...this._missionFilterService.filterSettings$.getValue(),
        resource: resource,
        stereotypeId: undefined
      });
    } else {
      this._missionFilterService.filterSettings$.next({
        ...this._missionFilterService.filterSettings$.getValue(),
        resource: undefined
      });
    }
  }
}
