import {TechEnvironment} from "./environment.interface";

export const environment: TechEnvironment = {
  production: true,
  apiUrl: {
    one: "https://one-dev.api.semco.app",
    pageDefaultSize: 25,
    defaultTimeoutSec: 30,
    tokenExpireOffsetSec: 120
  },
  lang: {
    defaultLanguage: "en-US",
    useBrowserLanguage: false
  },
};
