<ion-spinner *ngIf="((reportIsLoading$ | async) || (reportStereotypesTypesIsLoading$ | async)) else main"></ion-spinner>
<ng-template #main>
  <ion-content *ngIf="(currentReportId || currentIsModificationNeeded) else addReportButton" class="main">

    <!-- SERVICE TIMES ARE PERMANENT ITEMS ON EVERY REPORT -->
    <ion-text>
      <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.HEADER' | translate }}</h1>
    </ion-text>
    <app-tech-inventory-mission-detail-report-article
      [name]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.WORK.LABEL' | translate"
      [amountLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.WORK.AMOUNT' | translate"
      [amountUnitLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.WORK.UNIT' | translate"
      [disableForm]="setFormDisable"
      [amount]="workDurationInHours"
      [unitPrice]="setWorkCost(workDurationCost)"
      [isRemovable]="false"
      (articleChanged)="onWorkingCostsChanged($event)">
    </app-tech-inventory-mission-detail-report-article>
    <app-tech-inventory-mission-detail-report-article
      [name]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.TRAVEL.LABEL' | translate"
      [amountLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.TRAVEL.AMOUNT' | translate"
      [amountUnitLabel]="'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.SERVICE.TRAVEL.UNIT' | translate"
      [disableForm]="setFormDisable"
      [amount]="travelDurationInHours"
      [unitPrice]="setTravelCost(travelDurationCost)"
      [isRemovable]="false"
      (articleChanged)="onTravelCostsChanged($event)">
    </app-tech-inventory-mission-detail-report-article>


    <!-- MATERIAL COSTS ARE OPTIONAL AND GET BUILD BY + FAB BUTTON MENU -->
    <ion-text *ngIf="usedArticles.length > 0">
      <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.MATERIAL.HEADER' | translate }}</h1>
    </ion-text>
    <app-tech-inventory-mission-detail-report-article
      *ngFor="let article of usedArticles"
      [article]="article"
      [disableForm]="setFormDisable"
      [isAbleToDisplayNotes]="true"
      [note]="article.note"
      (articleChanged)="onArticleChanged($event)"
      (remove)="onRemoveArticle($event)">
    </app-tech-inventory-mission-detail-report-article>

    <div class="total-costs">
      <ion-text>
        <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.COSTS.TOTAL' | translate }}</h1>
      </ion-text>
      <ion-text><h1>{{ totalCosts | currency:'EUR':true:'1.2':currentLang }}</h1></ion-text>
    </div>

    <hr class="ion-margin-vertical"/>

    <!-- REPORT TYPE SELECT -->
    <ng-container *ngIf="(stereotypeTypes$ | async)!.data as typeOptions">
      <ion-text>
        <h1>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.TYPE.HEADER' | translate }}</h1>
      </ion-text>
      <ion-card>
        <ion-card-content class="report-custom-values">
          <ion-item class="article" lines="none">
            <ion-select
              label="Type"
              label-placement="stacked"
              interface="popover"
              (ionChange)="reportTypeSelected($event)"
              [value]="reportTypeDefaultSelected(typeOptions)"
            >
              <ion-select-option *ngFor="let typeOption of typeOptions" [value]="typeOption">
                {{ typeOption.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ng-container>

    <!-- CUSTOM VALUE PROPERTIES START HERE -->
    <ng-container *ngIf="reportType">
      <ion-text>
        <h1>{{ reportType.headerTitle }}</h1>
      </ion-text>
      <form *ngIf="!setFormDisable" [formGroup]="parentForm">
        <app-shared-custom-form-property
          (inputChange)="formInnerModified($event)"
          [hasNoMargin]="true"
          [customPropertySets]="selectedReportStereotypeType?.customPropertySets!"
          [customFilledValues]="incValues">

        </app-shared-custom-form-property>
      </form>
    </ng-container>

    <br>

    <ion-fab #articleMenu slot="fixed" vertical="bottom" horizontal="end">
      <ion-fab-button (click)="openArticlePickerModal()">
        <ion-icon name="add-outline" *ngIf="(articlesToAddIsLoading$ | async) === false"></ion-icon>
        <ion-spinner *ngIf="(articlesToAddIsLoading$ | async) === true"></ion-spinner>
      </ion-fab-button>
    </ion-fab>

  </ion-content>
  <ng-template #addReportButton>
    <div class="ion-text-center">
      <div class="ion-margin-top">
        <ion-text color="medium">
          <p>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.NO_REPORT.INFO' | translate }}</p>
          <p>{{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.NO_REPORT.DESCRIPTION' | translate }}</p>
        </ion-text>
      </div>
      <ion-button class="ion-margin-top" fill="outline" (click)="requestPreview()">
        <ion-icon slot="start" name="add"></ion-icon>
        {{ 'COMPONENTS.MISSIONS.REPORT.ACTIONS.NO_REPORT.BUTTON' | translate }}
      </ion-button>
    </div>
  </ng-template>
</ng-template>



