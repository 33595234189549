<ion-card class="card-container">
  <ion-list>
    <ng-container *ngFor="let knowledge of knowledgeList as knowledgeList">
      <ng-container *ngIf="isKnowledgePathListDto(knowledge)">
        <ion-item button="button" detail="detail"
                  [routerLink]="knowledge.knowledgePathId + '/start'" routerDirection="forward">
          <ion-label>{{ knowledge.title }}</ion-label>
          <ng-template *ngIf="knowledgeList.length > 0; else noValues">
            <ion-label>{{ knowledge.title }}</ion-label>
          </ng-template>
        </ion-item>
      </ng-container>
      <ng-container *ngIf="isKnowledgeArticleListDto(knowledge)">
        <ion-item button="button" detail="detail"
                  [routerLink]="knowledge.knowledgeArticleId?.toString()" routerDirection="forward">
          <ion-label>{{ knowledge.title }}</ion-label>
          <ng-template *ngIf="knowledgeList.length > 0; else noValues">
            <ion-label>{{ knowledge.title }}</ion-label>
          </ng-template>
        </ion-item>
      </ng-container>
    </ng-container>
    <ng-template #noValues>
      <ion-label class="ion-text-center">{{ 'TECH.KNOWLEDGE.PATH.EMPTY' | translate }}</ion-label>
    </ng-template>
  </ion-list>
</ion-card>
