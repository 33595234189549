<ng-container *ngIf="resourceListDto.length > 0; else noResources">
  <ion-list class="resource-list">
    <ion-item [ngClass]="{'is-selected': selectedResource === resource}" *ngFor="let resource of resourceListDto"
              (click)="applyResourceFilter(resource)">
      <div class="resource-item-title" [style.margin-left]="(1 * 2) + 'rem'">
        <div *ngIf="resource?.inventoryNumber" class="resource-inventory-number">
            {{ resource.inventoryNumber }}
        </div>
        <div class="resource-name">
            {{ resource.name }}
        </div>
      </div>
      <ion-chip *ngIf="resource.locationId" slot="end">
        <ion-icon name="location"></ion-icon>
        <ion-text>{{ resource.locationId }}</ion-text>
      </ion-chip>
    </ion-item>
    <ion-item *ngIf="paging.pageNumber < paging.totalPages" class="resource-list load-more" (click)="needMore()">
      <ion-icon name="add-outline" [style.margin-left]="(1 * 2) + 'rem'"></ion-icon>
      <ion-text>{{ 'COMPONENTS.MISSIONS.TREE_MENU.LOAD_MORE' | translate }}</ion-text>
    </ion-item>
  </ion-list>
</ng-container>
<ng-template #noResources>
  <ion-list class="resource-list">
    <ion-item>
      <ion-text class="ion-text-center" [style.margin-left]="(1 * 2) + 'rem'"
                [innerText]="'COMPONENTS.MISSIONS.TREE_MENU.EMPTY_RESOURCE' | translate"></ion-text>
    </ion-item>
  </ion-list>
</ng-template>
