<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <app-shared-button-side-menu [menuId]="'tech-menu'" [title]="'TECH.KNOWLEDGE.PAGE_NAME' | translate">
      <ion-button (click)="changeDisplayBar()">
        <ion-icon name="search"></ion-icon>
      </ion-button>
    </app-shared-button-side-menu>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content pulling-icon="chevron-down"></ion-refresher-content>
  </ion-refresher>
  <app-shared-base-search-bar *ngIf="isDisplaySearchBar" class="item-searchbar"
                              (valueToSearch)="onSearch($event)"></app-shared-base-search-bar>
  <ion-spinner *ngIf="!isDragRefresh && (knowledgePathIsLoading$ | async) && !isInfinityLoading"></ion-spinner>
  <ng-container *ngIf="!(!isDragRefresh && (knowledgePathIsLoading$ | async) && !isInfinityLoading)">
    <ng-container *ngIf="(knowledgePathData$ | async)?.length! > 0; else noKnowledge">
      <app-tech-knowledge-card [knowledgeList]="knowledgePathData$ | async"></app-tech-knowledge-card>
    </ng-container>
  </ng-container>
  <ion-infinite-scroll
    (ionInfinite)="loadMoreKnowledgePath($event)">
    <ion-infinite-scroll-content>
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ng-template #noKnowledge>
  <ion-card class="card-container">
    <ion-item>
      <ion-label class="ion-text-center" [innerText]="'TECH.KNOWLEDGE.EMPTY' | translate"></ion-label>
    </ion-item>
  </ion-card>
</ng-template>
