import {enableProdMode, importProvidersFrom} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import {RouteReuseStrategy, provideRouter} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {environment} from '@env-tech/environment';
import {provideStore} from "@ngrx/store";
import {provideEffects} from "@ngrx/effects";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import {IonicStorageModule} from "@ionic/storage-angular";
import {Drivers} from '@ionic/storage';
import {appStore} from "@core/store/app.reducer";
import {HttpClientModule, HttpClient} from "@angular/common/http";
import {DefaultDataServiceConfig, EntityDataModule, EntityMetadataMap, provideEntityData} from "@ngrx/data";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import * as routes from "@tech/routes";
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {TechAppComponent} from "@tech/tech-app.component";
import {techLoginStore} from "@tech/pages/login/store/tech-login.reducer";
import {sideBarStore} from "@tech/components/side-bar/store/side-bar.reducers";
import {techIssuesStore} from "@tech/pages/issues/store/tech-issues.reducers";
import {attachmentsStore} from "@shared/components/attachments/store/attachments.reducers";
import {SideBarApiEffects} from "@tech/components/side-bar/store/side-bar-api.effects";
import {AttachmentsApiEffects} from "@shared/components/attachments/store/attachments-api.effects";
import {TechIssuesApiEffects} from "@tech/pages/issues/store/tech-issues-api-effects.service";
import {TechKnowledgeApiEffects} from "@tech/pages/knowledge/store/knowledge-api.effects";
import {TechDocumentsApiEffects} from "@tech/pages/documents/store/documents-api.effects";
import {TechInfosApiEffects} from "@tech/pages/infos/store/infos-api.effects";
import {TechLoginEffects} from "@tech/pages/login/store/tech-login-effects.service";
import {techInfosStore} from "@tech/pages/infos/store/infos.reducers";
import {techKnowledgeStore} from "@tech/pages/knowledge/store/knowledge.reducers";
import {techDocumentsStore} from "@tech/pages/documents/store/documents.reducers";
import {techInventoryStore} from "@tech/pages/inventory/store/tech-iventory.reducers";
import {TechInventoryApiEffects} from "@tech/pages/inventory/store/tech-inventory-api.effects";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';

// currencyPipe it is only include support to en-US
// so we need to register the local de-DE to Angular to allow currencyPipe work also with it
registerLocaleData(localeDe);

if (environment.production) {
  enableProdMode();
}

defineCustomElements(window);

const entityMetadataMap: EntityMetadataMap = {
  Chat: {
    selectId: chat => chat.chatByFormDefinitionId
  }
};

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.apiUrl.one
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(TechAppComponent, {
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
    provideStore({
      app: appStore,
      login: techLoginStore,
      techInfos: techInfosStore,
      techInventory: techInventoryStore,
      techKnowledge: techKnowledgeStore,
      techDocuments: techDocumentsStore,
      issues: techIssuesStore,
      attachments: attachmentsStore,
      sideBar: sideBarStore
    }),
    provideEffects([
      TechLoginEffects,
      TechInfosApiEffects,
      TechInventoryApiEffects,
      TechDocumentsApiEffects,
      TechKnowledgeApiEffects,
      TechIssuesApiEffects,
      AttachmentsApiEffects,
      SideBarApiEffects
    ]),
    provideStoreDevtools({
      name: 'semco ONE'
    }),
    provideRouter(routes.default),
    provideEntityData({entityMetadata: entityMetadataMap}),
    importProvidersFrom(
      IonicModule.forRoot({
        spinner: 'dots',
        swipeBackEnabled: false,
      }),
      IonicStorageModule.forRoot({
        name: 'one',
        driverOrder: [
          CordovaSQLiteDriver._driver,
          Drivers.IndexedDB,
          Drivers.LocalStorage
        ]
      }),
      HttpClientModule,
      BrowserAnimationsModule,
      EntityDataModule.forRoot({}),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      })
    ),
  ]
});
