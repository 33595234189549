import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {AssignedUserEnum} from "@tech/pages/inventory/enums/segment-settings.emun";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryMissionPagesService {

  private _selectedSegment: AssignedUserEnum;

  constructor(
    private _router: Router
  ) {
    this._selectedSegment = AssignedUserEnum.Mine;
  }

  set selectedSegment(userSelection: AssignedUserEnum) {
    this._selectedSegment = userSelection;
  }

  get selectedSegment() {
    return this._selectedSegment;
  }

  resetSegment() {
    this.selectedSegment = AssignedUserEnum.Mine;
  }

  changeSegmentSetting(currentSegment: any) {
    const urlSegments = this._router.url.split('/');
    switch (currentSegment) {
      case AssignedUserEnum.Mine:
        urlSegments[urlSegments.length - 1] = 'view';
        this.selectedSegment = AssignedUserEnum.Mine;
        this._router.navigate(urlSegments)
        break;
      case AssignedUserEnum.Unassigned:
        urlSegments[urlSegments.length - 1] = 'report';
        this.selectedSegment = AssignedUserEnum.Unassigned;
        this._router.navigate(urlSegments)
        break;
      default: {
        urlSegments[urlSegments.length] = 'view';
        this.selectedSegment = AssignedUserEnum.Mine;
        this._router.navigate(urlSegments)
      }
    }
  }

  checkSegmentByString(segment: string): AssignedUserEnum | undefined {
    switch (segment) {
      case 'view':
        return AssignedUserEnum.Mine
      case 'report':
        return AssignedUserEnum.Unassigned
      default:
        return undefined
    }
  }
}
