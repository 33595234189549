import {Component, Input} from '@angular/core';
import {ContactSimpleDto, MissionDto} from '@server-models';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {SharedUserHtmlComponent} from '@shared/components/user-html/shared-user-html.component';
import {TechInventoryService} from '@tech/pages/inventory/services/tech-inventory.service';
import {
  TechInventoryMissionTypeOptionInputComponent
} from '@tech/pages/inventory/components/mission/components/type-option-input/tech-inventory-mission-type-option-input.component';
import {
  TechInventoryMissionStateActionsComponent
} from '@tech/pages/inventory/components/mission/components/state-actions/tech-inventory-mission-state-actions.component';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {
  TechInventoryMissionDetailContainerShipAddressModalComponent
} from "@tech/pages/inventory/components/mission/components/detail/pages/container/components/ship-address-modal/tech-inventory-mission-detail-container-ship-address-modal.component";
import {
  TechInventoryMissionDetailContainerAssignEditorModalComponent
} from "@tech/pages/inventory/components/mission/components/detail/pages/container/components/assign-editor-modal/tech-inventory-mission-detail-container-assign-editor-modal.component";
import {
  SharedIssueCustomReadOnlyMissionStateOptionsComponent
} from "@shared/components/issues/pages/read-only/components/mission/components/mission-state-options/shared-issue-custom-read-only-mission-state-options.component";

@Component({
  selector: 'app-tech-inventory-mission-detail-container-resume',
  templateUrl: './tech-inventory-mission-detail-container-resume.component.html',
  styleUrls: ['./tech-inventory-mission-detail-container-resume.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedUserHtmlComponent,
    TechInventoryMissionTypeOptionInputComponent,
    SharedIssueCustomReadOnlyMissionStateOptionsComponent,
    TechInventoryMissionStateActionsComponent,
    TechInventoryMissionDetailContainerShipAddressModalComponent,
  ],
  providers: [SharedModalControllerService],
})
export class TechInventoryMissionDetailContainerResumeComponent {
  @Input() model: MissionDto;

  constructor(
    public techInventoryService: TechInventoryService,
    private _translateService: TranslateService,
    private _modalService: SharedModalControllerService
  ) {
    this.model = {};
  }

  onShowLocationModal(): void {
    this._modalService.showModal(
      TechInventoryMissionDetailContainerShipAddressModalComponent,
      '',
      { missionDetail: this.model }
    );
  }

  onShowAssignEditorModal(): void {
    this._modalService.showModal(
      TechInventoryMissionDetailContainerAssignEditorModalComponent,
      '',
      {
        model: this.model,
        assignContactFn: (contact: ContactSimpleDto) => this.onAssignEditor(contact),
      }
    );
  }

  onAssignEditor(editor: ContactSimpleDto): void {
    this.model.solutionContact = editor;
  }

  prepareLocationValue(
    name: string | null | undefined,
    number: string | null | undefined
  ) {
    return name || number
      ? `${ name ?? '' } ${ number ? '(' + number + ')' : '' }`
      : this._translateService.instant(
        'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.EMPTIES.LOCATION'
      );
  }

  prepareEditorValue(displayName: string | null | undefined) {
    return displayName
      ? displayName
      : this._translateService.instant(
        'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.EMPTIES.EDITOR'
      );
  }
}
