import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MissionState, StereotypeListDto } from '@server-models';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TechInventoryService } from '@tech/pages/inventory/services/tech-inventory.service';
import { MissionFilterService } from '@tech/pages/inventory/services/mission-filter.service';

@Component({
  selector: 'app-tech-inventory-settings',
  templateUrl: './tech-inventory-settings.component.html',
  styleUrls: ['./tech-inventory-settings.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, FormsModule],
})
export class TechInventorySettingsComponent implements OnInit {
  @Input() stereotypes: StereotypeListDto[];
  @Output() missionFilterSettingsChanged!: EventEmitter<void>;

  currentSelectedObject: {
    list?: string;
    state?: MissionState | null | '';
    stereotype?: number | null | '';
  };

  protected readonly MissionState = MissionState;

  constructor(
    private _inventoryService: TechInventoryService,
    private _missionFilterService: MissionFilterService
  ) {
    this.missionFilterSettingsChanged = new EventEmitter<any>();

    this.currentSelectedObject = {
      list: 'list',
      state: null,
      stereotype: null,
    };

    this.stereotypes = [];
  }

  ngOnInit() {
    this.currentSelectedObject = {
      state: MissionState.Instructed,
      stereotype: null,
    };
    this._inventoryService.getSettingReset().subscribe(({state, stereotype}) => {
      this.currentSelectedObject.state = state;
      this.currentSelectedObject.stereotype = stereotype;
    })
  }

  /**
   * @name changeStatusMode
   * @description
   * emit the value for bodyfilter status request
   * @memberof
   * @param ev
   */
  changeStatusMode(ev: CustomEvent) {
    const missionState = ev.detail.value;
    missionState == '' ? this.currentSelectedObject.state = null : this.currentSelectedObject.state = missionState;

    this._missionFilterService.filterSettings$.next({
      ...this._missionFilterService.filterSettings$.getValue(),
      state: missionState === '' ? undefined : missionState,
    });
  }

  /**
   * @name changeStereotypeMode
   * @description
   * emit the value bodyfilter resource request
   * @memberof TechInventorySettingsComponent
   * @param ev
   */
  changeStereotypeMode(ev: CustomEvent) {
    const stereotype = ev.detail.value;
    stereotype == '' ? this.currentSelectedObject.stereotype = null : this.currentSelectedObject.stereotype = stereotype;

    this._missionFilterService.filterSettings$.next({
      ...this._missionFilterService.filterSettings$.getValue(),
      stereotypeId: stereotype === '' ? undefined : stereotype,
    });
  }

  clearSettingsFilters() {
    this._missionFilterService.filterSettings$.next({
      ...this._missionFilterService.filterSettings$.getValue(),
      state: undefined,
      stereotypeId: undefined,
    });
    this.currentSelectedObject.stereotype = null;
    this.currentSelectedObject.state = null;
  }
}
