import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {TechSideBarComponent} from "@tech/components/side-bar/tech-side-bar.component";

@Component({
  selector: 'app-tech-main',
  templateUrl: './tech-main.page.html',
  styleUrls: ['./tech-main.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, TechSideBarComponent]
})
export class TechMainPage implements OnInit {

  isLoading$!: Observable<boolean>;
  currentLogin$!: Observable<boolean>;

  constructor(
    private _store: Store
  ) {
    this.isLoading$ = this._store.select(TechLoginSelectors.selectIsLoading);

  }

  ngOnInit() {
    this.currentLogin$ = this._store.pipe(select(TechLoginSelectors.selectCurrentLogin), map((login) => !!login));
  }
}
