import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule, ViewWillEnter} from '@ionic/angular';
import {Store} from "@ngrx/store";
import {filter, Observable} from "rxjs";
import {Params, Router} from "@angular/router";
import {ShowHidePasswordComponent} from "@features/show-hide-password/show-hide-password.component";
import {TranslateModule} from "@ngx-translate/core";
import {AppType} from "@shared/models/AppType";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";
import {LoginCardComponent} from "@tech/pages/login/components/login-card/login-card.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ShowHidePasswordComponent, TranslateModule, LoginCardComponent]
})
export class TechLoginPage implements OnInit, ViewWillEnter {

  public isLoading$: Observable<boolean> = this._store.select(TechLoginSelectors.selectIsLoading);
  public isLogged$: Observable<boolean> = this._store.select(TechLoginSelectors.selectIsLogged)
  public currentApp$: Observable<AppType> = this._store.select(TechLoginSelectors.selectCurrentApp).pipe(
    filter(x => x != null)
  );

  username?: string;
  password?: string;

  redirectRequest?: string;
  changeRegisterView: boolean;

  constructor(
    private _store: Store,
    private _router: Router,
  ) {
    this.changeRegisterView = false;
  }



  ionViewWillEnter(): void {
    this.redirectRequest = this.getRedirectRequest();
    this.isLogged$.subscribe((isLogged) => {
      if (isLogged && this.redirectRequest === undefined) {
        this._router.navigate(['tech/logged/infos'])
      }
    });
    this.currentApp$.subscribe(async (appType) => {
      if (this.redirectRequest !== undefined) {
        await this.startApp(appType);
      }
    });
    this._store.dispatch(TechLoginActions.init());
  }

  ngOnInit() {

  }

  async startApp(action: AppType) {
    const redirect = await this.findRedirectUrl(action, this.redirectRequest);
    const url = new URL(redirect ?? '', window.location.origin);
    const path = url.pathname;

    const queryParams: Params = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return this._router.navigate([path], {queryParams});
  }

  getRedirectRequest(): string | undefined {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams?.get('redirect');

    return url || undefined;
  };

  async findRedirectUrl(appType: AppType, request?: string) {
    switch (appType) {
      case AppType.Tech:
        return request?.startsWith('/tech/') ? request : '/tech/';
      default:
        return null;
    }
  }

  /**
   * @name onUserSubmit
   * @description
   * handle the submit event of the childs to dispatch the correct action
   * @memberof TechLoginPage
   * @param ev
   */
  onUserSubmit(ev: any) {
    const inputUsername = ev.input.username;
    const inputPassword = ev.input.password;

    this._store.dispatch(TechLoginActions.byPassword({login: inputUsername!, password: inputPassword || ''}));
  }

  /**
   * @name onToLogin
   * @description
   * control the emitted value to change the view
   * @memberof TechLoginPage
   */
  onToLogin(): void {
    this.changeRegisterView = !this.changeRegisterView;
  }

  /**
   * @name onToReset
   * @description
   * navigate to reset password
   * @memberof TechLoginPage
   */
  onToReset(): void {
    this._router.navigate(['tech/login/reset-password']);
  }

  onFormChange(ev: any): void {
    this.username = ev.input.username;
  }

  /**
   * @name onRegisterUser
   * @description
   * handle on register user event to dispatch init registration guest
   * @memberof TechLoginPage
   */
  onRegisterUser(buttonId: number | string): void {
    this._store.dispatch(TechLoginActions.initRegistrationGuest({isLoadingId: buttonId, currentEmail: this.username}));
  }
}
