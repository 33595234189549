import {CoreBaseEffects} from "@core/core-base.effects";
import {Observable} from "rxjs";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {TechInfosActions} from "@tech/pages/infos/store/tech-info.actions-type";
import {Injectable} from "@angular/core";
import {IInfosRequestPagination} from "@tech/pages/infos/interfaces/infos-request-pagination.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class TechInfoEffectsBase extends CoreBaseEffects {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract postPaginated(action$: Observable<IInfosRequestPagination>): Observable<any>;

  abstract postMyRead(action$: Observable<any>): Observable<any>;

  abstract postItemsSearchedPaginated(action$: Observable<{
    searchTerm: string;
    requestParam: IInfosRequestPagination
  }>): Observable<any>;


  postPaginated$ = createEffect(() => this.actions().pipe(
    ofType(TechInfosActions.postItemsPaginated),
    action => this.postPaginated(action)
  ));

  postMyRead$ = createEffect(() => this.actions().pipe(
    ofType(TechInfosActions.postItemsMyRead),
    action => this.postMyRead(action)
  ));

  postItemsSearchedPaginated$ = createEffect(() => this.actions().pipe(
    ofType(TechInfosActions.postItemsSearchedPaginated),
    action => this.postItemsSearchedPaginated(action)
  ));


}
