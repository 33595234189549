import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {KnowledgeArticleStartDto} from "@server-models";
import {select, Store} from "@ngrx/store";
import {KnowledgeState} from "@tech/pages/knowledge/store/knowledge.state";
import {inject} from "@angular/core";
import {filter, take, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {KnowledgeActions} from "@tech/pages/knowledge/store/knowledge.actions-type";
import {TechKnowledgeSelectors} from "@tech/pages/knowledge/store/knowledge.selectors-type";

export const articlesPageResolver: ResolveFn<KnowledgeArticleStartDto | null | undefined> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store: Store<KnowledgeState> = inject(Store);
    const currentId: string = route.paramMap.get('id')!;
    store.dispatch((KnowledgeActions.getArticlePage({
      articleId: parseInt(currentId)
    })));

    return store.pipe(
      select(TechKnowledgeSelectors.selectArticlePage),
      withLatestFrom(store.pipe(select(TechKnowledgeSelectors.selectKnowledgePathLoading))),
      filter(([data, loading]) => !!data && !loading),
      take(1),
      map(([data]) => data)
    );
  };
