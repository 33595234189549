import {Component, Input, OnInit} from '@angular/core';
import {MissionDto, StereotypeListDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, } from "@ngx-translate/core";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {
  TechInventoryMissionTypeOptionsComponent
} from "@tech/pages/inventory/components/mission-type-options/tech-inventory-mission-type-options.component";
import {ClockService} from "@core/services/clock.service";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-tech-inventory-mission-detail-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, UserHtmlComponent, TechInventoryMissionTypeOptionsComponent],

})
export class TechInventoryMissionDetailViewDescriptionComponent implements OnInit {
  @Input() missionDetail: MissionDto;
  selectedStereotype$: Observable<StereotypeListDto>;


  constructor(
    private _store: Store,
    public clockService: ClockService
  ) {
    this.missionDetail = {};
    this.selectedStereotype$ = of();


  }

  ngOnInit() {
    this.selectedStereotype$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionSelectedStereotype));
  }

  formatDates(start: string | undefined, end: string | null | undefined): string {
    if (!start) return '';

    const startDate = this.clockService.formatDateWithWeekday(start, false, true);
    const endDate = end ? this.clockService.formatDateWithWeekday(end, false, true) : '';

    return endDate ? `${startDate} - ${endDate}` : startDate;
  }
}
