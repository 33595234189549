import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DocumentsState} from "@tech/pages/documents/store/documents.state";

export const selectDocumentsStore = createFeatureSelector<DocumentsState>('techDocuments');

export const selectDocumentsPage = createSelector(
  selectDocumentsStore,
  (state: DocumentsState) => state.page
);

export const selectDocumentsPageList = createSelector(
  selectDocumentsStore,
  (state: DocumentsState) => state.items
);

export const selectDocumentsPagePagination = createSelector(
  selectDocumentsStore,
  (state: DocumentsState) => state.paging
);

export const selectDocumentsPageLoading = createSelector(
  selectDocumentsStore,
  (state: DocumentsState) => state.isLoading
);
