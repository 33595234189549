import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TokenDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {SharedFormPasswordComponent} from "@shared/components/form-password/shared-form-password.component";
import {TranslateModule} from "@ngx-translate/core";
import {TechLoginState} from "@tech/pages/login/store/tech-login.state";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";

@Component({
  selector: 'app-tech-reset-password',
  templateUrl: './tech-reset-password.page.html',
  styleUrls: ['./tech-reset-password.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    SharedFormPasswordComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  standalone: true
})
export class TechResetPasswordPage implements OnInit {

  isLoading$!: Observable<boolean>;
  selectToken$!: Observable<TokenDto | null | undefined>;
  form: FormGroup;

  constructor(
    private _store: Store<TechLoginState>,
    private _formBuilder: FormBuilder
  ) {
    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TechLoginSelectors.selectIsLoading));
    this.selectToken$ = this._store.pipe(select(TechLoginSelectors.selectToken));
  }

  submitReset(): void {
    const email = this.form.value.email;

    this._store.dispatch(
      TechLoginActions.resetRequestPassword({
        email,
        isLoadingId: email!
      })
    );
  }
}
