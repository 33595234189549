import {Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {select, Store} from "@ngrx/store";
import {IonicModule} from "@ionic/angular";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {selectTenantDisplayName} from "@shared/stores/login-base/store/shared-login-base.selector";
import {map} from "rxjs/operators";
import {TechLoginState} from "@tech/pages/login/store/tech-login.state";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";
import {TechSideBarState} from "@tech/components/side-bar/store/tech-side-bar.state";
import {TechSideBarActions} from "@tech/components/side-bar/store/tech-side-bar.actions-type";
import {selectSideBarLogo} from "@tech/components/side-bar/store/tech-side-bar.selectors";

@Component({
  selector: 'app-tech-side-bar',
  templateUrl: './tech-side-bar.component.html',
  styleUrls: ['./tech-side-bar.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    SharedSafePipe,
    NgOptimizedImage,
    TranslateModule
  ],
  standalone: true
})
export class TechSideBarComponent implements OnInit {

  logo$: Observable<string>;
  displayName$: Observable<string>;
  deleteEnabled$: Observable<boolean> = of(false);

  constructor(
    private _store: Store<TechSideBarState>,
    private _loginStore: Store<TechLoginState>
  ) {
    this.logo$ = of();
    this.displayName$ = of();

    // simulated for app tests by store providers
    this.deleteEnabled$ = this._loginStore.pipe(
      select(selectTenantDisplayName),
      map(x => x === "App Test")
    );
  }

  ngOnInit() {
    this._initValues();
  }

  /**
   * @name loadLogo
   * @description
   * dispatch the action to request the logo
   * @memberof TechSideBarComponent
   */
  loadLogo() {
    this._store.dispatch(TechSideBarActions.getLogo());
  }

  /**
   * @name _initValues
   * @description
   * init the values to let the template handle it with async pipe
   * @memberof TechSideBarComponent
   */
  private _initValues(): void {
    this.logo$ = this._store.pipe(select(selectSideBarLogo));
    this.displayName$ = this._loginStore.pipe(select(TechLoginSelectors.selectTenantDisplayName));
  }

  /**
   * @name logout
   * @description
   * launch a logout action
   * @memberof TechSideBarComponent
   */
  logout() {
    this._loginStore.dispatch((TechLoginActions.initLogout()));
  }

  delete() {
    if (confirm("Delete account and all data?")) {
      alert("Account will be deleted within 7 days");
      this._loginStore.dispatch((TechLoginActions.initLogout()));
    }
  }
}
