<ion-card>
  <ion-card-content>
    <ion-card-title>
      <ion-text color="dark">{{ missionDetail.title }}</ion-text>
    </ion-card-title>
    <ion-text color="medium">
      <small class="ion-text-uppercase">
        <span class="margin-right">#{{ missionDetail.missionId }}</span>
        <span class="margin-right">
            {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.DATE.PLANNED.TITLE' | translate }}
          </span>
        <span>{{ formatDates(missionDetail.plannedStart, missionDetail.plannedEnd) }}</span>
      </small>
    </ion-text>
    <div class="spacer-1rem"></div>
    <ng-container *ngIf="missionDetail.description">
      <small>
        <ion-text color="dark">
          {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.DESCRIPTION' | translate }}
        </ion-text>
      </small>
      <app-user-html [html]="missionDetail.description"></app-user-html>
    </ng-container>
    <ion-list *ngIf="selectedStereotype$ | async as selectedStereotype">
      <ion-item>
        <app-tech-inventory-mission-type-options
          [stereotypeNameType]="selectedStereotype.name!"
        ></app-tech-inventory-mission-type-options>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>
