import {Component} from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule
} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {Observable} from "rxjs";
import {SharedFormPasswordComponent} from "@shared/components/form-password/shared-form-password.component";
import {IDispatchFormData} from "@shared/components/form-password/interfaces/dispatch-form-data.interface"
import {TechLoginState} from "@tech/pages/login/store/tech-login.state";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";

@Component({
  selector: 'app-tech-change-password',
  templateUrl: './tech-change-password.page.html',
  styleUrls: ['./tech-change-password.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedFormPasswordComponent
  ],
  standalone: true
})
export class TechChangePasswordPage {
  loginData!: {
    email: string;
    passwordBase64: string;
    password: string;
  }
  isLoading$: Observable<boolean>;

  constructor(private _store: Store<TechLoginState>, private _activatedRoute: ActivatedRoute) {
    this.isLoading$ = this._store.pipe(select(TechLoginSelectors.selectIsLoading));
    this.loginData = this._activatedRoute.snapshot.data['loginData'];
    this.loginData.password = atob(this.loginData.passwordBase64);
  }

  /**
   * @name onSubmitChange
   * @description
   * save need, it variables  to call the reset dispatch
   * @memberof TechChangePasswordPage
   * @param form
   */
  onSubmitChange(form: FormGroup): void {
    const newPassword = form.value.newPassword;

    const dispatchData: IDispatchFormData = {
      newPassword: newPassword,
      newPasswordBase64: btoa(newPassword),
      confirmPassword: form.value.confirmPassword,
      email: form.value.email
    };

    if (dispatchData.newPassword === dispatchData.confirmPassword) {
      this.dispatchResetPassword(dispatchData);
    }
  }

  /**
   * @name dispatchResetPassword
   * @description
   * dispatch the change password action
   * @memberof TechChangePasswordPage
   * @param data
   */
  dispatchResetPassword(data: IDispatchFormData): void {
    this._store.dispatch(
      TechLoginActions.changePassword({
        email: data.email,
        oldPasswordBase64: this.loginData.passwordBase64,
        newPasswordBase64: data.newPasswordBase64
      })
    );
  }
}
