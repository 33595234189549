import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Router, UrlTree} from "@angular/router";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {TechIssuesSelectors} from "@tech/pages/issues/store/tech-issues.selector-type";

@Injectable({
  providedIn: 'root'
})
export class TechIssueReadGuard {

  constructor(private _store: Store, private _router: Router) {
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._store.pipe(
      withLatestFrom(this._store.select(TechIssuesSelectors.selectDetailDataFormatted)),
      map(([_, formattedData]) => {

        if (Object.keys(formattedData).length === 0) {
          return this._router.createUrlTree(['tech/logged/issues']);
        } else {
          return true;
        }
      })
    );
  }
}
