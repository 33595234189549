<ion-card *ngIf="!isRemove">
  <ion-card-content>
    <form [formGroup]="articleForm">
      <ion-item lines="none" class="article">
        <ion-text slot="start" (click)="onToggleNotes()">
          <h1 style="display:flex" class="ion-align-items-center">
            {{ name }}
            <ng-container *ngIf="isAbleToDisplayNotes">
              <ion-icon class="ion-margin-start" *ngIf="isShowNotes" name="chevron-up"
                        (click)="onToggleNotes()"></ion-icon>
              <ion-icon class="ion-margin-start" *ngIf="!isShowNotes" name="chevron-down"
                        (click)="onToggleNotes()"></ion-icon>
            </ng-container>

          </h1>
        </ion-text>
        <ion-text class="close-item" slot="end" *ngIf="isRemovable">
          <h1>
            <ion-icon name="close-outline" (click)="onRemove()"></ion-icon>
          </h1>
        </ion-text>
      </ion-item>

      <ion-row *ngIf="isShowNotes && isAbleToDisplayNotes">
        <ion-col size="12">
          <ion-textarea style="min-height: 0" label="Notes" labelPlacement="stacked" formControlName="note"
                        auto-grow="true" rows="1" color="dark"></ion-textarea>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
          <ion-item class="article" lines="none">
            <app-tech-inventory-mission-detail-report-detail-decimal-input
              [label]="amountLabel"
              labelPlacement="stacked"
              [value]="amount"
              [control]="articleForm.get('amount')"
              [isDisabled]="disableForm"
              [unit]="amountUnitLabel"
              [inputType]="DecimalInputTypes.Decimal"
              (change)="onPriceChanged()">
              >
            </app-tech-inventory-mission-detail-report-detail-decimal-input>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
          <ion-item class="article" lines="none">
            <app-tech-inventory-mission-detail-report-detail-decimal-input
              label="Unit price"
              labelPlacement="stacked"
              [value]="unitPrice"
              [control]="articleForm.get('unitPrice')"
              [isDisabled]="disableForm"
              [inputType]="DecimalInputTypes.Currency"
              (change)="onPriceChanged()">
            </app-tech-inventory-mission-detail-report-detail-decimal-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </form>
  </ion-card-content>
</ion-card>
