import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {EAssignedUser} from "@tech/pages/inventory/enums/assigned-user.emun";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryMissionPagesService {

  private _selectedSegment: EAssignedUser;

  constructor(
    private _router: Router
  ) {
    this._selectedSegment = EAssignedUser.Mine;
  }

  set selectedSegment(userSelection: EAssignedUser) {
    this._selectedSegment = userSelection;
  }

  get selectedSegment() {
    return this._selectedSegment;
  }

  resetSegment() {
    this.selectedSegment = EAssignedUser.Mine;
  }

  changeSegmentSetting(currentSegment: any) {
    const currentUrl = this._router.url;
    const urlSegments = currentUrl.split('/');
    const idIndex = urlSegments.findIndex(segment => segment === 'mission') + 1;
    const id = urlSegments[idIndex];
    let targetRoute = '';

    if (!id) {
      this._router.navigate([`/tech/logged/inventory/mission`]);
    } else {
      switch (currentSegment) {
        case EAssignedUser.Mine:
          this.selectedSegment = EAssignedUser.Mine;
          targetRoute = `/tech/logged/inventory/mission/${ id }/view`;
          break;
        case EAssignedUser.PrimaryIssue:
          this.selectedSegment = EAssignedUser.PrimaryIssue;
          targetRoute = `/tech/logged/inventory/mission/${ id }/issue`;
          break;
        case EAssignedUser.Unassigned:
          this.selectedSegment = EAssignedUser.Unassigned;
          targetRoute = `/tech/logged/inventory/mission/${ id }/report`;
          break;
        default:
          this.selectedSegment = EAssignedUser.Mine;
          targetRoute = `/tech/logged/inventory/mission/${ id }/view`;
          break;
      }
      this._router.navigate([targetRoute]);
    }
  }

  checkSegmentByString(segment: string): EAssignedUser | undefined {
    switch (segment) {
      case 'view':
        return EAssignedUser.Mine
      case 'issue':
        return EAssignedUser.PrimaryIssue
      case 'report':
        return EAssignedUser.Unassigned
      default:
        return undefined
    }
  }
}
