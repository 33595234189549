import {Component, Input, OnInit} from '@angular/core';
import {MissionDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService,} from "@ngx-translate/core";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {TechInventoryService} from "@tech/pages/inventory/services/tech-inventory.service";
import {
  TechInventoryMissionTypeOptionsComponent
} from "@tech/pages/inventory/components/mission-type-options/tech-inventory-mission-type-options.component";
import {
  MissionStateOptionsComponent
} from "@shared/components/issues/components/custom/read/mission/components/mission-state-options/mission-state-options.component";
import {
  TechInventoryMissionStateActionsComponent
} from "@tech/pages/inventory/components/mission-state-actions/tech-inventory-mission-state-actions.component";

@Component({
  selector: 'app-tech-inventory-mission-detail-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    UserHtmlComponent,
    TechInventoryMissionTypeOptionsComponent,
    MissionStateOptionsComponent,
    TechInventoryMissionStateActionsComponent
  ],

})
export class TechInventoryMissionDetailViewResumeComponent implements OnInit {
  @Input() missionDetail: MissionDto;

  constructor(
    public techInventoryService: TechInventoryService,
    private _translateService: TranslateService
  ) {
    this.missionDetail = {};

  }

  ngOnInit() {

  }

  prepareLocationValue(name: string | null | undefined, number: string | null | undefined) {
    return name || number ? `${name ?? ''} ${number ? '(' + number + ')' : ''}` : this._translateService.instant('COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.EMPTIES.LOCATION');
  }

  prepareEditorValue(displayName: string | null | undefined) {
    return displayName ? displayName : this._translateService.instant('COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.EMPTIES.EDITOR');
  }
}
