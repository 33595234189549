import {Injectable} from "@angular/core";
import {TechIssueApiBaseService} from "@tech/pages/issues/services/tech-issue-api-base.service";
import {Observable} from "rxjs";
import {MessageControlDto} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class TechIssueControlMessageApiService extends TechIssueApiBaseService {

  override getIssueTemplatePreviewListById(id: number): Observable<any> {
    console.warn('Template preview message control it is not implemented yet');
    return super.get<any>(`/tech/v2/issue/message-control/template/${ id }/preview`, undefined);
  }

  override getIssueById(issueId: number): Observable<MessageControlDto> {
    return super.get<MessageControlDto>(`/tech/v2/issue/message-control/${ issueId }`);
  }

  override sendIssueTemplatePreviewById(templateId: number, issue: any): Observable<void> {
    console.warn('Submit message control it is not implemented yet');
    return super.post<void>(`/tech/v2/issue/message-control/template/${ templateId }/submitIssue`, undefined, issue);
  }

}
