<ng-container *ngIf="isInput else currencyString">
  <ion-input #input
             [label]="label" [labelPlacement]="labelPlacement" [value]="value" type="number"
             [disabled]="disabled"
             (ionInput)="onInput($event)" (ionChange)="switchToCurrency()" (ionBlur)="switchToCurrency()">
  </ion-input>
</ng-container>

<ng-template #currencyString>
  <ion-input [label]="label" [labelPlacement]="labelPlacement" [value]="formattedValue"
             [disabled]="disabled"
             [readonly]="true" (click)="switchToInput()">
  </ion-input>
</ng-template>
