import {Component} from '@angular/core';
import {IonicModule, ViewWillEnter} from "@ionic/angular";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {IssueListDto, Orders} from "@server-models";
import {filter, Observable, Subscription} from "rxjs";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {select, Store} from "@ngrx/store";
import {tap} from "rxjs/operators";
import {SharedIssueItemComponent} from "@shared/components/issues/components/issue-item/shared-issue-item.component";
import {TechIssueState} from "@tech/pages/issues/store/tech-issue.state";
import {TechIssuesActions} from "@tech/pages/issues/store/tech-issue.actions-type";
import {TechIssuesSelectors} from "@tech/pages/issues/store/tech-issue.selector-type";
import {SharedButtonSideMenuComponent} from "@shared/components/button-side-menu/shared-button-side-menu.component";

@Component({
  selector: 'app-tech-issue',
  templateUrl: './tech-issue.page.html',
  styleUrls: ['./tech-issue.page.scss'],
  imports: [
    IonicModule,
    AsyncPipe,
    SharedIssueItemComponent,
    NgForOf,
    NgIf,
    TranslateModule,
    SharedButtonSideMenuComponent
  ],
  standalone: true
})
export class TechIssuePage implements ViewWillEnter {

  paginationParams: IPaginationParams;
  isDragRefresh: boolean;
  isInfinityLoading: boolean;

  issuesData$: Observable<IssueListDto[]>;
  pagingData$: Observable<IPagination>;
  isLoading$: Observable<boolean>;

  openSubscriptionList$: Subscription[];

  constructor(
    private _store: Store<TechIssueState>
  ) {
    this.issuesData$ = this._store.pipe(select(TechIssuesSelectors.selectPageList));
    this.pagingData$ = this._store.pipe(select(TechIssuesSelectors.selectPagePagination));
    this.isLoading$ = this._store.pipe(select(TechIssuesSelectors.selectPageLoading));
    this.openSubscriptionList$ = [];
    this.isDragRefresh = false;
    this.isInfinityLoading = false;
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 25,
      cols: 'Content',
      sortField: 'DateTime',
      sort: Number(Orders.Descending)
    };
  }

  ionViewWillEnter(): void {
    this.loadIssuesByPagination(true);
  }

  /**
   * @name loadIssuesByPagination
   * @description
   * dispatch issues request
   * @memberof TechIssuePage
   * @param isRefresh
   */
  loadIssuesByPagination(isRefresh: boolean = false): void {
    this._store.dispatch((TechIssuesActions.getItemsPaginated({
      params: this.paginationParams,
      isRefresh: isRefresh
    })));
  }

  /**
   * @name loadMoreIssues
   * @description
   * load more issues on infinity isLoading event
   * @memberof TechIssuePage
   * @param event
   */
  loadMoreIssues(event: any): void {
    this.paginationParams.pageNumber++;
    this.isInfinityLoading = true;
    this.loadIssuesByPagination();
    this.isLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        event.target.complete();
        this.isInfinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name handleRefresh
   * @description
   * handle the isRefresh page on drag event
   * @memberof TechIssuePage
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.isDragRefresh) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.isDragRefresh = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadIssuesByPagination(true);

    this.isLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.isDragRefresh = false;
      })
    ).subscribe();
  }

  /**
   * @name gotoAdd
   * @description
   * navigate to add issue
   * @memberof TechIssuePage
   */
  gotoAdd(): void {
    this.paginationParams.pageNumber = 1;
    this._store.dispatch(TechIssuesActions.navigateToNewIssue());
  }

  onNavigateToIssue() {
    this.paginationParams.pageNumber = 0;
  }
}
