import {inject} from '@angular/core';
import {UrlTree} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {
  SharedConfirmLeaveModalComponent
} from "@shared/components/confirm-leave-modal/shared-confirm-leave-modal.component";
import {
  TechInventoryMissionPagesService
} from "@tech/pages/inventory/components/mission/components/detail/services/tech-mission-pages.service";
import {EAssignedUser} from "@tech/pages/inventory/enums/assigned-user.emun";
import {Store} from "@ngrx/store";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-inventory.actions-type";

export interface CanLeave {
  canLeave: () => boolean;
}

export const techCanLeavePageGuard: (component: any, router: any, currentState: any,
                                     nextState: any) => (boolean | Promise<boolean | UrlTree>) = (component, router,
                                                                                                  currentState,
                                                                                                  nextState) => {
  const modalCtrl = inject(ModalController);
  const store = inject(Store);
  const techInventoryMissionPagesService = inject(TechInventoryMissionPagesService);

  // in case that is trying to leave because it is a submit
  if (nextState.url.split('/').includes('success')) {
    return true;
  }

  const canLeave = component.canLeave ? component.canLeave() : true;
  return canLeave ? true : presentModal(modalCtrl, techInventoryMissionPagesService, store);
};

async function presentModal(modalCtrl: ModalController,
                            techInventoryMissionPagesService: TechInventoryMissionPagesService,
                            store: Store): Promise<boolean | UrlTree> {
  const modal = await modalCtrl.create({
    component: SharedConfirmLeaveModalComponent
  });
  await modal.present();

  const { data } = await modal.onWillDismiss();

  // in case that the navigation is cancel it is needed also update the segment
  if (!!data?.confirmed == false) {
    techInventoryMissionPagesService.changeSegmentSetting(EAssignedUser.Unassigned);
  }

  // to resolve the navigation and set the mission loading to false
  store.dispatch(TechInventoryActions.missionNavigateDone());
  return !!data?.confirmed;
}
