<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ 'COMPONENTS.MISSIONS.REPORT.MODAL_CONFIRMATION.TITLE' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" (click)="exit()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
    <ion-card-content>
      <ion-list lines="none">
        <ion-item class="ion-no-padding">
          <ion-label>
            <h2>{{ 'COMPONENTS.MISSIONS.REPORT.MODAL_CONFIRMATION.MESSAGE' | translate }}</h2>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <div class="action-button-container">
      <ion-button color="medium" (click)="answer(true)">
        {{ 'COMPONENTS.MISSIONS.REPORT.MODAL_CONFIRMATION.OPTIONS.DISCARD' | translate }}
      </ion-button>
      <ion-button (click)="answer(false)">
        {{ 'COMPONENTS.MISSIONS.REPORT.MODAL_CONFIRMATION.OPTIONS.CANCEL' | translate }}
      </ion-button>
    </div>
  </ion-card>

</ion-content>
