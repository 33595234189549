import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormGroup} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {SharedFormPasswordComponent} from "@shared/components/form-password/shared-form-password.component";
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {IDispatchFormData} from "@shared/components/form-password/interfaces/dispatch-form-data.interface";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {TechLoginState} from "@tech/pages/login/store/tech-login.state";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";

@Component({
  selector: 'app-tech-login-new-password-dialog',
  templateUrl: './tech-login-new-password-dialog.component.html',
  styleUrls: ['./tech-login-new-password-dialog.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SharedFormPasswordComponent
  ],
  standalone: true
})
export class TechLoginNewPasswordDialogComponent implements OnInit {

  @Input() newPasswordData: { token: string, email: string };

  shadowForm: FormGroup;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;

  constructor(
    private _modalControllerService: SharedModalControllerService,
    private _store: Store<TechLoginState>
  ) {
    this.newPasswordData = <{ token: string, email: string }>{};
    this.shadowForm = new FormGroup({});
    this.isLoading$ = of(false);
    this.isLoadingId$ = of(NaN);
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TechLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TechLoginSelectors.selectIsLoadingId));
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof TechLoginNewPasswordDialogComponent
   */
  exit(): void {
    this._modalControllerService.closeModal();
  }

  /**
   * @name onFormChange
   * @description
   * use a shadow copy from the emitted form child component
   * @memberof TechLoginNewPasswordDialogComponent
   * @param form
   */
  onFormChange(form: FormGroup): void {
    this.shadowForm = form;
  }

  /**
   * @name submitForm
   * @description
   * save need, it variables to call the dispatch action
   * @memberof TechLoginNewPasswordDialogComponent
   */
  submitForm(): void {
    const newPassword = this.shadowForm.value.newPassword;

    const dispatchData: IDispatchFormData = {
      newPassword: newPassword,
      newPasswordBase64: btoa(newPassword),
      confirmPassword: this.shadowForm.value.confirmPassword,
      email: this.shadowForm.value.email
    };

    if (dispatchData.newPassword === dispatchData.confirmPassword) {
      this.dispatchNewPassword(dispatchData);
    }
  }

  /**
   * @name dispatchNewPassword
   * @description
   * dispatch the resetPassword action
   * @memberof TechLoginNewPasswordDialogComponent
   * @param data
   */
  dispatchNewPassword(data: IDispatchFormData): void {
    this._store.dispatch(
      TechLoginActions.resetPassword({
        resetData: {
          token: this.newPasswordData?.token,
          newPassword: data.newPassword,
          newPasswordBase64: data.newPasswordBase64,
          email: data.email
        }, isLoadingId: this.newPasswordData?.token
      })
    );
  }

  checkLoadingButton(buttonId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (buttonId! === id) && isLoading)
    );
  }
}
