import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";
import {
  MissionState,
} from "@server-models";
import {CoreClockService} from "@core/services/core-clock.service";
import {EAssignedUser} from "@tech/pages/inventory/enums/assigned-user.emun";

@Injectable({
  providedIn: 'root'
})
export class TechInventoryService {
  private settingReset = new BehaviorSubject<{
    state?: MissionState | null,
    stereotype?: number | null | undefined
  }>({
    state: null,
    stereotype: null
  });
  private segmentReset = new BehaviorSubject<EAssignedUser>(EAssignedUser.Mine);

  constructor(
    private _clockService: CoreClockService
  ) {
  }

  getSettingReset() {
    return this.settingReset;
  }

  getSegmentReset() {
    return this.segmentReset;
  }

  resetSetting() {
    this.settingReset.next({
      state: null,
      stereotype: null
    });
  }

  setSetting(incSetting: {
    state?: MissionState | null,
    resource?: number | null
  }) {

    this.settingReset.next(incSetting);
  }

  resetSegment() {
    this.segmentReset.next(EAssignedUser.Mine);
  }

  setSegment(incSegment: EAssignedUser) {
    this.segmentReset.next(incSegment);
  }

  resetAll() {
    this.settingReset.next({
      state: null,
      stereotype: null
    });
    this.segmentReset.next(EAssignedUser.Mine);
  }

  formatDate(date: string): string {
    return date ? this._clockService.formatDateWithWeekday(date) : "";
  }
}
