import {Injectable} from "@angular/core";
import {IRequestOptions} from "@shared/interfaces/request-options.interface";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {Observable} from "rxjs";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {ResourceDto} from "@server-models";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class TechResourceApiService extends CoreHttpClientApiBaseService {

  postResourceByParentIdPaginated(parentId: number, paramsPaginated: IPaginationParams,
                                  body: any): Observable<IOrgaResponse<ResourceDto[]>> {
    const options: IRequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        ParentId: parentId.toString()
      }
    }
    return super.post<IOrgaResponse<ResourceDto[]>>(`/tech/resource/inheritable/filter`, undefined, body, options);
  }
}
