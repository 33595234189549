import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {IonicModule, NavController} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {filter, Observable, take} from "rxjs";
import {tap} from "rxjs/operators";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {OrgaFolderItemDto} from "@server-models";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {select, Store} from "@ngrx/store";
import {DocumentsState} from "@tech/pages/documents/store/documents.state";
import {
  selectDocumentsPageList,
  selectDocumentsPageLoading,
  selectDocumentsPagePagination
} from "@tech/pages/documents/store/documents.selectors";
import {DocumentsListComponent} from "@tech/pages/documents/components/documents-list/documents-list.component";
import {ActivatedRoute} from "@angular/router";
import {InfoCardComponent} from "@tech/pages/infos/components/info-card/info-card.component";
import {BaseSearchBarComponent} from "@shared/components/search-bar/components/base-search-bar.component";
import {DocumentsApiService} from "@tech/pages/documents/services/documents-api.service";
import {DocumentsActions} from "@tech/pages/documents/store/documents.actions-type";
import {ButtonTechSideMenuComponent} from "@tech/components/button-tech-side-menu/button-tech-side-menu.component";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";


@Component({
  selector: 'app-documents',
  templateUrl: './documents.page.html',
  styleUrls: ['./documents.page.scss'],
  imports: [
    AsyncPipe,
    ButtonTechSideMenuComponent,
    IonicModule,
    NgForOf,
    NgIf,
    TranslateModule,
    DocumentsListComponent,
    InfoCardComponent,
    BaseSearchBarComponent
  ],
  standalone: true
})
export class DocumentsPage implements OnInit {

  paginationParams: PaginationParams;
  ownLoad: boolean;
  innerDoc: boolean;
  currentPath: string[];
  defaultPath: string[];
  infinityLoading: boolean;

  documentsData$: Observable<OrgaFolderItemDto[]> | null;
  pagingData$: Observable<Pagination>;
  isLoading$: Observable<boolean>;
  tenantId$: Observable<number | undefined>;

  displaySearchBar: boolean;

  constructor(
    private _store: Store<DocumentsState>,
    private _activatedRouter: ActivatedRoute,
    private _navController: NavController,
    private _documentsApiService: DocumentsApiService
  ) {
    this.documentsData$ = this._store.pipe(select(selectDocumentsPageList));
    this.pagingData$ = this._store.pipe(select(selectDocumentsPagePagination));
    this.isLoading$ = this._store.pipe(select(selectDocumentsPageLoading));
    this.tenantId$ = this._store.pipe(select(TechLoginSelectors.selectTenantId));
    this.ownLoad = false;
    this.infinityLoading = false;
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 25,
      cols: 'Content',
      sortField: 'Type',
      sort: 1,
      sortThenField: 'Name',
      sortThen: 0
    };
    this.innerDoc = false;
    this.currentPath = [this._activatedRouter.snapshot.queryParamMap.get('path') || ''];
    this.defaultPath = [""];
    this.displaySearchBar = false;
  }

  ngOnInit() {
    this.loadDocumentsByPagination();
  }

  /**
   * @name onPathChange
   * @description
   * on path change event launch the load documents
   * @memberof DocumentsPage
   * @param paths
   */
  onPathChange(paths: { currentPath: string }): void {
    this.getPath(this.currentPath);
    this.currentPath.push(paths.currentPath);
    this.paginationParams.pageNumber = 1;
    this.innerDoc = true;
    this.loadDocumentsByPagination(true);
  }

  /**
   * @name changeDisplayBar
   * @description
   * change boolean value of display search bar to hide or display
   * @memberof DocumentsPage
   */
  changeDisplayBar() {
    this.displaySearchBar = !this.displaySearchBar;
    if (!this.displaySearchBar) {
      this.loadDocumentsByPagination(true);
    }
  }

  /**
   * @name back
   * @description
   * navigate back to the previousPath and relaunch the load documents request
   * @memberof DocumentsPage
   */
  back(): void {
    this.currentPath.pop();

    this._navController.navigateBack(['tech/logged/documents'], {queryParams: {path: this.getPath(this.currentPath)}}).then(() => {
      this.paginationParams.pageNumber = 1;
      this.loadDocumentsByPagination(true);
    })
  }

  /**
   * @name loadDocumentsByPagination
   * @description
   * subscribe to tenantId to get the value and dispatch the action to get documents
   * @memberof DocumentsPage
   * @param refresh
   */
  loadDocumentsByPagination(refresh: boolean = false): void {
    this.tenantId$.pipe(
      take(1)
    ).subscribe(data => {
      const isDefaultPath = JSON.stringify(this.currentPath) === JSON.stringify(this.defaultPath);
      this.innerDoc = !isDefaultPath;
      if (!isDefaultPath) {
        this.currentPath = this.createPreviousPath(this.currentPath);
      }
      this._store.dispatch(DocumentsActions.getItemsPaginated({
        path: this.getLastPosition(this.currentPath),
        tenantId: data!,
        params: this.paginationParams,
        refresh
      }));
    });
  }

  /**
   * @name createPreviousPath
   * @description
   * Use on refresh the page the array generated need to have all the paths so create them
   * @memberof DocumentsPage
   * @param inputArray
   * @returns {string[]}
   */
  createPreviousPath(inputArray: string[]): string[] {
    const resultArray: string[] = [];
    resultArray.push("");

    for (const item of inputArray) {
      const parts = item.split('/');
      let partial = '';
      for (const part of parts) {
        partial += (partial ? '/' : '') + part;
        if (!resultArray.includes(partial)) {
          resultArray.push(partial);
        }
      }
    }
    return resultArray;
  }

  /**
   * @name getPath
   * @description
   * returns the path string formatted
   * @memberof DocumentsPage
   * @param paths
   */
  getPath(paths: string[]): string {
    return paths.join('');
  }

  /**
   * @name getLastPosition
   * @description
   * return the last item of the array
   * @memberof DocumentsPage
   * @param array
   */
  getLastPosition(array: string[]): string {
    const lastPosition = array.length - 1;
    return array[lastPosition];
  }

  /**
   * @name loadMoreDocuments
   * @description
   * load more documents when infinity scroll
   * @memberof DocumentsPage
   * @param event
   */
  loadMoreDocuments(event: any): void {
    this.paginationParams.pageNumber++;
    this.infinityLoading = true;
    this.loadDocumentsByPagination();
    this.isLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        event.target.complete();
        this.infinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name handleRefresh
   * @description DocumentsComponent
   * refresh the data on drag refresh
   * @memberof
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.ownLoad) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.ownLoad = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadDocumentsByPagination(true);

    this.isLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.ownLoad = false;
      })
    ).subscribe();
  }

  /**
   * @name onSearch
   * @description
   * trigger the event from base search bar to dispatch a search or refresh load documents
   * @memberof DocumentsPage
   * @param value
   */
  onSearch(value: string): void {
    if (value) {
      const path = this.getLastPosition(this.currentPath);
      this._store.dispatch(DocumentsActions.getItemsSearched({toSearch: value, path: path}))
    } else {
      this.loadDocumentsByPagination(true);
    }
  }
}
