import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ArticleUsageDto, CustomPropertyType} from '@server-models';
import {
  IReportArticleForm
} from "@tech/pages/inventory/components/mission/components/detail/pages/report/interfaces/report-article-form.interface";
import {Subscription} from "rxjs";
import {
  EDecimalInputTypes,
  TechInventoryMissionDetailReportDecimalInputComponent
} from '@tech/pages/inventory/components/mission/components/detail/pages/report/components/decimal-input/tech-inventory-mission-detail-report-decimal-input.component';
import {
  SharedCustomPropertyFormInputDropdownComponent
} from "@shared/components/custom-property-form/components/input/components/dropdown/shared-custom-property-form-input-dropdown.component";
import {
  SharedCustomPropertyFormInputTextComponent
} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";

@Component({
  selector: 'app-tech-inventory-mission-detail-report-article',
  templateUrl: './tech-inventory-mission-detail-report-article.component.html',
  styleUrls: ['./tech-inventory-mission-detail-report-article.component.scss'],
  standalone: true,
  providers: [CurrencyPipe],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedCustomPropertyFormInputDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    TechInventoryMissionDetailReportDecimalInputComponent,
    SharedCustomPropertyFormInputTextComponent
  ]
})
export class TechInventoryMissionDetailReportArticleComponent implements OnInit, OnDestroy {

  @Input() article!: ArticleUsageDto;
  @Input() name: string = 'Small parts';
  @Input() amount: number = 0;
  @Input() unitPrice: number = 0;
  @Input() isAbleToDisplayNotes: boolean = false;
  @Input() note: string | null | undefined = '';
  @Input() amountLabel: string = 'Amount';
  @Input() amountUnitLabel: string = 'Pcs.';
  @Input() isRemovable: boolean = true;
  @Input() disableForm: boolean = false;

  @Output() articleChanged = new EventEmitter<IReportArticleForm>();
  @Output() remove = new EventEmitter<IReportArticleForm>();

  articleForm: FormGroup = new FormGroup({});
  articleFromValueChangesSubscription: Subscription | null;
  isShowNotes = false;
  isRemove = false;

  constructor() {
    this.articleFromValueChangesSubscription = null;
  }

  ngOnInit() {
    if (this.article) {
      this.name = this.article?.article?.name ?? '';
      this.unitPrice = this.article.price ?? 0;
      this.amountUnitLabel = this.article.unit ?? '';
      this.amount = this.article.count ?? 1;
    }

    this.articleForm = new FormGroup({
      articleId: new FormControl(this.article?.article?.articleId ?? 0),
      localId: new FormControl(this.article?.id ?? 0),
      name: new FormControl(this.name),
      amount: new FormControl(this.amount),
      unitPrice: new FormControl(this.unitPrice),
      totalPrice: new FormControl(0),
      note: new FormControl(this.note)
    });


    // Triggers articleChanged
    this.articleFromValueChangesSubscription = this.articleForm.valueChanges.subscribe((value: IReportArticleForm) => this.articleChanged.emit(value));

    // Trigger first changes
    this.isShowNotes = !!this.note;
    this.onPriceChanged();
  }

  onToggleNotes() {
    this.isShowNotes = !this.isShowNotes;
  }

  onRemove(): void {
    if (this.isRemovable) {
      this.remove.emit(this.articleForm.value);
    }
  }

  onPriceChanged(): void {
    this.articleForm.get('totalPrice')?.setValue(this.articleForm.value['amount'] * this.articleForm.value['unitPrice']);
  }

  ngOnDestroy(): void {
    if (this.articleFromValueChangesSubscription) {
      this.articleFromValueChangesSubscription.unsubscribe();
    }
  }

  protected readonly CustomPropertyType = CustomPropertyType;
  protected readonly DecimalInputTypes = EDecimalInputTypes;
}
