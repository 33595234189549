<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'COMPONENTS.MISSIONS.MAP.LOCATION' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="onCloseModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <ion-list lines="none">
        <ion-item class="ion-no-padding" button detail (click)="onOpenGoogleMaps()">
          <ion-icon slot="start" name="location" color="primary"/>
          <ion-label>
            <h2>{{ missionDetail.location?.name }}</h2>
            <p>
              <ion-text color="primary">Google Maps</ion-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list lines="none">
        <ion-item class="ion-no-padding">
          <ion-label>
            <p>{{ 'COMPONENTS.MISSIONS.MAP.ADDRESS' | translate }}</p>
            <h2>{{ (missionDetail.shippingAddress?.street ?? '') + ' ' + (missionDetail.shippingAddress?.houseNumber ?? '') }}</h2>
            <h2>{{ (missionDetail.shippingAddress?.zipcode ?? '') + ' ' + (missionDetail.shippingAddress?.city ?? '') }}</h2>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="missionDetail.location?.phoneNumber" class="ion-no-padding">
          <ion-label>
            <p>{{ 'COMPONENTS.MISSIONS.MAP.PHONE' | translate }}</p>
            <a class="phone" [href]="'tel:' + missionDetail.location?.phoneNumber">
              <ion-icon name="call"></ion-icon>
              <h2>{{ missionDetail.location?.phoneNumber }}</h2>
            </a>
          </ion-label>

        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>
