<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.ADD_ARTICLE' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" (click)="onClose()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-shared-base-search-bar class="ion-margin-top" (valueToSearch)="onSearch($event)"></app-shared-base-search-bar>

<ion-content>
  <div style="display: grid" *ngIf="(isLoading$ | async)">
    <ion-spinner class="article-list-spinner"></ion-spinner>
  </div>
  <ion-list *ngIf="(isLoading$ | async) === false">
    <ion-item *ngFor="let article of articles$ | async" (click)="onSelectArticle(article)">
      {{ getArticleName(article) }}
    </ion-item>
  </ion-list>
</ion-content>

