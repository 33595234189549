<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ 'COMPONENTS.MODALS.FILTERS.LABELS' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="exit()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-card *ngIf="filterList">
    <ion-list lines="none">
      <ng-container *ngFor="let filter of filterList">
        <ion-item>
          <ng-container [ngSwitch]="filter.type">
            <!-- Select type filter -->
            <ion-select *ngSwitchCase="EFilterListType.select"
                        [label]="filter.placeholder | translate"
                        [placeholder]="filter.placeholder | translate"
                        [(ngModel)]="filter.currentValue"
                        [name]="filter.keyId"
                        interface="popover"
            >
              <ion-select-option *ngFor="let option of filter.options" [value]="option.value">
                {{ option.display }}
              </ion-select-option>
            </ion-select>
            <!-- Input type filter -->
            <ion-input class="ion-text-right" *ngSwitchCase="EFilterListType.input"
                       [label]="filter.placeholder | translate"
                       [placeholder]="filter.placeholder | translate" [(ngModel)]="filter.currentValue"
                       [name]="filter.keyId" type="text"></ion-input>
          </ng-container>
        </ion-item>
      </ng-container>
    </ion-list>
  </ion-card>
</ion-content>

<ion-footer>
  <ion-button class="apply" expand="full" (click)="applyFilterList()">
    {{ 'COMPONENTS.MODALS.FILTERS.APPLY' | translate }}
  </ion-button>
</ion-footer>

