<ion-spinner *ngIf="(inventoryIsLoading$ | async) else main"></ion-spinner>
<ng-template #main>
  <ng-container *ngIf="missionDetail$ | async as missionDetail">
    <ion-content>
      <!--resume-->
      <app-tech-inventory-mission-detail-container-resume [model]="missionDetail">
      </app-tech-inventory-mission-detail-container-resume>

      <!--description-->
      <app-tech-inventory-mission-detail-container-description class="main"
                                                               [missionDetail]="missionDetail"
      ></app-tech-inventory-mission-detail-container-description>
      <!--custom-values-->
      <app-shared-custom-property-sets-read-only-card
        *ngIf="customPropertySetFormattedList$ | async as customPropertySetFormattedList"
        [propertySetsDetail]="customPropertySetFormattedList"
      ></app-shared-custom-property-sets-read-only-card>
      <!--  attachments-->
      <ion-card *ngIf="missionDetail.attachments && missionDetail.attachments.length > 0">
        <ion-card-content>
          <ion-card-title color="dark">
            {{ 'COMPONENTS.MISSIONS.DETAILS.RESUME.LABELS.ATTACHMENTS' | translate }}
          </ion-card-title>

          <app-shared-attachments [designType]="DesignTypeEnum.Box" [attachmentsInc]="missionDetail.attachments"
                                  [hasHeader]="false"></app-shared-attachments>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ng-container>
</ng-template>

