import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MissionState} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import { TranslateModule} from "@ngx-translate/core";
import {
  MissionStateOptionsComponent
} from "@shared/components/issues/components/custom/read/mission/components/mission-state-options/mission-state-options.component";
import {select, Store} from "@ngrx/store";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";
import {
  MissionStateActionsComponent
} from "@shared/components/issues/components/custom/read/mission/components/mission-state-actions/mission-state-actions.component";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable} from "rxjs";

@Component({
  selector: 'app-tech-inventory-mission-state-actions',
  templateUrl: './tech-inventory-mission-state-actions.component.html',
  styleUrls: ['./tech-inventory-mission-state-actions.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, MissionStateOptionsComponent, MissionStateActionsComponent],

})
export class TechInventoryMissionStateActionsComponent implements OnInit {

  @Input() stateOption!: MissionState;
  @Input() currentMissionId!: number;
  @Output() onAction = new EventEmitter<MissionState>();

  protected readonly MissionState = MissionState;
  changeStateCurrent$!: Observable<MissionState>;
  changeStateLoading$!: Observable<boolean>;

  constructor(
    private _store: Store
  ) {
  }

  ngOnInit() {
    this.changeStateCurrent$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionChangeStateCurrent));
    this.changeStateLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionChangeStateLoading));
    this.changeStateCurrent$.subscribe((currentState => {
      if (currentState != null && this.stateOption != currentState) {
        this.stateOption = currentState;
      }
    }));
  }

  get availableActions(): { color: string; state: MissionState; }[] {
    if (this.stateOption == "0") {
      return [
        {
          color: "medium",
          state: MissionState.Rejected
        },
        {
          color: "medium",
          state: MissionState.Canceled
        },
        {
          color: "primary",
          state: MissionState.Instructed
        },
        {
          color: "primary",
          state: MissionState.Done
        },
      ];
    }
    switch (this.stateOption) {
      case MissionState.Instructed:
        return [
          {
            color: "medium",
            state: MissionState.Rejected
          },
          {
            color: "primary",
            state: MissionState.Done
          }
        ];
      case MissionState.Rejected:
        return [
          {
            color: "medium",
            state: MissionState.Instructed
          },
          {
            color: "primary",
            state: MissionState.Canceled
          }
        ];
      case MissionState.Canceled:
        return [
          {
            color: "medium",
            state: MissionState.Rejected
          }
        ];
      case MissionState.Done:
        return [
          {
            color: "medium",
            state: MissionState.Instructed
          }
        ];
      default:
        return [];
    }
  }

  dispatchActionState(state: MissionState) {
    this._store.dispatch(TechInventoryActions.setMissionState({id: this.currentMissionId, missionToChange: state}));
  }

}
