import {Injectable} from "@angular/core";
import {LocationListDto, ResourceModelListDto, StereotypeListDto} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryHelperService {

  isStereotype(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): mainItem is StereotypeListDto {
    return mainItem !== undefined && mainItem !== null && 'stereotypeId' in mainItem && !('locationId' in mainItem);
  }

  isLocation(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): mainItem is LocationListDto {
    return mainItem !== undefined && mainItem !== null && 'locationId' in mainItem;
  }

  isModel(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): mainItem is ResourceModelListDto {
    return mainItem !== undefined && mainItem !== null && 'resourceModelId' in mainItem;
  }

  getMainItemId(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): number | undefined {
    if (mainItem === undefined || mainItem === null) return undefined;
    if (this.isStereotype(mainItem)) return mainItem.stereotypeId;
    if (this.isLocation(mainItem)) return mainItem.locationId;
    if (this.isModel(mainItem)) return mainItem.resourceModelId;
    return undefined;
  }
}
