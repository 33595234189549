import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
  BehaviorSubject,
  filter,
  Observable,
  of,
  pairwise,
  Subject,
  Subscription
} from 'rxjs';
import {cloneDeep, isEqual} from 'lodash';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule, IonModal} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {
  TechInventoryMissionByDistanceApiService
} from '@tech/pages/inventory/services/tech-mission-by-distance-api.service';
import {
  FilterDto,
  MissionDistanceListDto,
  PointListDto,
  ResourceInMissionDto
} from '@server-models';
import {Geolocation, Position} from '@capacitor/geolocation';
import {catchError, map, startWith, tap} from 'rxjs/operators';
import {GoogleMapsModule} from '@angular/google-maps';
import {ToastControllerService} from '@shared/services/toast-controller.service';
import {Router} from '@angular/router';
import {
  INearbyMissionMapDto
} from "@tech/pages/inventory/components/mission/components/map/interfaces/near-by-mission-map-dto.interface";
import {
  INearbyMissionsDto
} from "@tech/pages/inventory/components/mission/components/map/interfaces/near-by-missions-dto.interface";

@Component({
  selector: 'app-tech-inventory-mission-map',
  templateUrl: './tech-inventory-mission-map.component.html',
  styleUrls: ['./tech-inventory-mission-map.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    GoogleMapsModule
  ]
})
export class TechInventoryMissionMapComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('detailModal') public detailModal!: IonModal;

  @Input() missionFilter$?: Observable<FilterDto[]>;
  @Input() mapConfig?: { distance?: number | string | null };
  missionFilter: FilterDto[] = [];

  loading$ = new Subject<boolean>();

  nearbyMissions$: Observable<INearbyMissionMapDto> = of({} as INearbyMissionMapDto);
  missions: MissionDistanceListDto[] = [];
  currentNearbyMissionsForModalSubject: BehaviorSubject<INearbyMissionsDto | undefined> = new BehaviorSubject<INearbyMissionsDto | undefined>(undefined);

  map!: google.maps.Map;
  options: google.maps.MapOptions;
  detailZoom = 9;
  overviewZoom = 6;

  currentOrigin: google.maps.LatLngLiteral | undefined;
  defaultLocation: google.maps.LatLngLiteral = {
    lat: 50.00000,
    lng: 10.00000
  };
  userPositionIcon: string = 'assets/icon/google-gps/gps-icon.png';

  subscriptions: (Subscription | undefined)[] = [];

  constructor(
    private _missionByDistanceService: TechInventoryMissionByDistanceApiService,
    private _router: Router,
    private _translateService: TranslateService,
    private _toastService: ToastControllerService
  ) {
    // Map options
    this.options = {
      streetViewControl: false,
      disableDefaultUI: true,
      center: this.defaultLocation,
      // Map style
      // Generate a style json here:
      // https://mapstyle.withgoogle.com/
      styles: [
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ]
    };
  }

  ngOnInit(): void {
    this.loading$.next(true);
    this.initFilterOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['mapConfig']) {
      this.onChangeMapDistance();
    }
  }

  initFilterOptions(): void {
    // Action when filters changed (distinct)
    const filterSubscription = this.missionFilter$
      ?.pipe(
        startWith(null),
        pairwise(),
        filter(([prev, curr]) => !isEqual(prev, curr))
      )
      ?.subscribe(([_, currenFilter]) => {
        this.missionFilter = currenFilter ?? [];
        if (this.currentOrigin)
          this.findMissions(
            this.currentOrigin,
            this.missionFilter
          );
      });

    this.subscriptions.push(filterSubscription);
  }

  initMap(map: google.maps.Map): void {
    this.map = map;
    this.initCenter();
    this.initUserPosition();
  }

  initCenter(): void {
    this.map.setZoom(this.overviewZoom);
    this.map.setCenter(this.defaultLocation);
    this.loading$.next(false);
  }

  onChangeMapDistance() {
    if (this.mapConfig) this.findMissions(this.currentOrigin ?? this.defaultLocation, undefined, this.mapConfig.distance);
  }

  initUserPosition(hasToast: boolean = false, distance: number | string | null = null): void {
    this.loading$.next(true);
    Geolocation.getCurrentPosition().then(
      // On gps position found
      (userPosition: Position) =>
        this.findMissions({
          lat: userPosition.coords.latitude,
          lng: userPosition.coords.longitude,
        }, undefined, distance),
      // On gps position error
      (reason) => {
        if (!hasToast) {
          this._toastService.observableToast({
            duration: 6000,
            message: this._translateService.instant('COMPONENTS.MISSIONS.MAP.TOASTS.NO_GPS')
          });
        }
        this.loading$.next(false);
        // Repeat until user grands access to user location
        setTimeout(() => this.initUserPosition(true), 3000);
      }
    );
  }

  centerMap(location: google.maps.LatLngLiteral): void {
    this.map.setCenter(location);
  }

  findMissions(
    origin: google.maps.LatLngLiteral,
    filter?: FilterDto[],
    distance?: number | string | null,
    page: number = 0,
    hasAppendItems?: boolean
  ): void {
    // Init filter and show isLoading spinner
    const missionFilter: FilterDto[] = filter ?? this.missionFilter ?? [];
    this.loading$.next(true);

    // Reset map
    this.resetNearbyMissions(origin);
    this.currentOrigin = origin;

    // Load nearby missions
    this.nearbyMissions$ = this._missionByDistanceService
      .getMissionsByDistance(origin, missionFilter)
      .pipe(
        catchError((error) => {
          this._toastService.observableToast({
            message: this._translateService.instant('COMPONENTS.MISSIONS.MAP.TOASTS.NO_INTERNET')
          });
          return of({ items: [] } as any);
        }),
        map(foundMissions => {
          // A way to use paging, but currently it is set fixed to page size 100
          if (hasAppendItems) {
            this.missions = [...this.missions, ...foundMissions.items];
          } else {
            this.missions = foundMissions.items;
          }
          // Show toast if there are no missions
          if (this.missions.length === 0) {
            this._toastService.observableToast({
              message: this._translateService.instant('COMPONENTS.MISSIONS.MAP.TOASTS.NO_NEARBY_MISSIONS')
            });
          }
          // Map to local dto
          return this._generateNearbyMissions(origin, this.missions);
        }),
        // Stop isLoading, center and zoom map
        tap(() => {
          this.loading$.next(false);
          this.centerMap(origin);
          if (distance && distance != '') {
            const numDistance = Number(distance);
            if (numDistance <= 10) {
              this.map.setZoom(12);
            } else if (numDistance <= 25) {
              this.map.setZoom(11);
            } else if (numDistance <= 50) {
              this.map.setZoom(10);
            } else if (numDistance >= 500) {
              this.map.setZoom(6);
            } else if (numDistance >= 250) {
              this.map.setZoom(6);
            } else if (numDistance >= 100) {
              this.map.setZoom(7);
            }
          } else {
            this.map.setZoom(this.detailZoom);
          }

        })
      )
  }

  resetNearbyMissions(origin?: google.maps.LatLngLiteral): void {
    // initialize
    this.nearbyMissions$ = of({
      origin,
      nearby: []
    });
    this.currentOrigin = origin;
  }

  onMarkerClick(nearby: INearbyMissionsDto): void {
    this.currentNearbyMissionsForModalSubject.next(nearby);
    setTimeout(() => this.detailModal.present());
  }

  openMissionDetail(mission: MissionDistanceListDto): void {
    this._router.navigate(['tech/logged/inventory/mission', mission.missionId, 'view'])
      .then(() => this.detailModal.dismiss());
  }

  getResourceInfo(resource: ResourceInMissionDto): string {
    return `${
      resource.inventoryNumber ? resource.inventoryNumber.toString() + ' ' : ''
    }${ resource.name }`;
  }

  private _generateNearbyMissions(
    origin: PointListDto,
    missions: MissionDistanceListDto[]
  ): INearbyMissionMapDto {
    const nearbyMissionMap = { nearby: [] } as INearbyMissionMapDto;

    // Clone missions
    missions = cloneDeep(missions);

    // Place origin
    nearbyMissionMap.origin = origin as google.maps.LatLngLiteral;

    // Generate local mission map dto
    while (missions.length > 0) {
      const nearbyMissions: INearbyMissionsDto = {
        point: missions[0]?.shippingAddress?.point as google.maps.LatLngLiteral,
        location: this._getCurrentMarkerLocation(missions),
        missions: []
      };
      for (let j = 0; j < missions.length; j++) {
        if (
          isEqual(missions[j]?.shippingAddress?.point, nearbyMissions.point)
        ) {
          nearbyMissions?.missions?.push(missions[j]);
          missions.splice(j, 1);
          j--;
        }
      }
      nearbyMissionMap.nearby.push(nearbyMissions);
    }
    return nearbyMissionMap;
  }

  private _getCurrentMarkerLocation(missions: MissionDistanceListDto[]): { name: string; address: string } {
    // Address
    const address = missions[0]?.shippingAddress;
    // Location if available
    const location = missions.find((mission) => !!mission.location?.name)?.location;

    return {
      name: `${ location?.name ? location?.name : '' }`,
      address: `${ address?.street ?? '' } ${ address?.houseNumber ?? '' }, ${
        address?.zipcode ?? ''
      } ${ address?.city ?? '' }`
    };
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }
}
