import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {filter, firstValueFrom, Observable} from 'rxjs';
import {isObject} from 'lodash';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {
  IEntitySelectConfig
} from "@shared/components/input-entity-select/interfaces/input-entity-select-config.interface";
import {
  SharedInputEntitySelectApiService
} from "@shared/components/input-entity-select/services/shared-input-entity-select-api.service";
import {
  SharedInputEntitySelectOptionsModalComponent
} from "@shared/components/input-entity-select/components/options-modal/shared-input-entity-select-options-modal.component";
import {
  SharedInputEntitySelectObservableStore
} from "@shared/components/input-entity-select/observable-store/shared-input-entity-select.observable-store";

@Component({
  selector: 'app-shared-input-entity-select-item',
  templateUrl: './shared-input-entity-select-item.component.html',
  styleUrls: ['./shared-input-entity-select-item.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SharedInputEntitySelectItemComponent
    }
  ]
})
export class SharedInputEntitySelectItemComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() set value(value: Object | undefined) {
    this._setSelectedItem(value);
  }

  @Input() formControl: FormControl = new FormControl(undefined);
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() config?: IEntitySelectConfig;

  @Output() valueChange = new EventEmitter<Object>();

  items$!: Observable<any[]>;
  selected$!: Observable<any>;
  isLoading$!: Observable<boolean>;

  private _selectedItem?: Object;
  private _entityStore!: SharedInputEntitySelectObservableStore;

  constructor(
    private _modalCtrlService: SharedModalControllerService,
    private _apiService: SharedInputEntitySelectApiService) {
  }

  ngOnInit(): void {
    this._entityStore = new SharedInputEntitySelectObservableStore(
      this._apiService,
      this.config
    );
    this.isLoading$ = this._entityStore.selectLoading();
    this.items$ = this._entityStore.selectItems();
    this.selected$ = this._entityStore.selectCurrentItem();

    if (this._selectedItem) {
      this._entityStore.setCurrentItem(this._selectedItem);
    }
  }

  ngAfterViewInit(): void {
    this.formControl.updateValueAndValidity();
  }

  onSelect(item: Object): void {
    this._setSelectedItem(item, true);
  }

  onClick(): void {
    if (!this.readonly && !this.disabled) {
      this.disabled = true;
      this.formControl.updateValueAndValidity();
      this._entityStore.clearItems();
      this._entityStore.getFirstPage();
      // Wait for items to load until options modal opens
      firstValueFrom(
        this._entityStore.selectLoading().pipe(filter(loading => !loading))
      ).then(() => {
        this.openOptionsModal();
      });
    }
  }

  openOptionsModal(): void {
    firstValueFrom(
      this._modalCtrlService.showModal(SharedInputEntitySelectOptionsModalComponent, '', {
        entityStore: this._entityStore,
        idKey: this.config?.idKey ?? '',
        displayKey: this.config?.displayKey ?? '',
        label: this.label,
        items$: this.items$,
        isLoading$: this.isLoading$,
        selectFn: (item: Object) => this.onSelect(item)
      })
    ).then(() => this.disabled = false);
  }

  onTouch: any = () => {
  };
  onChange: any = () => {
  };

  writeValue(value?: Object): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private _setSelectedItem(item?: Object, emit: boolean = false) {
    if (isObject(item)) {
      const key = this.config?.displayKey;
      const display = key ? (item as any)[key] : '';
      this.formControl.setValue(display);
      this._selectedItem = item;
    } else {
      this.formControl.setValue(undefined);
      this._selectedItem = undefined;
    }

    if (emit) {
      this.valueChange.emit(this._selectedItem);
    }
    this.formControl.updateValueAndValidity();
    this._entityStore?.setCurrentItem(this._selectedItem);
  }

  protected readonly Boolean = Boolean;
}
