import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TechLoginState} from "./tech-login.state";

export const selectLogin = createFeatureSelector<TechLoginState>('login');

export const selectCurrentApp = createSelector(
    selectLogin,
    (state: TechLoginState) => state.currentApp
);

export const selectIsLogging = createSelector(
  selectLogin,
  (state: TechLoginState) => state.isLogging
);

export const selectIsLogged = createSelector(
  selectLogin,
  (state: TechLoginState) => state.isLogged
);
