import {environment} from "@env-tech/environment";
import {KnowledgeState} from "@tech/pages/knowledge/store/knowledge.state";
import {createReducer, on} from "@ngrx/store";
import {KnowledgeActions} from "./knowledge.actions-type";
import {initialDocumentsState} from "@tech/pages/documents/store/documents.reducers";

export const initialKnowledgeState: KnowledgeState = {
  page: {},
  items: [],
  itemsStart: {},
  articlePage: {},
  paging: {
    totalItems: 0,
    pageNumber: 0,
    pageSize: environment?.apiUrl?.pageDefaultSize || 1,
    totalPages: 1
  },
  isLoading: true
};

export const techKnowledgeStore = createReducer(
  initialKnowledgeState,
  on(KnowledgeActions.getPathItemsPaged, (state) => ({
    ...state,
    isLoading: true
  })),
  on(KnowledgeActions.getPathItemsPagedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(KnowledgeActions.getPathItemsPagedSuccess, (state, {data}) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(KnowledgeActions.getPathItemsPagedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(KnowledgeActions.getPathItemsPagedRefresh, (state, {data}) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(KnowledgeActions.getPathItemsByIdStart, (state) => ({
    ...state,
    isLoading: true
  })),
  on(KnowledgeActions.getPathItemsByIdStartSuccess, (state, {data}) => ({
    ...state,
    itemsStart: data,
    isLoading: false
  })),
  on(KnowledgeActions.getPathItemsByIdStartFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(KnowledgeActions.getPathItemsByIdStartRefresh, (state, {data}) => ({
    ...state,
    itemsStart: data,
    isLoading: false
  })),
  on(KnowledgeActions.getItemsSearched, (state) => ({
    ...state,

    paging: {
      totalItems: initialDocumentsState.paging.totalItems,
      pageNumber: initialDocumentsState.paging.pageNumber,
      pageSize: initialDocumentsState.paging.pageSize,
      totalPages: initialDocumentsState.paging.totalPages,
    },
    isLoading: true
  })),
  on(KnowledgeActions.getItemsSearchedSuccess, (state, {data}) =>
    ({
      ...state,
      items: [...data.items],
      paging: data.paging,
      isLoading: false
    })
  ),
  on(KnowledgeActions.getItemsSearchedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(KnowledgeActions.getArticlePage, (state) => ({
    ...state,
    isLoading: true
  })),
  on(KnowledgeActions.getArticlePageSuccess, (state, {data}) => ({
    ...state,
    articlePage: data,
    isLoading: false
  })),
  on(KnowledgeActions.getArticlePageFail, (state) => ({
    ...state,
    isLoading: false
  })),
)
