import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ContactSimpleDto} from '@server-models';
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class TechAssignEditorApiService extends CoreHttpClientApiBaseService {

  assignMe(missionId: number): Observable<ContactSimpleDto> {
    return super.post<ContactSimpleDto>(`/tech/mission/${ missionId }/editor/me/assign`);
  }

  assignContact(missionId: number, contactId: number): Observable<void> {
    return super.post(`/tech/mission/${ missionId }/editor/${ contactId }/assign`);
  }

  unassignEditor(missionId: number): Observable<void> {
    return super.post(`/tech/mission/${ missionId }/editor/unassign`);
  }
}
