import {Injectable} from "@angular/core";
import {MessageIssueDto, MessageIssuePreviewDto, SubmitMessageIssueDto} from "@server-models";
import {Observable} from "rxjs";
import {TechIssueApiBaseService} from "@tech/pages/issues/services/tech-issue-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class TechIssueMessageApiService extends TechIssueApiBaseService {

  override getIssueTemplatePreviewListById(id: number): Observable<MessageIssuePreviewDto> {
    return super.get<MessageIssuePreviewDto>(`/tech/v2/issue/message/template/${ id }/preview`, undefined);
  }

  override getIssueById(issueId: number): Observable<MessageIssueDto> {
    return super.get<MessageIssueDto>(`/tech/v2/issue/message/${ issueId }`);
  }

  override sendIssueTemplatePreviewById(templateId: number, issue: SubmitMessageIssueDto): Observable<void> {
    return super.post<void>(`/tech/v2/issue/message/template/${ templateId }/submitIssue`, undefined, issue)
  }

}
