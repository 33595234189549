import {Injectable} from '@angular/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';

import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {TechLoginNewPasswordDialogComponent} from '@tech/pages/login/components/new-password-dialog/tech-login-new-password-dialog.component';
import {Router} from '@angular/router';
import {AlertBaseControllerService} from '@shared/services/shared-alert-controller.service';
import {MeDto} from '@server-models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SharedLoginStorageBaseService} from '@shared/stores/login-base/services/shared-login-storage-base.service';
import {TechLoginPinRegisterUserDialogComponent} from '@tech/pages/login/components/pin-register-user-dialog/tech-login-pin-register-user-dialog.component';
import {TechLoginState} from '@tech/pages/login/store/tech-login.state';
import {TechLoginActions} from '@tech/pages/login/store/tech-login.actions-type';
import {SharedTenantSelectionModalComponent} from '@shared/components/tenant-selection-modal/shared-tenant-selection-modal.component';
import {
  TechLoginRequestRegisterUserDialogComponent
} from "@tech/pages/login/components/request-register/components/user-dialog/tech-login-request-register-user-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class TechLoginSideEffectsService {
  constructor(
    private _store: Store<TechLoginState>,
    private _loginBaseStorageService: SharedLoginStorageBaseService,
    private _alertControllerService: AlertBaseControllerService,
    private _modalControllerService: SharedModalControllerService,
    private _translateService: TranslateService,
    private _router: Router
  ) {
  }

  /**
   * @name hasMultipleTenants
   * @description
   * checks if there are multiple tenants
   * @memberof TechLoginSideEffectsService
   * @param tokenInfo
   * @returns {boolean}
   */
  hasMultipleTenants(tokenInfo: MeDto): boolean {
    const tenants = tokenInfo?.tenants;
    return tenants?.length! > 1;
  }

  /**
   * @name getTenants
   * @description
   * display modal if not tenantId existing else dispatch already existing tenant
   * @memberof TechLoginSideEffectsService
   * @param tokenInfo
   */
  getTenants(tokenInfo: MeDto): void {
    this._checkExistingTenants().subscribe((tenantId) => {

      if (!(!!tenantId)) {
        this._modalControllerService.showModal(SharedTenantSelectionModalComponent, '', {
          data: tokenInfo.tenants
        });
      } else {
        this._store.dispatch(TechLoginActions.existingMultiTenant({ tenantId: tenantId! }))
      }
    });
  }

  /**
   * @name _checkExistingTenants
   * @description
   * returns tenantId if exists
   * @memberof TechLoginSideEffectsService
   * @private
   * @returns {Observable<number | undefined>}
   */
  private _checkExistingTenants(): Observable<number | undefined> {
    return this._loginBaseStorageService.getSt().pipe(
      map((value) => value?.currentTenant?.tenantId)
    );
  }

  askRegistrationData(currentEmail: string) {
    this._modalControllerService.showModal(TechLoginRequestRegisterUserDialogComponent, '', {
      currentEmail
    });
  }

  askRegistrationPinGuest(passwordBase64: string, email: string) {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(TechLoginPinRegisterUserDialogComponent, '', {
      passwordBase64,
      email
    });
  }

  /**
   * @name newPasswordDialog
   * @description
   * display a modal of new password
   * @memberof TechLoginSideEffectsService
   * @param token
   * @param email
   */
  newPasswordDialog(token: string, email: string): void {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(TechLoginNewPasswordDialogComponent, '', {
      newPasswordData: {
        token,
        email
      }
    });
  }

  /**
   * @name launchAppByPassword
   * @description
   * dispatch an action login by password
   * @memberof TechLoginSideEffectsService
   * @param email
   * @param password
   */
  launchAppByPassword(email: string, password: string): void {
    this._store.dispatch(TechLoginActions.byPassword({ login: email, password: password }));
  }

  /**
   * @name resetRequestPasswordAlert
   * @description
   * display an alert to info the user that the email have sent successfully
   * @memberof TechLoginSideEffectsService
   */
  resetRequestPasswordAlert(): void {
    this._alertControllerService.observableAlert({
      header: this._translateService.instant('LOGIN.RESET_PASSWORD.ALERT.HEADER'),
      subHeader: this._translateService.instant('LOGIN.RESET_PASSWORD.ALERT.SUB_HEADER'),
      message: this._translateService.instant('LOGIN.RESET_PASSWORD.ALERT.MESSAGE'),
      buttons: [
        {
          text: this._translateService.instant('LOGIN.RESET_PASSWORD.ALERT.BUTTONS.ACCEPT'),
          handler: () => {
            this._router.navigateByUrl("tech/login");
          }
        }
      ],
    })
  }
}
