import {Injectable} from '@angular/core';
import {AppEntityType, FilterDto, GroupedFilterDto} from '@server-models';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  MenuTreeFilterInterface,
  MenuTreeInterface,
  MenuTreeParamInterface
} from "@tech/pages/inventory/interfaces/menu-tree-filter.interface";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {TreeMenuSegmentEnum} from "@tech/pages/inventory/enums/tree-menu-segment.enum";

@Injectable({
  providedIn: 'root',
})
export class MenuTreeFilterService {
  filters$: Observable<FilterDto[]>;
  params$: Observable<PaginationParams>;
  request$: Observable<{ param: PaginationParams; filter: FilterDto[], refresh: boolean }>

  paramSettings$: BehaviorSubject<MenuTreeParamInterface> =
    new BehaviorSubject<MenuTreeParamInterface>({
      segment: TreeMenuSegmentEnum.Type
    });
  filterSettings$: BehaviorSubject<MenuTreeFilterInterface> =
    new BehaviorSubject<MenuTreeFilterInterface>({
      stereotype: undefined,
      resource: undefined,
    });
  requestSettings$: BehaviorSubject<MenuTreeInterface> =
    new BehaviorSubject<MenuTreeInterface>({
      param: {segment: TreeMenuSegmentEnum.Type},
      filter: undefined,
      refresh: false
    });

  constructor() {
    this.params$ = this.paramSettings$.pipe(
      map((paramSettings) => this._createParams(paramSettings))
    );
    this.filters$ = this.filterSettings$.pipe(
      map((filterSettings) => this._createFilters(filterSettings))
    );
    this.request$ = this.requestSettings$.pipe(
      map((requestSettings) => this._createRequest(this._createParams(requestSettings.param!), this._createFilters(requestSettings.filter!), requestSettings.refresh!))
    );
  }

  private _createRequest(param: PaginationParams, filter: FilterDto[], refresh: boolean) {
    return {param, filter, refresh}
  }

  private _createParams(paramSettings: MenuTreeParamInterface) {
    let params;
    switch (paramSettings.segment) {
      case TreeMenuSegmentEnum.Type: {
        params = {
          pageNumber: 1,
          pageSize: 30,
          cols: 'Content',
          entityType: AppEntityType.Resource,
          archived: 'false'
        };
        break;
      }
      case TreeMenuSegmentEnum.Location: {
        params = {
          pageNumber: 1,
          pageSize: 30,
          cols: 'Content',
          archived: 'false'
        };
        break;
      }
      case TreeMenuSegmentEnum.Model: {
        params = {
          pageNumber: 1,
          pageSize: 30,
          cols: 'Content',
          archived: 'false'
        };
        break;
      }
      default: {
        params = {
          pageNumber: 1,
          pageSize: 30,
          cols: 'Content',
          entityType: AppEntityType.Resource,
          archived: 'false'
        };
        break;
      }
    }
    return params;
  }

  /**
   * @name _createFilters
   * @description
   * hold the filter by this.filter and works adding or removing need it on demand
   * @memberof MenuTreeFilterService
   * @param filterSettings
   */
  private _createFilters(
    filterSettings: MenuTreeFilterInterface
  ): FilterDto[] {
    const filters: (FilterDto | GroupedFilterDto)[] = [];



    return filters;
  }
}

