import {Component, Input, OnInit} from '@angular/core';
import {InfoDto, InfoListDto} from "@server-models";
import {CommonModule, DatePipe} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {postItemsMyRead} from "@tech/pages/infos/store/infos.actions";
import {Store} from "@ngrx/store";
import {InfoCardButtonsComponent} from "@tech/pages/infos/components/info-card-buttons/info-card-buttons.component";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe, InfoCardButtonsComponent, UserHtmlComponent, AttachmentsComponent],
  providers: [
    DatePipe,
  ]

})
export class InfoCardComponent implements OnInit {

  @Input() info: InfoListDto;

  constructor(private _datePipe: DatePipe, private _store: Store) {
    this.info = {};
  }

  ngOnInit() {
  }

  /**
   * @name formatCardSubtitle
   * @memberof InfoCardComponent
   * @description
   * Function to display the card subtitle with a specific format
   * @param info
   * @return {string}
   */
  formatCardSubtitle(info: InfoDto): string {
    const preCard: string = info.sender?.name ? `${info.sender.name}` : '';
    const postCard: string = info?.dateTime ? `, ${this._datePipe.transform(info?.dateTime, "dd.MM hh:mm")}` : ``;
    return `${preCard}${postCard}`;
  }

  /**
   * @name read
   * @memberof InfoCardComponent
   * @description
   * Dispatch the action read from the store
   * @param infoId
   */
  read(infoId: number | undefined) {
    this._store.dispatch((postItemsMyRead({id: infoId as number})));
  }

}
