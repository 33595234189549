import {Component} from '@angular/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {InfiniteScrollCustomEvent, IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FilterDto, FilterOperators, FilterTypes} from '@server-models';
import {Observable} from 'rxjs';
import {SharedSearchBarComponent} from '@shared/components/search-bar/shared-search-bar.component';
import {
  SharedInputEntitySelectObservableStore
} from "@shared/components/input-entity-select/observable-store/shared-input-entity-select.observable-store";

@Component({
  selector: 'app-shared-input-entity-select-options-modal',
  templateUrl: './shared-input-entity-select-options-modal.component.html',
  styleUrls: ['./shared-input-entity-select-options-modal.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    SharedSearchBarComponent,
  ],
  standalone: true,
})
export class SharedInputEntitySelectOptionsModalComponent {
  items$!: Observable<Object[]>;
  isLoading$!: Observable<boolean>;

  entityStore!: SharedInputEntitySelectObservableStore;
  displayKey!: string;
  idKey!: string;
  label!: string;
  selectFn!: (item: Object) => void;
  searchFilter?: FilterDto;

  constructor(private _modalCtrlService: SharedModalControllerService) {
  }

  onSearch(searchTerm: string): void {
    this.searchFilter = {
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Contains,
      property: this.displayKey,
      value: searchTerm,
    };
    this.entityStore.getFirstPage([this.searchFilter]);
  }

  onLoadMoreInfinite(event: InfiniteScrollCustomEvent): void {
    this.entityStore
      .appendPage(this.searchFilter ? [this.searchFilter] : [])
      .then(async (paging) => {
        if (paging.totalPages !== paging.pageNumber) {
          // Complete current call
          await event.target.complete();
        } else {
          // Disable infinite scroll
          event.target.disabled = true;
        }
      });
  }

  onSelectItem(item: any): void {
    this.selectFn(item);
    this.onClose();
  }

  onClear(): void {
    this.entityStore.getFirstPage();
  }

  onClose(): void {
    this._modalCtrlService.closeModal();
  }

  getDisplayName(item: any): string {
    return item[this.displayKey];
  }

  trackByFn(item: any): number {
    return item[this.idKey];
  }

  protected readonly Boolean = Boolean;
}
