import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TechInfoState} from "@tech/pages/infos/store/tech-info.state";

export const techInfoStoreSelect = createFeatureSelector<TechInfoState>('techInfos');

export const selectInfoPage = createSelector(
  techInfoStoreSelect,
  (state: TechInfoState) => state.page
);

export const selectInfoPageList = createSelector(
  techInfoStoreSelect,
  (state: TechInfoState) => state.items
);

export const selectInfoPagePagination = createSelector(
  techInfoStoreSelect,
  (state: TechInfoState) => state.paging
);

export const selectInfoPageLoading = createSelector(
  techInfoStoreSelect,
  (state: TechInfoState) => state.isLoading
);

export const selectInfoPageLoadingId = createSelector(
  techInfoStoreSelect,
  (state: TechInfoState) => state.isLoadingId
);
