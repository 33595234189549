<ion-card class="settings">
  <ion-select
    [placeholder]="'COMPONENTS.MISSIONS.RESOURCE.PLACEHOLDER' | translate"
    interface="popover"
    [(ngModel)]="currentSelectedObject.stereotype"
    (ionChange)="changeStereotypeMode($event)">
    <ion-select-option *ngIf="stereotypes.length > 0" [value]="''">
      {{ 'COMPONENTS.MISSIONS.RESOURCE.ALL' | translate }}
    </ion-select-option>
    <ion-select-option *ngFor="let stereotype of stereotypes" [value]="stereotype.stereotypeId">
      {{ stereotype.name }}
    </ion-select-option>
  </ion-select>

  <ion-select
    [placeholder]="'COMPONENTS.MISSIONS.STATE.PLACEHOLDER' | translate"
    interface="popover"
    [(ngModel)]="currentSelectedObject.state"
    (ionChange)="changeStatusMode($event)">
    <ion-select-option [value]="''">
      {{ 'COMPONENTS.MISSIONS.STATE.ALL' | translate }}
    </ion-select-option>
    <ion-select-option [value]="MissionState.Instructed">
      {{ 'COMPONENTS.MISSIONS.STATE.INSTRUCTED' | translate }}
    </ion-select-option>
    <ion-select-option [value]="MissionState.Created">
      {{ 'COMPONENTS.MISSIONS.STATE.CREATED' | translate }}
    </ion-select-option>
  </ion-select>

  <div class="icon-container" (click)="clearSettingsFilters()">
    <ion-icon size="large" name="close-outline"></ion-icon>
  </div>

</ion-card>
