import {Component, Input, OnInit} from '@angular/core';
import {KnowledgeArticleListDto, KnowledgePathListDto} from "@server-models";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-tech-knowledge-card',
  templateUrl: './tech-knowledge-card.component.html',
  styleUrls: ['./tech-knowledge-card.component.scss'],
  imports: [
    AsyncPipe,
    IonicModule,
    NgForOf,
    NgIf,
    TranslateModule,
    RouterLink
  ],
  standalone: true
})
export class TechKnowledgeCardComponent implements OnInit {

  @Input() knowledgeList: (KnowledgePathListDto | KnowledgeArticleListDto)[] | null;

  constructor() {
    this.knowledgeList = [];
  }

  ngOnInit() {
  }

  isKnowledgePathListDto(item: any): item is KnowledgePathListDto {
    return 'knowledgePathId' in item;
  }

  isKnowledgeArticleListDto(item: any): item is KnowledgeArticleListDto {
    return 'knowledgeArticleId' in item;
  }

}
