import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {SharedShowHidePasswordComponent} from "@shared/components/show-hide-password/shared-show-hide-password.component";
import {SharedCustomPropertyFormInputTextComponent} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {select, Store} from "@ngrx/store";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";

@Component({
  selector: 'app-tech-login-card',
  templateUrl: './tech-login-card.component.html',
  styleUrls: ['./tech-login-card.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedShowHidePasswordComponent,
    SharedCustomPropertyFormInputTextComponent
  ],
  standalone: true
})
export class TechLoginCardComponent implements OnInit {

  @Output() toReset: EventEmitter<any>;
  @Output() userSubmit: EventEmitter<{ input: { username: string, password: string } }>;
  @Output() formChangeEvent: EventEmitter<{ input: { username: string } }>;
  @Output() toRegisterUser: EventEmitter<string>;

  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;
  loginForm: FormGroup;
  buttonId: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store
  ) {
    this.toReset = new EventEmitter<any>();
    this.buttonId = "registerUserId";
    this.isLoading$ = this._store.pipe(select(TechLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TechLoginSelectors.selectIsLoadingId));
    this.userSubmit = new EventEmitter<{ input: { username: string, password: string } }>();
    this.formChangeEvent = new EventEmitter<{ input: { username: string } }>();
    this.toRegisterUser = new EventEmitter<string>();
    this.loginForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['']
    });
  }

  getControl() {
    return this.loginForm.get('username') as FormControl;
  }

  ngOnInit() {

  }

  /**
   * @name userInput
   * @description
   * user input emit
   * @memberof TechLoginCardComponent
   */
  userInput(): void {
    this.userSubmit.emit({
      input: {
        username: this.loginForm.get('username')?.value,
        password: this.loginForm.get('password')?.value
      }
    })
  }

  formChange() {
    this.formChangeEvent.emit({
      input: {
        username: this.loginForm.get('username')?.value,
      }
    })
  }

  /**
   * @name gotoReset
   * @description
   * emit a value to let the parent change the view and go to reset
   * @memberof TechLoginCardComponent
   */
  gotoReset(): void {
    this.toReset.emit();
  }


  registerUser() {
    this.toRegisterUser.emit(this.buttonId);
  }

  checkLoadingButton(buttonId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (this.buttonId! === id) && isLoading)
    );
  }

}
