import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {MissionCompleteDto, MissionState} from "@server-models";
import {CoreClockService} from "@core/services/core-clock.service";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-inventory.actions-type";
import {
  SharedIssueCustomReadOnlyMissionStateOptionsComponent
} from "@shared/components/issues/pages/read-only/components/mission/components/mission-state-options/shared-issue-custom-read-only-mission-state-options.component";
import {EMissionDisplayMode} from "@tech/pages/inventory/enums/mission-display-mode.enum";

@Component({
  selector: 'app-tech-inventory-mission-list',
  templateUrl: './tech-inventory-mission-list.component.html',
  styleUrls: ['./tech-inventory-mission-list.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedIssueCustomReadOnlyMissionStateOptionsComponent],

})
export class TechInventoryMissionListComponent implements OnInit {

  @Input() missionList: MissionCompleteDto[];
  @Input() displayType: EMissionDisplayMode = EMissionDisplayMode.LIST;
  protected readonly MissionState = MissionState;

  constructor(
    public clockService: CoreClockService,
    private _router: Router,
    private _store: Store
  ) {
    this.missionList = [];
  }

  ngOnInit() {
  }

  formatDates(start: string | undefined, end: string | null | undefined): string {
    if (!start) return '';

    const startDate = this.clockService.formatDateWithWeekday(start, false, true);
    const endDate = end ? this.clockService.formatDateWithWeekday(end, false, true) : '';

    return endDate ? `${ startDate } - ${ endDate }` : startDate;
  }

  navigateToDetail(mission: MissionCompleteDto) {
    this._store.dispatch(TechInventoryActions.navigateToMissionDetail(mission));
  }
}
