import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  ArticleListDto,
  ChangeStateOfMissionDto,
  FilterDto, LocationListDto,
  MissionCompleteDto,
  MissionDto, MissionReportDto, MissionReportPreviewDto,
  MissionState, ResourceModelListDto
} from "@server-models";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {map} from "rxjs/operators";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {IRequestOptions} from "@shared/interfaces/request-options.interface";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryApiService extends CoreHttpClientApiBaseService {


  pagePostMissionPaginated(paramsPaginated: IPaginationParams,
                           body: FilterDto[]): Observable<IOrgaResponse<MissionCompleteDto[]>> {
    const options: IRequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        SortField: paramsPaginated.sortField as string,
        Sort: paramsPaginated.sort!.toString()
      }
    }
    return super.post<IOrgaResponse<MissionCompleteDto[]>>(`/tech/mission/filter`,
      undefined, body, options).pipe(
      map((res: IOrgaResponse<MissionCompleteDto[]>) => res)
    );
  }

  getMissionDetailById(id: string): Observable<MissionDto> {
    return super.get<MissionDto>(`/tech/mission/${ id }`, undefined);
  }

  postChangeMissionStateById(id: number, newState: MissionState): Observable<void> {
    const body: ChangeStateOfMissionDto = {
      missionId: id,
      wantedState: newState
    };
    return super.post<void>(`/tech/mission/${ id }/changeState`, undefined, body);
  }

  postLocationFilter(params: IPaginationParams, body: any): Observable<IOrgaResponse<LocationListDto[]>> {
    const options: IRequestOptions = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols
      }
    }
    return super.post<IOrgaResponse<LocationListDto[]>>(`/tech/location/filter`, undefined, body, options);
  }

  postModelFilter(params: IPaginationParams, body: any): Observable<IOrgaResponse<ResourceModelListDto[]>> {
    const options: IRequestOptions = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        ...(params.sortField && { sortField: params.sortField.toString() }),
        ...(params.sort !== undefined && params.sort !== null && { sort: params.sort.toString() })
      }
    }
    return super.post<IOrgaResponse<ResourceModelListDto[]>>(`/tech/v2/model/filter`, undefined, body, options);
  }

  getMissionReportById(reportId: string): Observable<MissionReportDto> {
    return super.get<MissionReportDto>(`/tech/missionreport/${ reportId }`, undefined);
  }

  postSubmitMissionReport(missionReport: MissionReportDto): Observable<MissionReportDto> {
    return super.post<MissionReportDto>(`/tech/missionreport`, undefined, missionReport);
  }

  putSubmitMissionReportById(missionReport: MissionReportDto): Observable<MissionReportDto> {
    return super.put<MissionReportDto>(`/tech/missionreport/${ missionReport.missionReportId }`, undefined, missionReport);
  }

  getMissionReportPreviewById(missionId: string): Observable<MissionReportPreviewDto> {
    return super.get<MissionReportPreviewDto>(`/tech/mission/${ missionId }/report/preview`, undefined);
  }

  postMissionReportArticleFilter(body: any): Observable<IOrgaResponse<ArticleListDto[]>> {
    const options: IRequestOptions = {
      params: {
        PageSize: '500',
        PageNumber: '1',
        Cols: 'Content',
        SortField: 'name',
        Sort: '1'
      }
    }
    return super.post<IOrgaResponse<ArticleListDto[]>>(`/tech/article/filter`, undefined, body, options);
  }


}
