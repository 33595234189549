import {Injectable} from "@angular/core";
import {Clients} from "@server-models";
import {HttpClientBaseApiService} from "@core/services/http-client-base-api/http-client-base-api.service";
import {environment} from "@env-tech/environment";

@Injectable({
  providedIn: 'root'
})
export abstract class TechHttpClientBaseApiService extends HttpClientBaseApiService {
  override clientType = Clients.Tech;
  override _apiUrlOne = environment.apiUrl.one;
}
