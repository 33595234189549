import {Component, Input, OnInit} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {postItemsMyRead} from "@tech/pages/infos/store/infos.actions";
import {select, Store} from "@ngrx/store";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {InfoListDto} from "@server-models";
import {selectInfoPageLoading, selectInfoPageLoadingId} from "@tech/pages/infos/store/infos.selectors";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-info-card-buttons',
  templateUrl: './info-card-buttons.component.html',
  styleUrls: ['./info-card-buttons.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    CommonModule
  ],
  standalone: true
})
export class InfoCardButtonsComponent  implements OnInit {

  @Input() info: InfoListDto;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | undefined>;

  constructor(private _store: Store) {
    this.info = {};
    this.isLoading$ = this._store.pipe(select(selectInfoPageLoading));
    this.isLoadingId$ = this._store.pipe(select(selectInfoPageLoadingId));
  }

  ngOnInit() {
  }

  /**
   * @name read
   * @memberof InfoCardButtonsComponent
   * @description
   * Dispatch the action to send the backend that an info has been read
   * @param infoId
   */
  read(infoId: number | undefined): void {
      this._store.dispatch(postItemsMyRead({ id: infoId as number }));
  }

  /**
   * @name checkLoadingButton
   * @memberof InfoCardButtonsComponent
   * @description
   * Check if it is loading base on an id not load all buttons at the same time
   * @param buttonId
   * @return {Observable<boolean>}
   */
  checkLoadingButton(buttonId: number | undefined): Observable<boolean> {
    return this.isLoadingId$.pipe(
      map((id) => !!id && buttonId! === id)
    );
  }

}
