import {environment} from "@env-tech/environment";
import {createReducer, on} from "@ngrx/store";
import {DocumentsActions} from "@tech/pages/documents/store/documents.actions-type";
import {DocumentsState} from "@tech/pages/documents/store/documents.state";

export const initialDocumentsState: DocumentsState = {
  page: {},
  items: [],
  paging: {
    totalItems: 0,
    pageNumber: 0,
    pageSize: environment?.apiUrl?.pageDefaultSize || 1,
    totalPages: 1
  },
  isLoading: true,
  isLoadingId: NaN
};

export const techDocumentsStore = createReducer(
  initialDocumentsState,
  on(DocumentsActions.getItemsPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(DocumentsActions.getItemsPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(DocumentsActions.getItemsPaginatedSuccess, (state, {data}) =>
    ({
      ...state,
      // Important! include the current state.items and the data.items to create a new array with the
      // existing ones and the new one that is requested
      items: [...state.items, ...data.items],
      paging: data.paging,
      isLoading: false
    })
  ),
  on(DocumentsActions.getItemsPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(DocumentsActions.getItemsPaginatedRefresh, (state, {data}) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(DocumentsActions.getItemsSearched, (state) => ({
    ...state,

    paging: {
      totalItems: initialDocumentsState.paging.totalItems,
      pageNumber: initialDocumentsState.paging.pageNumber,
      pageSize: initialDocumentsState.paging.pageSize,
      totalPages: initialDocumentsState.paging.totalPages,
    },
    isLoading: true
  })),
  on(DocumentsActions.getItemsSearchedSuccess, (state, {data}) =>
    ({
      ...state,
      items: [...data.items],
      paging: data.paging,
      isLoading: false
    })
  ),
  on(DocumentsActions.getItemsSearchedFail, (state) => ({
    ...state,
    isLoading: false
  })),
);
