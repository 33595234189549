import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {CommonModule, CurrencyPipe, DecimalPipe} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {SharedCustomPropertyFormInputDropdownComponent} from '@shared/components/custom-property-form/components/input/components/dropdown/shared-custom-property-form-input-dropdown.component';

export enum EDecimalInputTypes {
  Currency = 0,
  Decimal = 1,
}

@Component({
  selector: 'app-tech-inventory-mission-detail-report-detail-decimal-input',
  templateUrl:
    './tech-inventory-mission-detail-report-decimal-input.component.html',
  styleUrls: [
    './tech-inventory-mission-detail-report-decimal-input.component.scss',
  ],
  standalone: true,
  providers: [
    CurrencyPipe,
    DecimalPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => TechInventoryMissionDetailReportDecimalInputComponent
      ),
      multi: true,
    },
  ],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedCustomPropertyFormInputDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class TechInventoryMissionDetailReportDecimalInputComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('input') input!: { setFocus: () => void };

  @Input() label!: string;
  @Input() labelPlacement!: string;
  @Input() unit: string | undefined;
  @Input() isDisabled: boolean = false;
  @Input() control: AbstractControl | null = null;
  @Input() inputType: EDecimalInputTypes = EDecimalInputTypes.Currency;

  @Input() set value(value: number | string | null | undefined) {
    value = Number((value ?? 0).toString().replace(',', '.'));

    if (!isNaN(value)) {
      this.rawValue = value;
      this.control?.setValue(value);
      this.formattedValue =
        this.inputType === EDecimalInputTypes.Currency
          ? this.formatCurrency(this.rawValue.toString())
          : this.formatDecimal(this.rawValue.toString());
    }
  }

  get value(): number {
    return this.rawValue;
  }

  rawValue: number = 0;
  formattedValue: string = '';

  isInput: boolean = true;

  constructor(
    private _currencyPipe: CurrencyPipe,
    private _decimalPipe: DecimalPipe,
    private _translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.isInput = this.formattedValue === '' && !this.isDisabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.value = this.rawValue ?? 0;
  }

  formatCurrency(value: string): string {
    return (
      this._currencyPipe.transform(
        value,
        'EUR',
        true,
        '1.2',
        this._translateService.currentLang
      ) ?? ''
    );
  }

  formatDecimal(value: string): string {
    return (
      (this._decimalPipe.transform(
        (+value).toFixed(2),
        '1.2',
        this._translateService.currentLang
      ) ?? '') + `${ this.unit ? ' ' + this.unit : '' }`
    );
  }

  switchToInput(): void {
    this.isInput = true;
    setTimeout(() => this.input.setFocus());
  }

  onInput(event: CustomEvent): void {
    this.value = event.detail.value ?? 0;
    this.input.setFocus();
  }

  switchToPipeValue(): void {
    this.rawValue = +this.rawValue.toFixed(2);
    this.isInput = false;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = () => fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
