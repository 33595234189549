import {Component, Input, OnInit} from '@angular/core';
import {IonicModule, MenuController} from "@ionic/angular";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-button-tech-side-menu',
  templateUrl: './button-tech-side-menu.component.html',
  styleUrls: ['./button-tech-side-menu.component.scss'],
  imports: [
    CommonModule,
    IonicModule
  ],
  standalone: true
})
export class ButtonTechSideMenuComponent implements OnInit {

  @Input() title!: string;
  @Input() showMenu!: boolean;

  constructor(
    private _menuCtrl: MenuController
  ) {
    this.showMenu = true;
  }

  ngOnInit() {

  }

  /**
   * @name openTechMenu
   * @description
   * Use the menu controller to open the tech-menu
   * @memberof ButtonTechSideMenuComponent
   */
  openTechMenu() {
    this._menuCtrl.open('tech-menu')
  }

}
