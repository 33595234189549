import {Component, Input, OnInit} from '@angular/core';
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {PinInputComponent} from "@shared/components/pin-input/pin-input.component";
import {select, Store} from "@ngrx/store";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {
  RegisterUserFormComponent
} from "@tech/pages/login/components/request-register-user-dialog/components/register-user-form.component";
import {TechLoginState} from "@tech/pages/login/store/tech-login.state";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";
import {
  DispatchRegisterGuest
} from "@tech/pages/login/components/request-register-user-dialog/interfaces/dispatch-register-guest.interface";

@Component({
  selector: 'app-request-register-user-dialog',
  templateUrl: './request-register-user-dialog.component.html',
  styleUrls: ['./request-register-user-dialog.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SafePipe,
    PinInputComponent,
    UserHtmlComponent,
    RegisterUserFormComponent
  ],
  standalone: true
})
export class RequestRegisterUserDialogComponent implements OnInit {

  @Input() currentEmail: string;

  registerUserShadowForm: FormGroup;
  isSubmitDisabled: boolean;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;
  buttonId: string;

  constructor(
    private _modalControllerService: ModalControllerService,
    private _translationService: TranslateService,
    private _store: Store<TechLoginState>
  ) {
    this.registerUserShadowForm = new FormGroup({});
    this.isSubmitDisabled = true;
    this.isLoading$ = of(false);
    this.isLoadingId$ = of(NaN);
    this.buttonId = "submitRegister"
    this.currentEmail = "";
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TechLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TechLoginSelectors.selectIsLoadingId));
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof RequestRegisterUserDialogComponent
   */
  exit(): void {
    this._store.dispatch(TechLoginActions.initRegistrationGuestDialogClose());
    this._modalControllerService.closeModal();
  }

  /**
   * @name submit
   * @description
   * dispatch the action on submit to complete the invitation
   * @memberof RequestRegisterUserDialogComponent
   */
  submit(): void {
    const password = this.registerUserShadowForm.value.password;
    const dispatchData: DispatchRegisterGuest = {
      password: password,
      passwordBase64: btoa(password),
      confirmPassword: this.registerUserShadowForm.value.confirmPassword,
      email: this.registerUserShadowForm.value.email
    };

    if (dispatchData.password === dispatchData.confirmPassword) {
      this.dispatchRegisterGuestPinRequest(dispatchData);
    }
  }

  /**
   * @name checkLoadingButton
   * @description
   * check loading by id
   * @memberof RequestRegisterUserDialogComponent
   * @param buttonId
   */
  checkLoadingButton(buttonId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (buttonId! === id) && isLoading)
    );
  }

  /**
   * @name onFormChange
   * @description
   * handle emit event to update the shadow form
   * @memberof RequestRegisterUserDialogComponent
   * @param form
   */
  onFormChange(form: FormGroup): void {
    this.registerUserShadowForm = form;
  }

  /**
   * @name dispatchRegisterGuestPinRequest
   * @description
   * dispatch the register guest pin request action
   * @memberof RequestRegisterUserDialogComponent
   * @param data
   */
  dispatchRegisterGuestPinRequest(data: DispatchRegisterGuest): void {
    this._store.dispatch(TechLoginActions.registerGuestPinRequest({
      registerGuest: {
        isRetry: false,
        email: data.email,
        passwordBase64: data.passwordBase64
      },
      isLoadingId: this.buttonId
    }))
  }
}
