import {Component, Input, OnInit} from '@angular/core';
import {MissionDto, StereotypeListDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule,} from "@ngx-translate/core";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {
  TechInventoryMissionTypeOptionInputComponent
} from "@tech/pages/inventory/components/mission/components/type-option-input/tech-inventory-mission-type-option-input.component";
import {CoreClockService} from "@core/services/core-clock.service";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-tech-inventory-mission-detail-container-description',
  templateUrl: './tech-inventory-mission-detail-container-description.component.html',
  styleUrls: ['./tech-inventory-mission-detail-container-description.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedUserHtmlComponent, TechInventoryMissionTypeOptionInputComponent],

})
export class TechInventoryMissionDetailContainerDescriptionComponent implements OnInit {
  @Input() missionDetail: MissionDto;
  selectedStereotype$: Observable<StereotypeListDto>;


  constructor(
    private _store: Store,
    public clockService: CoreClockService
  ) {
    this.missionDetail = {};
    this.selectedStereotype$ = of();


  }

  ngOnInit() {
    this.selectedStereotype$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionSelectedStereotype));
  }

  formatDates(start: string | undefined, end: string | null | undefined): string {
    if (!start) return '';

    const startDate = this.clockService.formatDateWithWeekday(start, false, true);
    const endDate = end ? this.clockService.formatDateWithWeekday(end, false, true) : '';

    return endDate ? `${ startDate } - ${ endDate }` : startDate;
  }
}
