import {Component, Input, OnInit} from '@angular/core';
import {MissionState} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-shared-issue-custom-read-only-mission-state-actions',
  templateUrl: './shared-issue-custom-read-only-mission-state-actions.component.html',
  styleUrls: ['./shared-issue-custom-read-only-mission-state-actions.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],

})
export class SharedIssueCustomReadOnlyMissionStateActionsComponent implements OnInit {

  @Input() stateOption: MissionState;
  protected readonly MissionState = MissionState;

  constructor() {
    this.stateOption = NaN;
  }

  ngOnInit() {
  }

}
