import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";
import {IssueDtoStereotypeType} from "@shared/components/issues/types/issue-dto.type";
import {
  CustomDefaultCompleteDto,
  CustomPropertyCompleteDto,
  CustomPropertyDto,
  CustomPropertyType,
  CustomValueCompleteDto,
  IssueType,
  MissionState,
  StereotypeDto
} from "@server-models";
import {
  IssueCustomPropertySetFormatted
} from "@shared/components/issues/interfaces/issue-custom-property-set-formatted.interface";
import {IssueFormatted} from "@shared/components/issues/interfaces/issue-formatted.interface";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {ClockService} from "@core/services/clock.service";
import {AssignedUserEnum} from "@tech/pages/inventory/enums/segment-settings.emun";

@Injectable({
  providedIn: 'root'
})
export class TechInventoryService {
  private settingReset = new BehaviorSubject<{
    state?: MissionState | null,
    stereotype?: number | null | undefined
  }>({
    state: null,
    stereotype: null
  });
  private segmentReset = new BehaviorSubject<AssignedUserEnum>(AssignedUserEnum.Mine);

  constructor(
    private _clockService: ClockService
  ) {
  }

  getSettingReset() {
    return this.settingReset;
  }

  getSegmentReset() {
    return this.segmentReset;
  }

  resetSetting() {
    this.settingReset.next({
      state: null,
      stereotype: null
    });
  }

  setSetting(incSetting: {
    state?: MissionState | null,
    resource?: number | null
  }) {

    this.settingReset.next(incSetting);
  }

  resetSegment() {
    this.segmentReset.next(AssignedUserEnum.Mine);
  }

  setSegment(incSegment: AssignedUserEnum) {
    this.segmentReset.next(incSegment);
  }

  resetAll() {
    this.settingReset.next({
      state: null,
      stereotype: null
    });
    this.segmentReset.next(AssignedUserEnum.Mine);
  }

  formatDate(date: string): string {
    return date ? this._clockService.formatDateWithWeekday(date) : "";
  }

  formatInventoryMissionReadDetailData(issueDto: IssueDtoStereotypeType, stereotypeDto?: StereotypeDto) {
    let customPropertySetsFormatted: IssueCustomPropertySetFormatted[] = [];

    for (const customPropertySet of stereotypeDto!.customPropertySets!) {
      const customPropertySetName = customPropertySet.customPropertySet?.name;
      const customPropertySetId = customPropertySet.customPropertySet?.customPropertySetId;
      const customPropertySetPosition = customPropertySet.position;
      const issuesFormatted: IssueFormatted[] = [];

      for (const customPropertyValue of issueDto.customPropertyValues!) {
        const propertyId: number | undefined = customPropertyValue.propertyId;

        const properties: CustomPropertyDto[] = customPropertySet.customPropertySet!.properties!;
        const correspondingProperty: CustomPropertyCompleteDto | undefined = properties.find((property: CustomPropertyDto) => property.customPropertyId === propertyId);
        const customValue: CustomValueCompleteDto = customPropertyValue!.customValues![0].customValue!;

        if (correspondingProperty) {
          switch (correspondingProperty.type) {
            case CustomPropertyType.Info: {
              const formattedValueInfo: IssueFormatted = {
                title: correspondingProperty.name!,
                value: correspondingProperty.content,
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
                kind: correspondingProperty.infoKind
              }
              issuesFormatted.push(formattedValueInfo);
              break;
            }
            case CustomPropertyType.Dropdown: {
              const formattedValueInfo: IssueFormatted = {
                title: correspondingProperty.name!,
                value: this._getDropdownContent(customValue.ownValue, correspondingProperty.defaultValues!),
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
              }
              issuesFormatted.push(formattedValueInfo);
              break;
            }
            case CustomPropertyType.Signature:
            case CustomPropertyType.Picture: {
              const formattedValueInfo: IssueFormatted = {
                title: correspondingProperty.name!,
                fileValue: (customValue as any).ownValueFile,
                value: customValue.ownValue,
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
              }
              issuesFormatted.push(formattedValueInfo);
              break;
            }
            default: {
              const formattedValue: IssueFormatted = {
                title: correspondingProperty.name!,
                value: customValue.ownValue,
                position: correspondingProperty.position!,
                type: correspondingProperty.type!,
              };
              issuesFormatted.push(formattedValue);
            }
          }
        }
      }

      issuesFormatted.sort((a, b) => a.position - b.position);

      customPropertySetsFormatted.push({
        name: customPropertySetName!,
        propertySetId: customPropertySetId!,
        position: customPropertySetPosition!,
        issuesFormatted: issuesFormatted
      });

      customPropertySetsFormatted.sort((a, b) => a.position - b.position);
    }

    const baseResult: IssueFormattedDetailData = {
      issueId: issueDto.issueId!,
      headerTitle: issueDto.title!,
      issueType: IssueType.Mission,
      customPropertySets: customPropertySetsFormatted
    };

    return {
      ...baseResult
    };
  }

  protected _getDropdownContent(selectedId: number, options: CustomDefaultCompleteDto[]): string {
    const result = options.find((props: any) => props.customValueId === selectedId)!;
    return result?.value;
  }
}
