import {createReducer, on} from "@ngrx/store";
import {TechSideBarState} from "@tech/components/side-bar/store/tech-side-bar.state";
import {TechSideBarActions} from "@tech/components/side-bar/store/tech-side-bar.actions-type";

export const initialSideBar: TechSideBarState = <TechSideBarState>{
  name: "",
  path: "",
  file: <Blob>{},
  logo: "",
  isLoading: true,
  isLoadingId: NaN
};

export const techSideBarStore = createReducer(
  initialSideBar,
  on(TechSideBarActions.getLogo, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TechSideBarActions.getLogoSuccess, (state, args) => ({
    ...state,
    logo: args.imgLogo,
    isLoading: false,
  })),
  on(TechSideBarActions.getLogoFail, (state) => ({
    ...state,
    isLoading: false
  })),
);
