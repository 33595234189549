import {Injectable} from "@angular/core";
import {IssueContentType, IssueType} from "@server-models";
import {IIssueService} from "@shared/components/issues/interfaces/issue-service.interface";
import {TechIssueMessageApiService} from "@tech/pages/issues/services/tech-issue-message-api.service";
import {TechIssueMissionApiService} from "@tech/pages/issues/services/tech-issue-mission-api.service";
import {TechIssueTicketApiService} from "@tech/pages/issues/services/tech-issue-ticket-api.service";
import {
  TechIssuesWinterhalterServiceCallApiService
} from "@tech/pages/issues/services/winterhalter/tech-issue-winterhalter-service-call-api.service";
import {
  TechIssuesWinterhalterProductOrderApiService
} from "@tech/pages/issues/services/winterhalter/tech-issue-winterhalter-product-order-api.service";
import {TechIssueControlMessageApiService} from "@tech/pages/issues/services/tech-issue-control-message-api.service";

@Injectable({
  providedIn: 'root',
})
export class TechIssueService {
  constructor(
    private _techIssuesMessageApiService: TechIssueMessageApiService,
    private _techIssuesMissionApiService: TechIssueMissionApiService,
    private _techIssuesTicketApiService: TechIssueTicketApiService,
    private _techIssuesControlMessageApiService: TechIssueControlMessageApiService,
    private _techIssuesWinterhalterServiceCallApiService: TechIssuesWinterhalterServiceCallApiService,
    private _techIssuesWinterhalterProductOrderService: TechIssuesWinterhalterProductOrderApiService,
  ) {

  }

  getApiServiceByIssueType(issueType: IssueType): IIssueService {
    switch (issueType) {
      case IssueType.Message:
        return this._techIssuesMessageApiService;
      case IssueType.Mission:
        return this._techIssuesMissionApiService;
      case IssueType.Ticket:
        return this._techIssuesTicketApiService;
      case IssueType.ControlMessage:
        return this._techIssuesControlMessageApiService;
      case IssueType.WinterhalterServiceCall:
        return this._techIssuesWinterhalterServiceCallApiService;
      case IssueType.WinterhalterProductOrder:
        return this._techIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue type: ${ issueType }`);
    }
  }

  getApiServiceByIssueContentType(issueContentType: IssueContentType): IIssueService {
    switch (issueContentType) {
      case IssueContentType.Message:
        return this._techIssuesMessageApiService;
      case IssueContentType.Mission:
        return this._techIssuesMissionApiService;
      case IssueContentType.Ticket:
        return this._techIssuesTicketApiService;
      case IssueContentType.WinterhalterServiceCall:
        return this._techIssuesWinterhalterServiceCallApiService;
      case IssueContentType.WinterhalterProductOrder:
        return this._techIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue content type: ${ issueContentType }`);
    }
  }


}
