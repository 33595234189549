import {Component, OnInit} from '@angular/core';
import {IonicModule, ViewWillEnter} from "@ionic/angular";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {
  AppEntityType, CombineOperator,
  FilterDto,
  FilterOperators, FilterTypes, GroupedFilterDto, LocationListDto,
  MissionCompleteDto,
  MissionState, Orders, ResourceModelListDto,
  ResourceType,
  StereotypeListDto
} from "@server-models";
import {SharedIssueItemComponent} from "@shared/components/issues/components/issue-item/shared-issue-item.component";
import {
  filter,
  first,
  Observable,
  of,
  Subscription
} from 'rxjs';
import {tap} from "rxjs/operators";
import {select, Store} from "@ngrx/store";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-inventory.actions-type";
import {
  ITechInventoryPageMenuTreeItemListMainItem,
  TechInventoryState, TechInventoryPageMenuTreeState
} from "@tech/pages/inventory/store/tech-inventory.state";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {
  TechInventorySettingsComponent
} from "@tech/pages/inventory/components/settings/tech-inventory-settings.component";
import {EAssignedUser} from "@tech/pages/inventory/enums/assigned-user.emun";
import {TechInventoryService} from "@tech/pages/inventory/services/tech-inventory.service";
import {TechMissionFilterService} from "@tech/pages/inventory/services/tech-mission-filter.service";
import {FormsModule} from "@angular/forms";
import {ETreeMenuSegment} from "@tech/pages/inventory/enums/tree-menu-segment.enum";
import {
  TechInventoryChildTreeResourceListComponent
} from "@tech/pages/inventory/components/child-tree-resource-list/tech-inventory-child-tree-resource-list.component";
import {TechMenuTreeFilterService} from "@tech/pages/inventory/services/tech-menu-tree-filter.service";
import {TechInventoryHelperService} from "@tech/pages/inventory/services/tech-iventory-helper.service";
import {SharedSearchBarComponent} from "@shared/components/search-bar/shared-search-bar.component";
import {
  TechInventoryMissionListComponent
} from "@tech/pages/inventory/components/mission/components/list/tech-inventory-mission-list.component";
import {
  TechInventoryMissionMapComponent
} from "@tech/pages/inventory/components/mission/components/map/tech-inventory-mission-map.component";
import {SharedButtonSideMenuComponent} from "@shared/components/button-side-menu/shared-button-side-menu.component";
import {EMissionDisplayMode} from "@tech/pages/inventory/enums/mission-display-mode.enum";

@Component({
  selector: 'app-tech-inventory',
  templateUrl: './tech-inventory.page.html',
  styleUrls: ['./tech-inventory.page.scss'],
  imports: [
    IonicModule,
    AsyncPipe,
    SharedIssueItemComponent,
    NgForOf,
    NgIf,
    TranslateModule,
    TechInventorySettingsComponent,
    FormsModule,
    TechInventoryChildTreeResourceListComponent,
    NgClass,
    SharedSearchBarComponent,
    TechInventoryMissionListComponent,
    TechInventoryMissionMapComponent,
    SharedButtonSideMenuComponent
  ],
  standalone: true
})
export class TechInventoryPage implements OnInit, ViewWillEnter {
  protected readonly MissionState = MissionState;
  protected readonly SegmentSettingsEnum = EAssignedUser;
  protected readonly TreeMenuSegmentEnum = ETreeMenuSegment;
  displayType: EMissionDisplayMode =  EMissionDisplayMode.LIST;

  paginationParams: IPaginationParams;
  paginationTreeStereotypeParams: IPaginationParams;
  paginationResourceTreeParams: IPaginationParams;

  paginationBody: FilterDto[];
  defaultPaginationBody: FilterDto[];

  isDragRefresh: boolean;
  isDragRefreshTree: boolean;

  isInfinityLoading: boolean;
  isInfinityMainTreeLoading: boolean;

  onlyMapConfig: {
    distance?: number | string | null;
  };

  onlyListConfig: {
    sortBy?: string;
  };

  missionDataList$: Observable<MissionCompleteDto[]>;
  missionStereotypes$: Observable<StereotypeListDto[]>;
  currentStereotypes: StereotypeListDto[];
  missionFilter$?: Observable<any>;

  inventoryMenuTree$: Observable<TechInventoryPageMenuTreeState>;
  currentTree: ITechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>[];
  filterResourceType: (FilterDto | GroupedFilterDto)[];

  pagingData$: Observable<IPagination>;

  isLoading$: Observable<boolean>;
  isLoadingMenuTreeMainList$: Observable<boolean>;
  isLoadingMenuTreeResourceById$: Observable<boolean>;

  openSubscriptionList$: Subscription[];
  isFirstLoad: boolean;

  selectedSegmentSettings: EAssignedUser | null;
  selectedTreeMenuSegment: ETreeMenuSegment;

  selectedItem: any;
  isDisplaySearchBar: boolean;

  constructor(
    private _store: Store<TechInventoryState>,
    private _inventoryService: TechInventoryService,
    private _missionFilterService: TechMissionFilterService,
    private _menuTreeFilterService: TechMenuTreeFilterService,
    public inventoryHelperService: TechInventoryHelperService
  ) {
    this.missionDataList$ = of();

    this.inventoryMenuTree$ = of();

    this.missionStereotypes$ = of();

    this.pagingData$ = of();

    this.isLoading$ = of();
    this.isLoadingMenuTreeMainList$ = of();
    this.isLoadingMenuTreeResourceById$ = of();


    this.openSubscriptionList$ = [];

    this.isDragRefresh = false;
    this.isDragRefreshTree = false;

    this.isInfinityLoading = false;
    this.isInfinityMainTreeLoading = false;

    this.paginationParams = {
      pageNumber: 1,
      pageSize: 10,
      cols: 'Location',
      sortField: 'plannedStart',
      sort: Number(Orders.Descending)
    };
    this.paginationTreeStereotypeParams = {
      pageNumber: 1,
      pageSize: 30,
      cols: 'Content',
      entityType: AppEntityType.Resource,
      archived: 'false'
    };
    this.paginationResourceTreeParams = {
      pageNumber: 1,
      pageSize: 10,
      cols: 'Content'
    };

    this.defaultPaginationBody = [];
    this.paginationBody = [];
    this.onlyMapConfig = {};
    this.onlyListConfig = {};

    this.currentStereotypes = [];
    this.currentTree = <ITechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>[]>{};
    this.filterResourceType = [];
    this.isFirstLoad = true;

    this.isDisplaySearchBar = false;
    this.selectedSegmentSettings = EAssignedUser.Mine;
    this.selectedTreeMenuSegment = ETreeMenuSegment.Type;
  }

  ngOnInit() {
    this.missionDataList$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionList));

    this.inventoryMenuTree$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMenuTree))

    this.missionStereotypes$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionStereotypes));

    this.pagingData$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionPagination));

    this.isLoading$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionLoading));
    this.isLoadingMenuTreeMainList$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMenuTreeMainListLoading));

    this._inventoryService.setSetting({
      state: MissionState.Instructed
    });

    this.missionFilter$ = this._missionFilterService.filters$;

    this._missionFilterService.filters$.subscribe((filters) => {
      this.paginationParams.pageNumber = 1;
      const isRefresh = !this.isFirstLoad;
      this.loadInventoryMissionByPagination(filters, isRefresh)
    });

    this.missionStereotypes$.subscribe((data) => {
      if (data) {
        this.currentStereotypes = this._filterByNoArchived(data);
      }
    });

    this._menuTreeFilterService.paramSettingsSubject.subscribe(({ segment }) => {
      this.selectedTreeMenuSegment = segment!;
    });
    this._menuTreeFilterService.request$.subscribe(({ param, filter, isRefresh }) => {
      const definitiveRefresh = this.isFirstLoad ? this.isFirstLoad : isRefresh;
      this.loadInventoryTreeMenuMainListByPagination(param, filter, definitiveRefresh);
    });
  }

  ionViewWillEnter() {
    if (!this.isFirstLoad) {
      this._missionFilterService.filterSettingsSubject.next({
        ...this._missionFilterService.filterSettingsSubject.getValue(),
      });

      this._menuTreeFilterService.filterSettingsSubject.next({
        ...this._menuTreeFilterService.filterSettingsSubject.getValue(),
      });
    }

    this.isFirstLoad = false;
  }

  /**
   * @name _filterByNoArchived
   * @description
   * filter stereotypes by non archived
   * @memberof TechInventoryPage
   * @param stereotypeList
   * @private
   */
  private _filterByNoArchived(stereotypeList: StereotypeListDto[]) {
    return stereotypeList.filter(stereotype => stereotype.isArchived !== true);
  }

  /**
   * @name loadInventoryMissionByPagination
   * @description
   * launch the action to load inventory mission by a post params and body
   * @memberof TechInventoryPage
   * @param filters
   * @param isRefresh
   */
  loadInventoryMissionByPagination(filters: FilterDto[], isRefresh: boolean = false): void {
    this._store.dispatch((TechInventoryActions.postMissionPagePaginated({
      pagination: this.paginationParams,
      filters: filters,
      isRefresh: isRefresh
    })))
  }

  loadInventoryTreeMenuMainListByPagination(param: IPaginationParams, filters: FilterDto[],
                                            isRefresh: boolean = false) {
    if (isRefresh) {
      this.paginationTreeStereotypeParams.pageNumber = 1;
      this.selectedItem = null;
    }
    this._store.dispatch((TechInventoryActions.requestMenuTreeMainListPaginated({
      currentSegment: this.selectedTreeMenuSegment,
      request: {
        pagination: param,
        filters: filters,
        isRefresh: isRefresh
      }
    })))
  }

  loadInventoryTreeMenuResourceByPagination(mainItemId: number, filters: (FilterDto | GroupedFilterDto)[],
                                            isRefresh: boolean = false) {
    this._store.dispatch((TechInventoryActions.requestMenuTreeResourcePaginated({
      mainItemId,
      request: {
        pagination: this.paginationResourceTreeParams,
        filters: filters,
        isRefresh: isRefresh
      }
    })));
  }

  loadMoreInventoryMission(event: any): void {
    this.paginationParams.pageNumber++;
    this.isInfinityLoading = true;
    this._missionFilterService.filters$
      .pipe(
        first(),
        tap(filters => {
          this.loadInventoryMissionByPagination(filters);
        })
      )
      .subscribe({
        complete: () => {
          this.isLoading$.pipe(
            filter(loader => !loader),
            tap(() => {
              event.target.complete();
              this.isInfinityLoading = false;
            })
          ).subscribe();
        },
        error: () => {
          event.target.complete();
          this.isInfinityLoading = false;
        }
      });
  }

  loadMoreInventoryTreeMenu(event: any) {
    this.paginationTreeStereotypeParams.pageNumber++;
    this.isInfinityMainTreeLoading = true;
    this._menuTreeFilterService.request$
      .pipe(
        first(),
        tap(({ param, filter }) => {
          this.loadInventoryTreeMenuMainListByPagination(this.paginationTreeStereotypeParams, filter, false);
        })
      )
      .subscribe({
        complete: () => {
          this.isLoadingMenuTreeMainList$.pipe(
            filter(loader => !loader),
            tap(() => {
              event.target.complete();
              this.isInfinityMainTreeLoading = false;
            })
          ).subscribe();
        },
        error: () => {
          event.target.complete();
          this.isInfinityMainTreeLoading = false;
        }
      });
  }

  handleRefresh(event: any): void {
    if (this.isDragRefresh) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.isDragRefresh = true;
    if (event) {
      event.target.disabled = true;
    }

    this.resetProperties();

    this.isLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.isDragRefresh = false;
      })
    ).subscribe();
  }

  /**
   * @name resetProperties
   * @description
   * reset the pagination pageNumber to 1, the pagination body to [] and reset all settings/segmentes filters
   * @memberof TechInventoryPage
   */
  resetProperties(): void {
    this.paginationParams.pageNumber = 1;
    this._inventoryService.resetSetting();
    this._missionFilterService.filterSettingsSubject.next({
      ...this._missionFilterService.filterSettingsSubject.getValue(),
      state: undefined,
      stereotypeId: undefined,
    });
  }

  /**
   * @name changeSegmentSetting
   * @description
   * emit event with selected segment value
   * @memberof TechInventoryPage
   * @param ev
   */
  changeSegmentSetting(ev: CustomEvent): void {
    const userEnumValue: EAssignedUser = ev.detail.value;
    this.selectedSegmentSettings = userEnumValue;
    this._missionFilterService.filterSettingsSubject.next({
      ...this._missionFilterService.filterSettingsSubject.getValue(),
      assignedUser: userEnumValue,
    });
  }

  changeTreeMenuSegment(ev: CustomEvent): void {
    const selectedSegment: ETreeMenuSegment = ev.detail.value;
    this._menuTreeFilterService.requestSettingSubject.next({
        ...this._menuTreeFilterService.requestSettingSubject.getValue(),
        param: { segment: selectedSegment },
        filter: { segment: selectedSegment },
        isRefresh: true
      }
    );
    this._missionFilterService.filterSettingsSubject.next({
      ...this._missionFilterService.filterSettingsSubject.getValue(),
      stereotypeId: undefined,
      resource: undefined,
      parentResource: undefined
    });

  }

  onDisplayTypeChange(newDisplayType: EMissionDisplayMode): void {
    this.displayType = newDisplayType;
    if (newDisplayType == EMissionDisplayMode.MAP) {
      this._missionFilterService.clearOnlyList();
    }
  }

  onMissionMapDistanceChanged(distance: number | string | null): void {
    this.onlyMapConfig = { ...this.onlyMapConfig, distance: distance };
  }

  onMissionListSortByChanged(sort: string | undefined) {
    if (sort) {
      this.onlyListConfig = { ...this.onlyListConfig, sortBy: sort };
      this.updateMissionParam();
    }
  }

  updateMissionParam() {
    if (this.onlyListConfig) {
      if (this.onlyListConfig.sortBy) {
        switch (this.onlyListConfig.sortBy) {
          case 'plannedStartDesc':
            this.paginationParams.sortField = 'plannedStart';
            this.paginationParams.sort = Number(Orders.Descending);
            break
          case 'plannedStartAsc':
            this.paginationParams.sortField = 'plannedStart';
            this.paginationParams.sort = Number(Orders.Ascending);
            break
          case 'plannedEndDesc':
            this.paginationParams.sortField = 'plannedEnd';
            this.paginationParams.sort = Number(Orders.Descending);
            break
          case 'plannedEndAsc':
            this.paginationParams.sortField = 'plannedEnd';
            this.paginationParams.sort = Number(Orders.Ascending);
            break
          default:
            break;
        }
      }
    }
  }

  openResources(item: ITechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>,
                parentId: number) {
    this.selectedItem = this.inventoryHelperService.getMainItemId(this.selectedItem?.mainItem) === parentId ? null : item;
    if (this.selectedItem != null) {
      this.paginationResourceTreeParams.pageNumber = 1;
      this._setFilterResourceType(parentId.toString());
      this.loadInventoryTreeMenuResourceByPagination(parentId, this.filterResourceType, true);
      this._missionFilterService.filterSettingsSubject.next({
        ...this._missionFilterService.filterSettingsSubject.getValue(),
        parentResource: {
          type: this.selectedTreeMenuSegment,
          id: parentId
        }
      });
    } else {
      this._missionFilterService.filterSettingsSubject.next({
        ...this._missionFilterService.filterSettingsSubject.getValue(),
        stereotypeId: undefined,
        resource: undefined,
        parentResource: undefined
      });
    }

  }

  requestMore(ev: { paging: IPagination, parentId: number }) {
    this._missionFilterService.filters$
      .pipe(
        first(),
        tap(filters => {
          this.paginationResourceTreeParams.pageNumber++;
          this._setFilterResourceType(ev.parentId.toString());
          this.loadInventoryTreeMenuResourceByPagination(ev.parentId, this.filterResourceType);
        })
      )
      .subscribe();
  }

  _setFilterResourceType(parentId: string): void {
    switch (this.selectedTreeMenuSegment) {
      case ETreeMenuSegment.Type: {
        this.filterResourceType = [
          {
            property: 'stereotypeId',
            value: parentId,
            operator: FilterOperators.Equal
          },
          {
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.Or,
            children: [
              <FilterDto>{
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                property: 'type',
                value: ResourceType.Device
              },
              <FilterDto>{
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                property: 'type',
                value: ResourceType.Virtual
              }
            ]
          }
        ];
        break;
      }
      case ETreeMenuSegment.Location: {
        this.filterResourceType = [
          {
            property: 'location.locationId',
            value: parentId,
            operator: FilterOperators.Equal
          }
        ];
        break;
      }
      case ETreeMenuSegment.Model: {
        this.filterResourceType = [
          {
            property: 'model.resourceModelId',
            value: parentId,
            operator: FilterOperators.Equal
          }
        ];
        break;
      }
    }

  }

  formatNameToDisplay(mainItem: StereotypeListDto | LocationListDto | ResourceModelListDto): string {
    switch (this.selectedTreeMenuSegment) {
      case ETreeMenuSegment.Model: {
        const castMainItem = mainItem as ResourceModelListDto;
        return `${ castMainItem.resourceManufacturer!.name } ${ castMainItem.name }`
      }
      default: {
        return mainItem.name!
      }
    }
  }

  changeDisplayBar() {
    if (this.displayType !== EMissionDisplayMode.MAP) {
      this.isDisplaySearchBar = !this.isDisplaySearchBar;
      if (!this.isDisplaySearchBar) {
        this._missionFilterService.filterSettingsSubject.next({
          ...this._missionFilterService.filterSettingsSubject.getValue(),
          titleToSearch: undefined
        });

      }
    }
  }

  onSearch(searchTerm: string): void {
    this._missionFilterService.filterSettingsSubject.next({
      ...this._missionFilterService.filterSettingsSubject.getValue(),
      titleToSearch: searchTerm
    });
  }


  /**
   * @name trackById
   * @description
   * arrow fuction to help the DOM to keep the information related to where are the items are and dont scroll to up
   * on load more resources request, need to be arrow function because is using a service from the component so it
   * needs to keep the component context instead only ngfor loop context
   * @param index
   * @param item
   */
  trackById = (index: number,
               item: ITechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>): number | undefined => {
    return this.inventoryHelperService.getMainItemId(item.mainItem);
  }
  protected readonly EMissionDisplayMode = EMissionDisplayMode;
}
