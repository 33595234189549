import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {
  Brands,
  Clients,
  GeneralSettingDto,
  PasswordLoginDto,
  RefreshTokenV3Dto,
  RequestUserRegistrationDto,
  ResetPasswordDto,
  SettingType,
  TokenDto
} from "@server-models";
import {TechHttpClientBaseApiService} from "@tech/services/http-client-base-api/tech-http-client-base-api.service";
import {TechErrorHandlerLoginService} from "@tech/services/error-handler-base/error-handler-login.service";
import {
  TechErrorHandlerResetPasswordService
} from "@tech/services/error-handler-base/error-handler-reset-password.service";

@Injectable({
  providedIn: 'root'
})
export class TechLoginApiService extends TechHttpClientBaseApiService {

  /**
   * @name login
   * @description
   * Make a post request to the super class with auth false to login
   * @memberof TechLoginApiService
   * @param login
   * @param password
   * @param passwordBase64
   * @return Observable<TokenDto>
   */
  login(login: string, password: string, passwordBase64?: string): Observable<TokenDto> {
    const body: PasswordLoginDto = {
      email: login,
      passwordBase64: passwordBase64 ? passwordBase64 : btoa(password)
    }

    const options = {
      errorHandler: new TechErrorHandlerLoginService(this._alertControllerService, this._translationService, this._router)
    }

    return super.post<TokenDto>(`/identity/login/password`, false, body, options);
  }

  changePassword(email: string, oldPasswordBase64: string, newPasswordBase64: string): Observable<any> {
    const body = {
      email,
      passwordBase64: oldPasswordBase64,
      newPasswordBase64
    }
    return super.post<any>(`/identity/login/changepassword`, false, body);
  }

  /**
   * @name resetPassword
   * @description
   * make a post request to identity api to reset password
   * @memberof TechLoginApiService
   * @param resetData
   * @returns {Observable<void>}
   */
  resetPassword(resetData: ResetPasswordDto): Observable<void> {
    return super.post<void>(`/identity/login/resetpassword`, false, resetData);
  }

  /**
   * @name resetRequestPassword
   * @description
   * make a post to identity api to launch a request by email to reset password
   * @memberof TechLoginApiService
   * @param email
   * @returns {Observable<void>}
   */
  resetRequestPassword(email: string): Observable<void> {
    const body = {
      email
    }
    const options = {
      errorHandler: new TechErrorHandlerResetPasswordService(this._alertControllerService, this._translationService, this._router)
    }
    return super.post<void>(`/identity/login/requestPasswordReset`, false, body, options);
  }

  /**
   * @name loginRefresh
   * @description
   * make a refresh login using refreshToken and tenantId
   * @memberof TechLoginApiService
   * @param refreshToken
   * @param tenantId
   * @return {Observable<TokenDto>}
   */
  loginRefresh(refreshToken: string, tenantId: number): Observable<TokenDto> {
    const body: RefreshTokenV3Dto = {
      meta: {
        type: 1
      },
      refreshToken,
      tenantId,
      client: Clients.Tech
    }
    const options = {
      errorHandler: new TechErrorHandlerLoginService(this._alertControllerService, this._translationService, this._router)
    }
    return super.post<TokenDto>(`/identity/v3/login/refresh`, false, body, options);
  }

  /**
   * @name getSettings
   * @description
   * request the global settings by tenant id
   * @memberof TechLoginApiService
   * @param tenantId
   * @returns {Observable<GeneralSettingDto>}
   */
  getSettings(tenantId: number): Observable<GeneralSettingDto> {
    // SettingType.General after settings is for general settings, is hardcode for now
    return super.get(`/orga/tenant/${tenantId}/setting/${SettingType.General}`, undefined);
  }

  requestGuestPin(email: string): Observable<void> {
    const body: RequestUserRegistrationDto = {
      email,
      brand: Brands.Semco
    }
    return super.post<void>(`/identity/userRegistration/requestUserRegistration`, false, body);
  }

  requestGuestAsUser(pin: string, email: string, passwordBase64: string): Observable<void> {
    const body: any = {
      pinFromEmail: pin,
      passwordBase64: passwordBase64,
      email
    }
    return super.post<void>(`/identity/userRegistration/registerUser`, false, body);
  }

}
