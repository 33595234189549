import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {
  AppEntityType, ArticleListDto, LocationListDto,
  MissionCompleteDto,
  MissionDto, MissionReportDto, MissionReportPreviewDto,
  MissionState,
  ResourceDto, ResourceModelListDto,
  StereotypeDto,
  StereotypeListDto,
} from "@server-models";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {TechInventoryApiService} from "@tech/pages/inventory/services/inventory-api.service";
import {TechInventoryEffectsBase} from "@tech/pages/inventory/store/tech-inventory.effects";
import {InventoryRequestPagination} from "@tech/pages/inventory/interfaces/inventory-request-pagination.interface";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {TechInventoryActions} from "@tech/pages/inventory/store/tech-iventory.actions-type";
import {CacheService} from "@shared/services/cache/cache.service";
import {TechStereotypeApiService} from "@tech/services/stereotype/tech-stereotype-api.service";
import {Router} from "@angular/router";
import {TechInventoryService} from "@tech/pages/inventory/services/tech-inventory.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastControllerService} from "@shared/services/toast-controller.service";
import {
  TechInventoryPageMenuTreeItemList,
  TechInventoryPageMenuTreeItemListMainItem
} from "@tech/pages/inventory/store/tech-invetory.state";
import {environment} from "@env-tech/environment.local";
import {TechResourceApiService} from "@tech/pages/inventory/services/resource-api.service";
import {TreeMenuSegmentEnum} from "@tech/pages/inventory/enums/tree-menu-segment.enum";
import {TechInventoryHelperService} from "@tech/pages/inventory/services/iventory-helper.service";
import {
  TechInventoryMissionPagesService
} from "@tech/pages/inventory/components/tech-inventory-mission-detail/services/mission-pages.service";

@Injectable({
  providedIn: 'root'
})
export class TechInventoryApiEffects extends TechInventoryEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _techInventoryApiService: TechInventoryApiService,
    private _techStereotypeApiService: TechStereotypeApiService,
    private _techResourceApiService: TechResourceApiService,
    private _cacheService: CacheService,
    private _router: Router,
    private _techInventoryService: TechInventoryService,
    private _toastService: ToastControllerService,
    private _translateService: TranslateService,
    private _techInventoryHelperService: TechInventoryHelperService,
    private _missionPagesService: TechInventoryMissionPagesService,
  ) {
    super(store, actions$);
  }

  postMissionPaginated(action: Observable<InventoryRequestPagination>): Observable<(OrgaResponse<MissionCompleteDto[]> | any)> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionPagination))),
      mergeMap(([action, paginationState]) => {
          if (action.refresh) {
            return this._requestPostMissionPaginated(action);
          } else if (this._checkPaginationState(action, paginationState)) {
            return this._requestPostMissionPaginated(action);
          } else {
            return [TechInventoryActions.postMissionPagePaginatedCancel()];
          }
        }
      )
    );
  }

  private _checkPaginationState(action: InventoryRequestPagination, state: Pagination): boolean {
    return !state || action.pagination.pageNumber <= state.totalPages
  }

  private _requestPostMissionPaginated(action: InventoryRequestPagination): Observable<(OrgaResponse<MissionCompleteDto[]> | any)> {
    return this._techInventoryApiService.pagePostMissionPaginated(action.pagination, action.filters).pipe(
      map((data: OrgaResponse<MissionCompleteDto[]>) =>
        action.refresh
          ? TechInventoryActions.postMissionPagePaginatedRefresh({data})
          : TechInventoryActions.postMissionPagePaginatedSuccess({data})
      ),
      catchError((error) => [TechInventoryActions.postMissionPagePaginatedFail({error})])
    )
  }

  postMissionPaginatedSuccess(action: Observable<any>): Observable<any> {
    return action.pipe(
      mergeMap(() => {
        return this._requestStereotypeFilterByEntityType();
      })
    );
  }

  private _requestStereotypeFilterByEntityType(): Observable<any> {
    const entityType = AppEntityType.Mission.toString();
    const cacheControl = this._cacheService.generateGuid();
    return this._techStereotypeApiService.getStereotypeFilterByEntityType(entityType, cacheControl).pipe(
      map((data: OrgaResponse<StereotypeListDto>) => {
          return TechInventoryActions.postMissionPagePaginatedDone({data})
        }
      ),
      catchError((error) => [TechInventoryActions.postMissionPagePaginatedFail({error})])
    )
  }

  navigateToMissionDetail(action: Observable<MissionCompleteDto>): Observable<any> {
    return action.pipe(
      tap((mission) => {
        this._router.navigate([`tech/logged/inventory/mission/${mission.missionId}`]);

      })
    )
  }

  getMissionDetailById(action: Observable<{
    missionId: string;
    reportDemand: boolean;
    existingUrl: string[]
  }>): Observable<any> {
    return action.pipe(
      mergeMap(({missionId, reportDemand, existingUrl}) => {
        return this._requestMissionDetailById(missionId, reportDemand, existingUrl)
      })
    );
  }

  private _requestMissionDetailById(missionId: string, reportDemand: boolean, existingUrl: string[]): Observable<any> {
    return this._techInventoryApiService.getMissionDetailById(missionId!).pipe(
      map((data: MissionDto) => {
          return TechInventoryActions.getMissionDetailByIdSuccess({data, reportDemand, existingUrl});
        }
      ),
      catchError((error) => [TechInventoryActions.getMissionDetailByIdFail({error})])
    );
  }

  getMissionDetailByIdSuccess(action: Observable<{
    data: MissionDto;
    reportDemand: boolean;
    existingUrl: string[]
  }>): Observable<any> {
    return action.pipe(
      mergeMap(({data, reportDemand, existingUrl}) => {
        return [TechInventoryActions.getMissionDetailStereotypeById({
          id: data.stereotypeId!,
          reportDemand,
          existingUrl
        })]
      })
    );
  }

  getMissionDetailStereotypeById(action: Observable<{
    id: number;
    reportDemand: boolean;
    existingUrl: string[]
  }>): Observable<any> {
    return action.pipe(
      mergeMap(({id, reportDemand, existingUrl}) =>
        this._requestGetStereotypeById({id, reportDemand, existingUrl})
      )
    );
  }

  private _requestGetStereotypeById(action: { id: number; reportDemand: boolean; existingUrl: string[] }): Observable<{
    data: StereotypeDto
  } | any> {
    const cacheGuid = this._cacheService.generateGuid();
    return this._techInventoryApiService.getStereotypeById(action.id).pipe(
      map((data: StereotypeDto) => {
          return TechInventoryActions.getMissionDetailStereotypeByIdSuccess({
            stereotype: data,
            cacheGuid,
            reportDemand: action.reportDemand,
            existingUrl: action.existingUrl
          })
        }
      ),
      catchError((error) => [TechInventoryActions.getMissionDetailStereotypeByIdFail({error})])
    )
  }

  getMissionDetailStereotypeByIdSuccess(action: Observable<{
    stereotype: StereotypeDto;
    cacheGuid: string;
    reportDemand: boolean;
    existingUrl: string[]
  }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.select(TechInventorySelectors.selectTechInventoryPageMissionDetail)),
      mergeMap(([{stereotype, existingUrl}, mission]) => {
        const formattedReadData = this._techInventoryService.formatInventoryMissionReadDetailData(mission, stereotype);
        const segmentString = existingUrl[0];
        const segmentType = this._missionPagesService.checkSegmentByString(segmentString);
        this._missionPagesService.changeSegmentSetting(segmentType);
        return [TechInventoryActions.getMissionDetailByIdDone({customPropertySets: formattedReadData.customPropertySets})]
      })
    );
  }

  setMissionState(action: Observable<{
    id: number,
    missionToChange: MissionState
  }>): Observable<any> {
    return action.pipe(
      mergeMap(({id, missionToChange}) =>
        this._requestSetMissionState({id, missionToChange})
      )
    );
  }

  private _requestSetMissionState(action: {
    id: number,
    missionToChange: MissionState
  }): Observable<any> {
    return this._techInventoryApiService.postChangeMissionStateById(action.id, action.missionToChange).pipe(
      map(() => {
          return TechInventoryActions.setMissionStateSuccess({missionState: action.missionToChange})
        }
      ),
      catchError((error) => [TechInventoryActions.setMissionStateFail({error})])
    )
  }

  setMissionStateSuccess(action: Observable<{ missionState: MissionState }>): Observable<any> {
    return action.pipe(
      tap(({missionState}) => {
        return this._toastService.observableToast({
          message: `${this._translateService.instant('COMPONENTS.MISSIONS.STATE.ALERT.MESSAGE')}`
        });
      })
    );
  }

  navigateToInventory(action: Observable<any>): Observable<any> {
    return action.pipe(
      tap(() => {
        this._router.navigateByUrl('tech/logged/inventory')
      })
    );
  }

  // report
  getMissionReportById(action: Observable<{ reportId: string }>): Observable<any> {
    return action.pipe(
      mergeMap(({reportId}) => {
        return this._requestMissionReportById(reportId)
      })
    );
  }

  private _requestMissionReportById(reportId: string): Observable<any> {
    return this._techInventoryApiService.getMissionReportById(reportId).pipe(
      map((data: MissionReportDto) => {
          return TechInventoryActions.getMissionReportByIdSuccess({data});
        }
      ),
      catchError((error) => [TechInventoryActions.getMissionReportByIdFail({error})])
    );
  }

  getMissionReportPreviewById(action: Observable<{ missionId: string }>): Observable<any> {
    return action.pipe(
      mergeMap(({missionId}) => {
        return this._requestMissionReportPreviewById(missionId)
      })
    );
  }

  private _requestMissionReportPreviewById(missionId: string): Observable<any> {
    return this._techInventoryApiService.getMissionReportPreviewById(missionId).pipe(
      map((data: MissionReportPreviewDto) => {
          return TechInventoryActions.getMissionReportPreviewByIdSuccess({data});
        }
      ),
      catchError((error) => [TechInventoryActions.getMissionReportPreviewByIdFail({error})])
    );
  }

  // getMissionReportPreviewByIdSuccess(action: Observable<{ data: MissionDto }>): Observable<any> {
  //   return action.pipe(
  //     mergeMap(({data}) => {
  //       return [TechInventoryActions.getMissionDetailStereotypeById({id: data.stereotypeId!})]
  //     })
  //   );
  // }

  getMissionReportArticleList(action: Observable<any>): Observable<any> {
    return action.pipe(
      mergeMap(() => {
        return this._requestMissionReportArticleList()
      })
    );
  }

  private _requestMissionReportArticleList(): Observable<any> {
    return this._techInventoryApiService.postMissionReportArticleFilter([]).pipe(
      map((data: OrgaResponse<ArticleListDto[]>) => {
          return TechInventoryActions.getMissionReportArticleListSuccess({data: data.items});
        }
      ),
      catchError((error) => [TechInventoryActions.getMissionReportArticleListFail({error})])
    );
  }

  submitMissionReport(action: Observable<{ missionReport: MissionReportDto }>): Observable<any> {
    return action.pipe(
      mergeMap(({missionReport}) => {
        return this._submitMissionReport(missionReport);
      })
    );
  }

  private _submitMissionReport(missionReport: MissionReportDto): Observable<any> {
    return this._techInventoryApiService.submitMissionReport(missionReport).pipe(
      map((data) => {
          return TechInventoryActions.submitMissionReportSuccess({response: data});
        }
      ),
      catchError((error) => [TechInventoryActions.submitMissionReportFail({error})])
    );
  }

  submitMissionReportSuccess(action: Observable<any>): Observable<any> {
    return action.pipe(
      tap(() => {
        this._toastService.observableToast({
          message: this._translateService.instant('COMPONENTS.MISSIONS.REPORT.ACTIONS.REPORT.FORM.SUCCESS.MESSAGE')
        })
      })
    );
  }

  // tree
  requestMenuTreeMainListPaginated(action: Observable<{
    currentSegment: TreeMenuSegmentEnum,
    request: InventoryRequestPagination
  }>): Observable<any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(TechInventorySelectors.selectTechInventoryPageMenuTreeMainListPaging))),
      mergeMap(([action, paginationState]) => {
        const segmentHandlers = {
          [TreeMenuSegmentEnum.Type]: () => this._requestMenuTreeMainListPaginatedStereotype(action.request),
          [TreeMenuSegmentEnum.Location]: () => this._requestMenuTreeMainListPaginatedLocation(action.request),
          [TreeMenuSegmentEnum.Model]: () => this._requestMenuTreeMainListPaginatedModel(action.request),
        };

        if (action.request.refresh || this._checkPaginationState(action.request, paginationState)) {
          return segmentHandlers[action.currentSegment]?.() ?? [];
        } else {
          return [TechInventoryActions.requestMenuTreeMainListPaginatedCancel()];
        }
      })
    );
  }

  private _requestMenuTreeMainListPaginatedStereotype(action: InventoryRequestPagination): Observable<(OrgaResponse<StereotypeListDto[]> | any)> {
    return this._techStereotypeApiService.postStereotypeFilterByEntityTypePaginated(action.pagination, action.filters).pipe(
      map((data: OrgaResponse<StereotypeListDto[]>) => {
        const formattedData = this._mainTreeFormattedData(data);
        return action.refresh
          ? TechInventoryActions.requestMenuTreeMainListPaginatedRefresh({data: formattedData})
          : TechInventoryActions.requestMenuTreeMainListPaginatedSuccess({data: formattedData});
      }),
      catchError((error) => [TechInventoryActions.requestMenuTreeMainListPaginatedFail({error})])
    )
  }

  private _requestMenuTreeMainListPaginatedLocation(action: InventoryRequestPagination): Observable<(OrgaResponse<LocationListDto[]> | any)> {
    return this._techInventoryApiService.postLocationFilter(action.pagination, action.filters).pipe(
      map((data: OrgaResponse<LocationListDto[]>) => {
        const formattedData = this._mainTreeFormattedData(data);
        return action.refresh
          ? TechInventoryActions.requestMenuTreeMainListPaginatedRefresh({data: formattedData})
          : TechInventoryActions.requestMenuTreeMainListPaginatedSuccess({data: formattedData});
      }),
      catchError((error) => [TechInventoryActions.requestMenuTreeMainListPaginatedFail({error})])
    )
  }

  private _requestMenuTreeMainListPaginatedModel(action: InventoryRequestPagination): Observable<(OrgaResponse<ResourceModelListDto[]> | any)> {
    return this._techInventoryApiService.postModelFilter(action.pagination, action.filters).pipe(
      map((data: OrgaResponse<ResourceModelListDto[]>) => {
        const formattedData = this._mainTreeFormattedData(data);
        return action.refresh
          ? TechInventoryActions.requestMenuTreeMainListPaginatedRefresh({data: formattedData})
          : TechInventoryActions.requestMenuTreeMainListPaginatedSuccess({data: formattedData});
      }),
      catchError((error) => [TechInventoryActions.requestMenuTreeMainListPaginatedFail({error})])
    )
  }

  private _mainTreeFormattedData(
    response: OrgaResponse<(StereotypeListDto | LocationListDto | ResourceModelListDto)[]>
  ): TechInventoryPageMenuTreeItemList<StereotypeListDto | LocationListDto | ResourceModelListDto> {
    return {
      paging: response.paging,
      isLoading: false,
      items: response.items.map((stereotype) => ({
          mainItem: stereotype,
          mainItemPaging: {
            totalItems: 0,
            pageNumber: 0,
            pageSize: environment?.apiUrl?.pageDefaultSize || 1,
            totalPages: 1
          },
          mainItemIsLoading: true
        })
      ),
      selectedMainItem: {}
    };
  }


  requestMenuTreeResourcePaginated(action: Observable<{
    mainItemId: number;
    request: InventoryRequestPagination
  }>): Observable<(OrgaResponse<ResourceDto[]> | any)> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(TechInventorySelectors.selectTechInventoryPageMenuTreeMainListItems))),
      mergeMap(([{mainItemId, request}, stereotypeItems]) => {
          const pagination = this._getPaginationResourceByMainItemId(mainItemId, stereotypeItems);

          if (request.refresh) {
            return this._requestPostResourceTreeMenuPaginated({parentId: mainItemId, request});
          } else if (this._checkPaginationState(request, pagination!)) {
            return this._requestPostResourceTreeMenuPaginated({parentId: mainItemId, request});
          } else {
            return [TechInventoryActions.requestMenuTreeResourcePaginatedCancel({mainItemId})];
          }
        }
      )
    );
  }

  private _getPaginationResourceByMainItemId(mainItemId: number, items: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>[]): Pagination | null {
    const item = items.find(item => this._techInventoryHelperService.getMainItemId(item.mainItem) === mainItemId);
    if (item) {
      return item.mainItemPaging;
    }
    return null;
  }

  private _requestPostResourceTreeMenuPaginated(action: {
    parentId: number;
    request: InventoryRequestPagination
  }): Observable<(OrgaResponse<ResourceDto[]> | any)> {
    return this._techResourceApiService.postResourceByParentIdPaginated(action.parentId, action.request.pagination, action.request.filters).pipe(
      withLatestFrom(this.store.pipe(select(TechInventorySelectors.selectTechInventoryPageMenuTreeMainListItems))),
      mergeMap(([data, mainItemList]) => {
        const formattedMainListItems = this._formatMainTreeDataWithResources(
          mainItemList,
          action.parentId,
          data
        );
        return action.request.refresh
          ? [TechInventoryActions.requestMenuTreeResourcePaginatedRefresh({
            mainItemId: action.parentId,
            data: formattedMainListItems!
          })]
          : [TechInventoryActions.requestMenuTreeResourcePaginatedSuccess({
            mainItemId: action.parentId,
            data: formattedMainListItems!
          })];
      }),
      catchError((error) => [TechInventoryActions.requestMenuTreeResourcePaginatedFail({
        mainItemId: action.parentId,
        error
      })])
    )
  }

  private _formatMainTreeDataWithResources(
    mainItemList: TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto>[],
    mainItemId: number,
    data: OrgaResponse<ResourceDto[]>
  ): TechInventoryPageMenuTreeItemListMainItem<StereotypeListDto | LocationListDto | ResourceModelListDto> | undefined {
    const foundItem = mainItemList.find(item => this._techInventoryHelperService.getMainItemId(item.mainItem) === mainItemId);
    if (foundItem) {
      return {
        ...foundItem,
        mainItem: foundItem.mainItem,
        mainItemPaging: data.paging,
        resourceItems: [...data.items],
        mainItemIsLoading: false
      };
    }

    return undefined;
  }


}
