import { Injectable } from '@angular/core';
import { AssignedUserEnum } from '@tech/pages/inventory/enums/segment-settings.emun';
import {
  CombineOperator,
  FilterDto,
  FilterKind,
  FilterOperators,
  FilterTypes,
  GroupedFilterDto,
  MissionState,
} from '@server-models';
import {
  InventoryMissionFilterSettings
} from '@tech/pages/inventory/interfaces/mission-filter.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MissionFilterService {
  filters$: Observable<FilterDto[]>;

  filterSettings$: BehaviorSubject<InventoryMissionFilterSettings> =
    new BehaviorSubject<InventoryMissionFilterSettings>({
      assignedUser: AssignedUserEnum.Mine,
      state: MissionState.Instructed,
      stereotypeId: undefined,
      resource: undefined,
    });

  constructor() {
    this.filters$ = this.filterSettings$.pipe(
      map((filterSettings) => this._createFilters(filterSettings))
    );
  }

  /**
   * @name _createFilters
   * @description
   * hold the filter by this.filter and works adding or removing need it on demand
   * @memberof MissionFilterService
   * @param filterSettings
   */
  private _createFilters(
    filterSettings: InventoryMissionFilterSettings
  ): FilterDto[] {
    const filters: (FilterDto | GroupedFilterDto)[] = [];

    switch (filterSettings.assignedUser) {
      case AssignedUserEnum.Mine:
        filters.push(<FilterDto>{
          type: FilterTypes.DataTransferObject,
          property: 'solutionContact',
          operator: FilterOperators.Default,
          kind: FilterKind.Relative,
        });
        break;
      case AssignedUserEnum.Team:
        filters.push(<FilterDto>{
          property: 'solutionContact',
          operator: FilterOperators.NotEqual,
          kind: FilterKind.Relative,
        });
        break;
      case AssignedUserEnum.Unassigned:
        filters.push(<FilterDto>{
          property: 'solutionContact.displayName',
          value: null,
          operator: FilterOperators.Equal,
        });
        break;
      default:
        filters.push(<FilterDto>{
          type: FilterTypes.DataTransferObject,
          property: 'solutionContact',
          operator: FilterOperators.Default,
          kind: FilterKind.Relative,
        });
    }

    if (filterSettings.stereotypeId) {
      filters.push(<FilterDto>{
        property: 'stereotypeId',
        value: filterSettings.stereotypeId.toString(),
        operator: FilterOperators.Equal,
      });
    }

    if (filterSettings.resource) {
      filters.push(<FilterDto>{
        property: 'sourceResource.resourceId',
        value: filterSettings.resource.resourceId!.toString(),
        operator: FilterOperators.Equal,
      });
    }

    if (filterSettings.state) {
      filters.push(<FilterDto>{
        property: 'state',
        value: filterSettings.state,
        operator: FilterOperators.Equal,
      });
    } else {
      filters.push(<GroupedFilterDto>{
        children: [
          <FilterDto>{
            property: 'state',
            value: MissionState.Created.toString(),
          },
          <FilterDto>{
            property: 'state',
            value: MissionState.Instructed.toString(),
          },
        ],
        combinedAs: CombineOperator.Or,
        type: FilterTypes.Grouped,
      });
    }

    return filters;
  }
}

