import {Component, Input} from '@angular/core';
import {
  ContactSimpleDto,
  MissionDto
} from '@server-models';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {SharedCapacitorBrowserService} from '@shared/services/capacitor/browser/shared-capacitor-browser.service';
import {ECoreApiProduct} from '@core/enums/core-api-product.enum';
import {TechAssignEditorApiService} from '@tech/pages/inventory/services/tech-assign-editor-api.service';
import {take} from 'rxjs';
import {
  SharedInputEntitySelectItemComponent
} from "@shared/components/input-entity-select/components/item/shared-input-entity-select-item.component";
import {
  IEntitySelectConfig
} from "@shared/components/input-entity-select/interfaces/input-entity-select-config.interface";
import {
  EInputEntitySelectLabelPlacementOptions
} from "@shared/components/input-entity-select/enums/input-entity-select-label-placement-options.enums";

@Component({
  selector: 'app-tech-inventory-mission-detail-container-assign-editor-modal',
  templateUrl: './tech-inventory-mission-detail-container-assign-editor-modal.component.html',
  styleUrls: ['./tech-inventory-mission-detail-container-assign-editor-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedInputEntitySelectItemComponent],
  providers: [SharedModalControllerService, SharedCapacitorBrowserService],
})
export class TechInventoryMissionDetailContainerAssignEditorModalComponent {
  @Input() model: MissionDto;

  assignContactFn!: (contact?: ContactSimpleDto) => void;

  contactToAssign?: any = undefined;
  isAssignOther: boolean = false;
  isLoadingOnAssignMe: boolean = false;

  contactSelectConfig: IEntitySelectConfig = {
    apiEndpoint: 'contact',
    apiProduct: ECoreApiProduct.Tech,
    idKey: 'contactId',
    displayKey: 'displayName',
    labelPlacement: EInputEntitySelectLabelPlacementOptions.STACKED
  };

  constructor(
    private _assignEditorService: TechAssignEditorApiService,
    private _modalService: SharedModalControllerService
  ) {
    this.model = {};
  }

  toggleIsAssign(): void {
    this.isAssignOther = !this.isAssignOther;
    this.contactToAssign = undefined;
  }

  onAssignEditor(): void {
    const contactId = this.contactToAssign?.contactId;
    const missionId = this.model?.missionId;
    if (contactId && missionId) {
      this._assignEditorService
        .assignContact(missionId, contactId)
        .pipe(take(1))
        .subscribe();
    }
    this.assignContactFn(this.contactToAssign);
    this.onCloseModal();
  }

  onAssignMe(): void {
    const missionId = this.model?.missionId;
    this.isLoadingOnAssignMe = true;
    if (missionId) {
      this._assignEditorService
        .assignMe(missionId)
        .pipe(take(1))
        .subscribe((contact: ContactSimpleDto) => {
            this.assignContactFn(contact);
            this.isLoadingOnAssignMe = false;
            this.onCloseModal();
          }
        );
    }

  }

  onUnassignEditor(): void {
    const missionId = this.model?.missionId;
    if (missionId) {
      this._assignEditorService
        .unassignEditor(missionId)
        .pipe(take(1))
        .subscribe();
    }
    this.assignContactFn(undefined);
    this.onCloseModal();
  }

  onCloseModal(): void {
    this._modalService.closeModal();
  }

  onSendMail(): void {
    window.location.href = `mailto:${ this.model.solutionContact?.email }`;
  }

  onStartPhoneCall(): void {
    window.location.href = `tel:${ this.model.solutionContact?.phone }`;
  }
}
