import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tech-mission-detail-report-success',
  templateUrl: './tech-inventory-mission-detail-report-success.page.html',
  styleUrls: ['./tech-inventory-mission-detail-report-success.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedSafePipe],

})
export class TechInventoryMissionDetailReportSuccessPage implements OnInit {

  isPost: boolean;

  constructor(
    private _router: Router
  ) {
    this.isPost = false;
  }

  ngOnInit() {
    const navigation = this._router.getCurrentNavigation();
    this.isPost = navigation?.extras.state?.['isPost'] || false;
  }

  /**
   * @name back
   * @description
   * navigate to previous segment
   * @memberof TechInventoryMissionDetailReportSuccessPage
   */
  back(): void {
    const urlSegments = this._router.url.split('/');
    urlSegments.pop();
    this._router.navigate(urlSegments);
  }

}
