import {createReducer, on} from "@ngrx/store";
import {TechInfoState} from "@tech/pages/infos/store/tech-info.state";
import {TechInfosActions} from './tech-info.actions-type';
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

function getInitialInfosSate(): TechInfoState {
  const env = CoreEnvironmentService.getEnvironment();
  return {
    page: {},
    items: [],
    paging: {
      totalItems: 0,
      pageNumber: 0,
      pageSize: env?.apiUrl?.pageDefaultSize || 1,
      totalPages: 1
    },
    isLoading: true,
    isLoadingId: NaN
  }
}

export const techInfosStore = createReducer(
  getInitialInfosSate(),
  on(TechInfosActions.postItemsPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TechInfosActions.postItemsPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechInfosActions.postItemsPaginatedSuccess, (state, { data }) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(TechInfosActions.postItemsPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechInfosActions.postItemsPaginatedRefresh, (state, { data }) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(TechInfosActions.postItemsMyRead, (state, data) => ({
    ...state,
    isLoading: false,
    isLoadingId: data.id
  })),
  on(TechInfosActions.postItemsMyReadSuccess, (state, { updatedItems, data }) => ({
      ...state,
      items: updatedItems,
      isLoadingId: undefined,
      isLoading: false
    })
  ),
  on(TechInfosActions.postItemsMyReadFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechInfosActions.postItemsSearchedPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TechInfosActions.postItemsSearchedPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechInfosActions.postItemsSearchedPaginatedSuccess, (state, { data }) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(TechInfosActions.postItemsSearchedPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechInfosActions.postItemsSearchedPaginatedRefresh, (state, { data }) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
);
