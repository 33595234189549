<ion-menu menuId="tech-menu" contentId="main-content" (ionWillOpen)="loadLogo()">
  <ion-header>
    <ion-toolbar mode="ios">
      <ion-item lines="none" *ngIf="logo$ | async">
        <img alt="logo-image" class="image-logo" [src]="(logo$ | async) | safe: 'url'"/>
      </ion-item>
      <ion-item lines="none">
        <ion-icon name="person" slot="start" color="primary"></ion-icon>
        <ion-label >
          <span *ngIf="displayName$ | async" [innerText]="displayName$ | async"></span>
          <span *ngIf="!(displayName$ | async)" [innerText]="'GENERAL.WORDS.EMPTY' | translate"></span>
        </ion-label>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-footer>
    <ion-toolbar class="safe-area"  mode="ios" color="primary">
      <ion-button expand="full" fill="outline" (click)="delete()" *ngIf="deleteEnabled | async"
                  [innerText]="'TECH.SIDEBAR.BUTTONS.DELETE' | translate">
      </ion-button>
      <ion-button expand="full" class="logout-button" (click)="logout()"
                  [innerText]="'TECH.SIDEBAR.BUTTONS.LOGOUT' | translate">
      </ion-button>
    </ion-toolbar>
  </ion-footer>
</ion-menu>
