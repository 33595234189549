import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FilterDto, FilterOperators, InfoListDto, InfoState} from "@server-models";
import {filter, Observable, Subscription} from "rxjs";
import {TranslateModule} from "@ngx-translate/core";
import {
  selectInfoPageList,
  selectInfoPageLoading,
  selectInfoPagePagination
} from "@tech/pages/infos/store/infos.selectors";
import {select, Store} from "@ngrx/store";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {InfosState} from "@tech/pages/infos/store/infos.state";
import {InfoCardComponent} from "@tech/pages/infos/components/info-card/info-card.component";
import {tap} from "rxjs/operators";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {InfosActions} from "@tech/pages/infos/store/infos.actions-type";
import {ButtonTechSideMenuComponent} from "@tech/components/button-tech-side-menu/button-tech-side-menu.component";

@Component({
  selector: 'app-infos',
  templateUrl: './infos.page.html',
  styleUrls: ['./infos.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, InfoCardComponent, ButtonTechSideMenuComponent],
})
export class InfosPage implements OnInit {
  paginationParams: PaginationParams;
  paginationBody: FilterDto[];
  paginationBodyUnread: FilterDto[];
  defaultPaginationBody: FilterDto[];
  ownLoad: boolean;
  infinityLoading: boolean;

  infosData$: Observable<InfoListDto[]>;
  pagingData$: Observable<Pagination>;
  isLoading$: Observable<boolean>;
  displayRead: boolean;

  openSubscriptionList$: Subscription[];

  constructor(
    private _store: Store<InfosState>
  ) {
    this.infosData$ = this._store.pipe(select(selectInfoPageList));
    this.pagingData$ = this._store.pipe(select(selectInfoPagePagination));
    this.isLoading$ = this._store.pipe(select(selectInfoPageLoading));
    this.openSubscriptionList$ = [];
    this.ownLoad = false;
    this.infinityLoading = false;
    this.displayRead = true;
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 10,
      cols: 'Content',
      sortField: 'DateTime',
      sort: 1
    };
    this.defaultPaginationBody = [
      {
        "property": "state",
        "value": InfoState.Pending.toString(),
        "operator": FilterOperators.NotEqual

      }
    ]
    this.paginationBody = [
      {
        "property": "state",
        "value": InfoState.Pending.toString(),
        "operator": FilterOperators.NotEqual
      }
    ];
    this.paginationBodyUnread = [
      {
        "property": "isRead",
        "value": false.toString(),
        "operator": FilterOperators.Equal
      },
      {
        "property": "requestRead",
        "value": true.toString(),
        "operator": FilterOperators.Equal
      }
    ]
  }

  ngOnInit() {
    this.loadInfosByPagination();
  }

  /**
   * @name loadInfosByPagination
   * @description
   * launch the action to load infos by a post params and body
   * @memberof InfosPage
   * @param refresh
   */
  loadInfosByPagination(refresh: boolean = false): void {
    this._store.dispatch((InfosActions.postItemsPaginated({
      params: this.paginationParams,
      body: this.paginationBody,
      refresh
    })));
  }

  /**
   * @name loadMoreInfos
   * @description
   * request the launch once the infinity scroll bottom is reached
   * @memberof InfosPage
   * @param event
   */
  loadMoreInfos(event: any): void {
    this.paginationParams.pageNumber++;
    this.infinityLoading = true;
    this.loadInfosByPagination();
    this.isLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        event.target.complete();
        this.infinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name handleRefresh
   * @description
   * Use to reset the pageNumber param, and call with refresh flag loadInfos
   * @memberof InfosPage
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.ownLoad) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.ownLoad = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadInfosByPagination(true);

    this.isLoading$.pipe(
      filter((loader: boolean) => !loader),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.ownLoad = false;
      })
    ).subscribe();
  }

  /**
   * @name filterInfosByUnread
   * @description
   * change pagination body to pagination body unread to filter by unread infos
   * @memberof InfosPage
   */
  filterInfosByUnread(): void {
    this._resetBodyParams();
    this.paginationBody.push(this.paginationBodyUnread[0], this.paginationBodyUnread[1]);
    this.displayRead = false;
    this.paginationParams.pageNumber = 1;
    this.loadInfosByPagination(true);
  }

  /**
   * @name filterInfosByRead
   * @description
   * change pagination body to pagination by default to filter by read infos
   * @memberof InfosPage
   */
  filterInfosByRead(): void {
    this._resetBodyParams();
    this.displayRead = true;
    this.paginationParams.pageNumber = 1;
    this.loadInfosByPagination(true);
  }

  /**
   * @name _resetBodyParams
   * @description
   * reset the body params to a default pagination body
   * @memberof InfosPage
   * @private
   */
  private _resetBodyParams() {
    this.paginationBody = [this.defaultPaginationBody[0]];
  }
}
