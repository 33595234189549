import {Component, Input, OnInit} from '@angular/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SharedTenantFilterPipe} from '@shared/pipes/shared-tenant-filter.pipe';
import {SharedSearchBarComponent} from '@shared/components/search-bar/shared-search-bar.component';
import {IFilterListModal} from "@shared/interfaces/filter-list-modal.interface";
import {EFilterListType} from "@shared/enums/filter-list-type.enum";

@Component({
  selector: 'app-shared-filter-list-modal',
  templateUrl: './shared-filter-list-modal.component.html',
  styleUrls: ['./shared-filter-list-modal.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    SharedTenantFilterPipe,
    SharedSearchBarComponent
  ],
  standalone: true,
})
export class SharedFilterListModalComponent implements OnInit {
  @Input() filterList: IFilterListModal[] = [];

  protected readonly EFilterListType = EFilterListType;

  constructor(
    private _sharedModalControllerService: SharedModalControllerService
  ) {
  }

  ngOnInit() {
  }

  applyFilterList(): void {
    this.exit(this.filterList);
  }

  exit(data?: any): void {
    this._sharedModalControllerService.closeModal(data);
  }


}
