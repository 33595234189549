import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {FormGroup} from "@angular/forms";
import {
  IFormReport
} from "@tech/pages/inventory/components/mission/components/detail/interfaces/form-report.interface";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryFormReportService {

  formReportSubject: BehaviorSubject<IFormReport>;

  constructor() {
    this.formReportSubject = new BehaviorSubject<IFormReport>(<IFormReport>{
      form: new FormGroup<any>({}),
      formattedData: undefined,
      hasReportId: false
    });
  }

  resetFormReport() {
    this.formReportSubject.next(<IFormReport>{
      form: new FormGroup<any>({}),
      formattedData: undefined,
      hasReportId: false
    })
  }

}
