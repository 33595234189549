import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TechLoginSelectors} from "@tech/pages/login/store/tech-login.selector-type";
import {SideBarComponent} from "@tech/components/side-bar/side-bar.component";

@Component({
  selector: 'app-tech',
  templateUrl: './tech-page.component.html',
  styleUrls: ['./tech-page.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SideBarComponent]
})
export class TechPage implements OnInit {

  isLoading$!: Observable<boolean>;
  currentLogin$!: Observable<boolean>;
  constructor(
    private _store: Store
  ) {
    this.isLoading$ = this._store.select(TechLoginSelectors.selectIsLoading);

  }

  ngOnInit() {
    this.currentLogin$ = this._store.pipe(select(TechLoginSelectors.selectCurrentLogin), map((login) => !!login));
  }
}
