import {ObservableStore} from '@codewithdan/observable-store';
import {FilterDto} from '@server-models';
import {lastValueFrom, Observable, take} from 'rxjs';
import {IPagination} from '@shared/interfaces/pagination.interface';
import {map} from 'rxjs/operators';
import {InputEntitySelectStoreState} from '../interfaces/input-entity-select-store-state.interface';
import {IListResponseDto} from '@shared/interfaces/list-response.interface';
import {
  SharedInputEntitySelectApiService
} from "@shared/components/input-entity-select/services/shared-input-entity-select-api.service";
import {
  IEntitySelectConfig
} from "@shared/components/input-entity-select/interfaces/input-entity-select-config.interface";

const initialState: InputEntitySelectStoreState = {
  items: [],
  selected: undefined,
  paging: undefined,
  loading: false
};

export class SharedInputEntitySelectObservableStore extends ObservableStore<any> {

  constructor(
    private _apiService: SharedInputEntitySelectApiService,
    private _config?: IEntitySelectConfig
  ) {
    super({ trackStateHistory: true, logStateChanges: false });
    this.setState(initialState, 'INIT_STATE');
  }

  getFirstPage(filters: FilterDto[] = []): void {
    const paging: IPagination = {
      pageSize: 20,
      pageNumber: 1,
      totalPages: 0,
      totalItems: 0
    };
    this.setState({ ...this.getState(), loading: true }, 'GET_FIRST_PAGE');
    this._getPage(filters, paging).then((response: IListResponseDto<any>) =>
      this._getPageSuccess(response)
    );
  }

  async appendPage(filters: FilterDto[] = []): Promise<IPagination> {
    this.setState({ ...this.getState(), loading: true }, 'APPEND_PAGE');

    const paging = await lastValueFrom(this.selectPaging().pipe(take(1)));
    paging.pageNumber = paging.pageNumber + 1;

    await this._getPage(filters, paging).then((response: IListResponseDto<any>) =>
      this._getPageSuccess(response)
    );
    return paging;
  }

  setCurrentItem(item?: Object): void {
    const currentState = this.getState();
    const newState = {
      selected: item
    };
    this.setState({ ...currentState, ...newState }, 'SET_CURRENT_ITEM');
  }

  clearItems(): void {
    const currentState = this.getState();
    const newState = {
      items: [],
      currentItem: undefined
    };
    this.setState({ ...currentState, ...newState }, 'CLEAR_ITEMS');
  }

  public selectItems(): Observable<any[]> {
    return this.stateChanged.pipe(map((state) => state.items));
  }

  public selectCurrentItem(): Observable<Object> {
    return this.stateChanged.pipe(map((state) => state.selected));
  }

  public selectPaging(): Observable<IPagination> {
    return this.stateChanged.pipe(map((state) => state.paging));
  }

  public selectLoading(): Observable<boolean> {
    return this.stateChanged.pipe(map((state) => state.loading));
  }

  private async _getPage(
    filters: FilterDto[] = [],
    paging?: IPagination
  ): Promise<any> {
    return this._apiService.getPage(filters, this._config, paging);
  }

  private _getPageSuccess(response: IListResponseDto<any>): void {
    const currentState = this.getState();
    const newState = {
      loading: false,
      items:
        response?.paging?.pageNumber > 1
          ? [...currentState.items, ...response.items]
          : response?.items ?? [],
      paging: response?.paging
    };
    this.setState({ ...currentState, ...newState }, 'GET_PAGE_SUCCESS');
  }
}
