import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {filter, take, withLatestFrom} from "rxjs";
import {select, Store} from "@ngrx/store";
import {KnowledgeArticleStartDto} from "@server-models";
import {map} from "rxjs/operators";
import {TechKnowledgeSelectors} from "@tech/pages/knowledge/store/tech-knowledge.selectors-type";
import {TechKnowledgeState} from "@tech/pages/knowledge/store/tech-knowledge.state";
import {KnowledgeActions} from "@tech/pages/knowledge/store/tech-knowledge.actions-type";

export const techKnowledgeDetailResolver: ResolveFn<KnowledgeArticleStartDto[] | null | undefined> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store: Store<TechKnowledgeState> = inject(Store);
    const currentId: string = route.paramMap.get('id')!;
    store.dispatch((KnowledgeActions.getPathItemsByIdStart({
      id: currentId,
      isRefresh: false
    })));

    return store.pipe(
      select(TechKnowledgeSelectors.selectArticlesPathStart),
      withLatestFrom(store.pipe(select(TechKnowledgeSelectors.selectKnowledgePathLoading))),
      filter(([data, loading]) => !!data && !loading),
      take(1),
      map(([data]) => {
        return data?.sort((a, b) => a.position! - b.position!);
      })
    );
  };
