<!--[class.badge-malfunction]="mission.type === 'Malfunction'"-->
<!--[class.badge-maintenance]="mission.type === 'Maintenance'"-->
<ion-card *ngFor="let mission of missionList" button
          (click)="navigateToDetail(mission)"
>
  <ion-card-header>
    <ion-item lines="none" detail class="missions">
      <ion-label class="missions">
        <ion-text>
          <h1>
            {{ mission.title }}
          </h1>
        </ion-text>
        <ion-text color="medium">
          <p class="ion-text-uppercase">
            <span class="margin-right">#{{ mission.missionId }}</span>
            <span class="margin-right">
              {{ 'COMPONENTS.MISSIONS.PLANNED' | translate }}
            </span>
            <span>
              {{ formatDates(mission.plannedStart, mission.plannedEnd) }}
            </span>
          </p>
        </ion-text>
      </ion-label>
      <ion-chip class="ion-hide-md-down" slot="end">
        <app-mission-state-options [stateOption]="mission.state!"></app-mission-state-options>
      </ion-chip>
    </ion-item>
  </ion-card-header>

  <ion-card-content>
    <ion-item lines="none" class="missions">
      <ion-chip>
        <ion-icon name="hammer"></ion-icon>
        <ion-label>
          <ion-text color="medium" class="margin-right">{{ mission.sourceResource?.resourceId }}</ion-text>
          <ion-text>{{ mission.sourceResource?.name }}</ion-text>
        </ion-label>
      </ion-chip>
      <ion-chip *ngIf="mission.location || mission.locationNumber">
        <ion-icon name="location"></ion-icon>
        <ion-label>
          <ion-text *ngIf="mission.locationNumber" color="medium" class="margin-right">{{ mission.locationNumber }}
          </ion-text>
          <ion-text *ngIf="mission.location">{{ mission.location }}</ion-text>
        </ion-label>
      </ion-chip>
    </ion-item>
  </ion-card-content>
</ion-card>
