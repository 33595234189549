import {createFeatureSelector, createSelector} from "@ngrx/store";
import {KnowledgePathStartDto} from "@server-models";
import {KnowledgeState} from "@tech/pages/knowledge/store/knowledge.state";

export const selectKnowledgeStore = createFeatureSelector<KnowledgeState>('techKnowledge');

export const selectKnowledgePathList = createSelector(
  selectKnowledgeStore,
  (state: KnowledgeState) => state.items
);

export const selectKnowledgePathPagination = createSelector(
  selectKnowledgeStore,
  (state: KnowledgeState) => state.paging
);

export const selectKnowledgePathStart = createSelector(
  selectKnowledgeStore,
  (state: KnowledgeState) => state.itemsStart
);

export const selectArticlesPathStart = createSelector(
    selectKnowledgePathStart,
    (pathStart: KnowledgePathStartDto) => pathStart.articles
)

export const selectKnowledgePathLoading = createSelector(
  selectKnowledgeStore,
  (state: KnowledgeState) => state.isLoading
);

export const selectArticlePage = createSelector(
  selectKnowledgeStore,
  (state: KnowledgeState) => state.articlePage
);
