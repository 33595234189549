import {createReducer, on} from "@ngrx/store";
import {TechLoginState} from "./tech-login.state";
import {TechLoginActions} from "@tech/pages/login/store/tech-login.actions-type";
import {EAppType} from "@shared/models/AppType.enum";

export const initialState: TechLoginState = <TechLoginState>{ currentApp: EAppType.Tech };

export const techLoginStore = createReducer(
  initialState,
  on(TechLoginActions.loadStorage, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TechLoginActions.loadStorageSuccess, (state, {
      tenantDisplayName,
      isGuest,
      tenantSettings,
      tenantId,
      tenantLanguage,
      tokenDto,
      app,
      isLogging
    }) => ({
      ...state,
      currentTenant: {
        tenantDisplayName,
        isGuest,
        tenantSettings,
        tenantId,
        tenantLanguage,
      },
      app: tokenDto,
      currentApp: app,
      isLogged: !!tokenDto,
      isLogging
    })
  ),
  on(TechLoginActions.loadStorageFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.initSuccess, (state, { token, app, currentTenant, isLogging }) => ({
    ...state,
    currentApp: app,
    app: token,
    currentTenant,
    isLoading: true,
    isLogging: isLogging!
  })),
  on(TechLoginActions.initFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.byPassword, (state, { login, password, passwordBase64 }) => ({
    ...state,
    currentLogin: {
      email: login,
      passwordBase64: password ? btoa(password!) : passwordBase64!
    },
    isLoading: true
  })),
  on(TechLoginActions.byPasswordSuccess, (state, { token }) => ({
      ...state,
      app: token,
      isLogging: true,
      isLoading: true
    })
  ),
  on(TechLoginActions.byPasswordFail, (state) => ({
    ...state,
    isLogging: false,
    isLoading: false
  })),
  on(TechLoginActions.loginRefresh, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TechLoginActions.loginRefreshSuccess, (state, { tokenDto }) => ({
    ...state,
    app: tokenDto,
    isLoading: true
  })),
  on(TechLoginActions.loginRefreshFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.changePassword, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TechLoginActions.changePasswordSuccess, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.changePasswordFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.successMultiTenant, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TechLoginActions.successUniqueTenant, (state, { currentTenant, isLogging }) => ({
    ...state,
    currentTenant,
    isLoading: true,
    isLogging
  })),
  on(TechLoginActions.cancelSelectedTenant, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.successSelectedTenant, (state, { tenantId }) => ({
    ...state,
    currentTenant: {
      tenantId
    },
    isLoading: true
  })),
  on(TechLoginActions.fetchTenantSettings, (state, { tenantId }) => ({
    ...state,
    currentTenant: {
      tenantId
    },
    isLoading: true
  })),
  on(TechLoginActions.fetchTenantSettingsCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.fetchTenantSettingsSuccess, (state, { currentTenant }) => ({
      ...state,
      currentTenant,
      isLoading: true
    })
  ),
  on(TechLoginActions.fetchTenantSettingsFail, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.isLoggingNavigationDone, (state) => ({
      ...state,
      isLogging: false,
      isLoading: false
    })
  ),
  on(TechLoginActions.isLoggingNavigationCanceled, (state) => ({
      ...state,
      isLogging: false,
      isLoading: true
    })
  ),
  on(TechLoginActions.initRegistrationGuest, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId
  })),
  on(TechLoginActions.initRegistrationGuestDialogClose, (state) => ({
    ...state,
    isLoading: false,
    isLoadingId: ""
  })),
  on(TechLoginActions.initRegistrationGuestDialogClose, (state) => ({
    ...state,
    isLoading: false,
    isLoadingId: ""
  })),
  on(TechLoginActions.registerGuestPinRequest, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId: isLoadingId!
  })),
  on(TechLoginActions.registerGuestPinRequestSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(TechLoginActions.registerGuestPinRequestFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(TechLoginActions.registerGuestAsUser, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId: isLoadingId!
  })),
  on(TechLoginActions.registerGuestAsUserSuccess, (state) => ({
    ...state,
    isLoading: true,
    isLogging: true
  })),
  on(TechLoginActions.registerGuestAsUserFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(TechLoginActions.resetPassword, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId
  })),
  on(TechLoginActions.resetPasswordSuccess, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.resetPasswordFail, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.resetRequestPassword, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId
  })),
  on(TechLoginActions.resetRequestPasswordSuccess, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TechLoginActions.resetRequestPasswordFail, (state, {}) => ({
    ...state,
    isLoading: false
  }))
);

