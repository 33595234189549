import {Injectable} from "@angular/core";
import {TechHttpClientBaseApiService} from "@tech/services/http-client-base-api/tech-http-client-base-api.service";
import {RequestOptions} from "@shared/interfaces/request-options.interface";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {Observable} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {ResourceDto} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class TechResourceApiService extends TechHttpClientBaseApiService {

  postResourceByParentIdPaginated(parentId: number, paramsPaginated: PaginationParams, body: any): Observable<OrgaResponse<ResourceDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        ParentId: parentId.toString()
      }
    }
    return super.post<OrgaResponse<ResourceDto[]>>(`/tech/resource/inheritable/filter`, undefined, body, options);
  }
}
