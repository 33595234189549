import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-tech-inventory-mission-type-option-input',
  templateUrl: './tech-inventory-mission-type-option-input.component.html',
  styleUrls: ['./tech-inventory-mission-type-option-input.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],

})
export class TechInventoryMissionTypeOptionInputComponent implements OnInit {

  @Input() stereotypeNameType: string;

  constructor() {
    this.stereotypeNameType = ""
  }

  ngOnInit() {
  }

}
