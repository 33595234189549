<ion-header>
  <ion-toolbar color="primary">
    <ion-title [innerText]="'LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.HEADER_TITLE' | translate">
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="exit()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ 'LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.SALUTE' | translate }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content class="card-padding">
      <span>{{ 'LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.DESCRIPTION' | translate }}</span>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-card-content>
      <app-tech-login-request-register-user-form [existingEmail]="currentEmail"
                                                 (formChange)="onFormChange($event)"></app-tech-login-request-register-user-form>
    </ion-card-content>
  </ion-card>
  <ion-card class="ion-no-padding card-button">
    <ng-container *ngIf="!(checkLoadingButton(buttonId)| async)">
      <ion-button [disabled]="this.registerUserShadowForm.invalid"
                  class="submit" expand="block" (click)="submit()">
        {{ 'LOGIN.REGISTER_GUEST.DIALOGS.REQUEST_REGISTER.BUTTONS.SUBMIT' | translate }}
      </ion-button>
    </ng-container>
    <ng-container *ngIf="(checkLoadingButton(buttonId) | async)">
      <ion-button [disabled]="true"
                  class="submit" expand="block">
        <ion-spinner></ion-spinner>
      </ion-button>
    </ng-container>
  </ion-card>
</ion-content>
