import {Component, OnInit} from '@angular/core';
import {MissionDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TechInventorySelectors} from "@tech/pages/inventory/store/tech-inventory.selector-type";
import {Observable, of} from "rxjs";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {SharedCacheService} from "@shared/services/cache/shared-cache.service";
import {TechIssuesSelectors} from "@tech/pages/issues/store/tech-issue.selector-type";
import {
  SharedCustomPropertySetsReadOnlyCardComponent
} from "@shared/components/custom-property-sets-read-only-card/shared-custom-property-sets-read-only-card.component";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";

@Component({
  selector: 'app-tech-inventory-mission-detail-issue-detail',
  templateUrl: './tech-inventory-mission-detail-issue-detail.page.html',
  styleUrls: ['./tech-inventory-mission-detail-issue-detail.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    SharedCustomPropertySetsReadOnlyCardComponent
  ],

})
export class TechInventoryMissionDetailIssueDetailPage implements OnInit {
  missionDetail$: Observable<MissionDto | undefined>;
  inventoryIsLoading$: Observable<boolean>;
  issueFormattedData$: Observable<ICustomPropertyStructureFormatted>;
  issueFormattedDataIsLoading$: Observable<boolean>;

  currentMissionDetail: MissionDto | undefined;

  constructor(
    private _store: Store,
    private _cacheService: SharedCacheService
  ) {
    this.missionDetail$ = of();
    this.inventoryIsLoading$ = of();
    this.issueFormattedData$ = of();
    this.issueFormattedDataIsLoading$ = of();
    this.currentMissionDetail = undefined;
  }

  ngOnInit() {
    this.missionDetail$ = this._store.pipe(select(TechInventorySelectors.selectTechInventoryPageMissionDetail));
    this.missionDetail$.subscribe((missionData) => {
      if (missionData) {
        const primaryIssue = missionData?.origin?.primaryIssue;
        if (primaryIssue?.issueId) {
          if (missionData.missionId) {
            this._store.dispatch(SharedIssueBaseActions.navigateToDetail({
              issueId: primaryIssue.issueId!,
              issueType: primaryIssue.issueType!,
              cacheGuid: this._cacheService.generateGuid(),
              isNavigationRequired: false
            }));
          }
        }
      }
    });
    this.issueFormattedData$ = this._store.pipe(select(TechIssuesSelectors.selectDetailDataFormatted));
    this.issueFormattedDataIsLoading$ = this._store.pipe(select(TechIssuesSelectors.selectIsLoading));
  }
}
