import {Injectable} from '@angular/core';
import {IRequestOptions} from '@shared/interfaces/request-options.interface';
import {Observable} from 'rxjs';
import {IOrgaResponse} from '@shared/interfaces/orga-response.interface';
import {
  FilterDto,
  MissionDistanceListDto,
  PointListDto,
} from '@server-models';
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class TechInventoryMissionByDistanceApiService extends CoreHttpClientApiBaseService {
  getMissionsByDistance(
    origin: PointListDto,
    filter: FilterDto[] = [],
    distance: number = 100000,
    pageNumber: number = 0,
    pageSize: number = 100
  ): Observable<IOrgaResponse<MissionDistanceListDto[]>> {
    const options: IRequestOptions = {
      params: {
        lat: origin?.lat?.toString() ?? '',
        lng: origin?.lng?.toString() ?? '',
        dist: distance.toString(),
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
      },
    };
    return this.post<IOrgaResponse<MissionDistanceListDto[]>>(
      `/tech/mission/bydistance/filter`,
      true,
      filter,
      options
    );
  }
}
