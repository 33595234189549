import {Component, OnInit} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {Storage} from "@ionic/storage-angular";
import {Store} from "@ngrx/store";
import {appInit} from "@core/store/app.actions";
import {environment} from "@env-tech/environment";
import {LanguageService} from "@shared/services/language.service";

@Component({
  selector: 'app-root',
  templateUrl: 'tech-app.component.html',
  styleUrls: ['tech-app.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class TechAppComponent implements OnInit {


  constructor(
    private _storage: Storage,
    private _store: Store,
    private _languageService: LanguageService
  ) {
    this._languageService.setDefaultLanguageByEnvironment(environment);
  }

  async ngOnInit() {
    this._store.dispatch(appInit());
    await this._storage.create();
  }
}
