import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {mergeMap, Observable, throwError, withLatestFrom} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {KnowledgeArticleListDto, KnowledgePathListDto, KnowledgePathStartDto} from "@server-models";
import {
  selectKnowledgePathPagination,
  selectKnowledgePathStart
} from "@tech/pages/knowledge/store/knowledge.selectors";
import {catchError, map} from "rxjs/operators";
import {KnowledgeActions} from "@tech/pages/knowledge/store/knowledge.actions-type";
import {KnowledgePathStartIdRequest} from "../interfaces/knowledge-path-start-id-request.interface";
import {Pagination} from "@shared/interfaces/pagination.interface";
import {
  KnowledgePathRequestPagination
} from "@tech/pages/knowledge/interfaces/knowledge-path-request-pagination.interface";
import {
  AttachmentKnowledgeArticle
} from "@shared/components/attachments/interfaces/attachment-knowledge-article.interface";
import {selectAttachments} from "@shared/components/attachments/store/attachments.selectors";
import {TechKnowledgeEffectsBase} from "@tech/pages/knowledge/store/knowledge.effects";
import {TechKnowledgeService} from "@tech/pages/knowledge/services/tech-knowledge.service";

@Injectable({
  providedIn: 'root'
})
export class TechKnowledgeApiEffects extends TechKnowledgeEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _techKnowledgeService: TechKnowledgeService
  ) {
    super(store, actions$);
  }

  /**
   * @name getPath
   * @description
   * Receive the action started on TechKnowledgeEffectsBase and use the latest state from knowledge.pagination
   * to check if there is more pages available and call a function to request knowledge path data
   * @memberof TechKnowledgeApiEffects
   * @param action
   * @return {Observable<(OrgaResponse<KnowledgePathListDto[]> | any)>}
   */
  getPath(action: Observable<KnowledgePathRequestPagination>): Observable<(OrgaResponse<KnowledgePathListDto[]> | any)> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(selectKnowledgePathPagination))),
      mergeMap(([action, paginationState]) => {
          if (action.refresh) {
            return this._requestGetPath(action)
          } else if (this._checkPaginationState(action, paginationState)) {
            return this._requestGetPath(action)
          } else {
            return [KnowledgeActions.getPathItemsPagedCancel()];
          }
        }
      )
    )
  }

  /**
   * @name getPathItemsByIdStart
   * @description
   * Receive the action started on TechKnowledgeEffectsBase and use the latest state from knowledge path using id
   * to retrieve knowledge start by id data
   * @memberof TechKnowledgeApiEffects
   * @param action
   * @return {Observable<KnowledgePathListDto[] | any>}
   */
  getPathItemsByIdStart(action: Observable<KnowledgePathStartIdRequest>): Observable<KnowledgePathListDto[] | any> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(selectKnowledgePathStart))),
      mergeMap(([action]) => this._requestGetPathItemByIdStart(action))
    );
  }

  getItemsSearched(action: Observable<{ toSearch: string }>): Observable<OrgaResponse<KnowledgeArticleListDto[]> | any> {
    return action.pipe(
      mergeMap((action) => {
        return this._requestItemsSearched(action)
      })
    )
  }

  getArticlePage(action: Observable<{articleId: number}>): Observable<AttachmentKnowledgeArticle> {
    return action.pipe(
      withLatestFrom(this.store.pipe(select(selectAttachments))),
      mergeMap(([action]) => this._requestArticlePage(action))
    );
  }

  /**
   * @name _requestItemsSearched
   * @description
   * request the data to the document service to search by text
   * @memberof TechKnowledgeApiEffects
   * @param action
   * @private
   */
  private _requestItemsSearched(action: { toSearch: string }): Observable<OrgaResponse<KnowledgeArticleListDto[]> | any> {
    return this._techKnowledgeService.getBySearch(action.toSearch!).pipe(
      mergeMap((data: OrgaResponse<KnowledgeArticleListDto[]>) => {
        return [KnowledgeActions.getItemsSearchedSuccess({data})]
      }),
      catchError((error) => {
        return [KnowledgeActions.getItemsSearchedFail({error})]
      })
    )
  }


  /**
   * @name _checkPaginationState
   * @description
   * Check if there is not paging information or if the pageNumber in the action is
   * less than or equal to the total number of pages available in the paginationState
   * @memberof TechKnowledgeApiEffects
   * @param action
   * @param state
   * @private
   * @return {boolean}
   */
  private _checkPaginationState(action: KnowledgePathRequestPagination, state: Pagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  /**
   * @name _requestGetPath
   * @description
   * Request the data to TechKnowledgeService and map the value to launch and pass throw the action need it
   * @memberof TechKnowledgeApiEffects
   * @param action
   * @private
   * @return {Observable<OrgaResponse<KnowledgePathListDto[]> | any>}
   */
  private _requestGetPath(action: KnowledgePathRequestPagination): Observable<OrgaResponse<KnowledgePathListDto[]> | any> {
    return this._techKnowledgeService.getPathItems(action.params).pipe(
      map((data: OrgaResponse<KnowledgePathListDto[]>) => {
        return action.refresh
          ? KnowledgeActions.getPathItemsPagedRefresh({data})
          : KnowledgeActions.getPathItemsPagedSuccess({data});
      }),
      catchError((error) => {
        return throwError(() => KnowledgeActions.getPathItemsPagedFail({error}));
      })
    );
  }

  /**
   * @name _requestGetPathItemByIdStart
   * @description
   * Request the data to TechKnowledgeService by id and map the value to launch and pass throw the action need it
   * @memberof TechKnowledgeApiEffects
   * @param action
   * @private
   * @return {Observable<KnowledgePathStartDto | any>}
   */
  private _requestGetPathItemByIdStart(action: KnowledgePathStartIdRequest): Observable<KnowledgePathStartDto | any> {
    return this._techKnowledgeService.getPathItemByIdStart(action.id).pipe(
      map((data: KnowledgePathStartDto) => {
        return action.refresh
          ? KnowledgeActions.getPathItemsByIdStartRefresh({data})
          : KnowledgeActions.getPathItemsByIdStartSuccess({data})
      }),
      catchError((error) => [KnowledgeActions.getPathItemsByIdStartFail({error})])
    );
  }

  /**
   * @name _requestArticlePage
   * @param action
   * @private
   */
  private _requestArticlePage(action: { articleId: number }): Observable<AttachmentKnowledgeArticle | any> {
    return this._techKnowledgeService.getArticle(action.articleId!).pipe(
      map((data) => KnowledgeActions.getArticlePageSuccess({data: data})
      ),
      catchError((error) => [KnowledgeActions.getArticlePageFail({error})]
      )
    );
  }

}
