import {createFeatureSelector, createSelector} from "@ngrx/store";
import {KnowledgePathStartDto} from "@server-models";
import {TechKnowledgeState} from "@tech/pages/knowledge/store/tech-knowledge.state";

export const techKnowledgeStoreSelect = createFeatureSelector<TechKnowledgeState>('techKnowledge');

export const selectKnowledgePathList = createSelector(
  techKnowledgeStoreSelect,
  (state: TechKnowledgeState) => state.items
);

export const selectKnowledgePathPagination = createSelector(
  techKnowledgeStoreSelect,
  (state: TechKnowledgeState) => state.paging
);

export const selectKnowledgePathStart = createSelector(
  techKnowledgeStoreSelect,
  (state: TechKnowledgeState) => state.itemsStart
);

export const selectArticlesPathStart = createSelector(
  selectKnowledgePathStart,
  (pathStart: KnowledgePathStartDto) => pathStart.articles
)

export const selectKnowledgePathLoading = createSelector(
  techKnowledgeStoreSelect,
  (state: TechKnowledgeState) => state.isLoading
);

export const selectArticlePage = createSelector(
  techKnowledgeStoreSelect,
  (state: TechKnowledgeState) => state.articlePage
);
