<div class="ion-page" id="mission" *ngIf="missionDetail$ | async as missionDetail">
  <ion-header [translucent]="true">
    <ion-toolbar color="primary">
      <ion-title>{{ formatTitle(missionDetail.missionId!) }}</ion-title>
      <ion-buttons slot="start">
        <ion-button (click)="back()">
          <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="primary">
      <ion-segment (ionChange)="missionPagesService.changeSegmentSetting($event.detail.value)" [(ngModel)]="missionPagesService.selectedSegment">
        <ion-segment-button [value]="AssignedUserEnum.Mine">
          <ion-label>
            {{ 'COMPONENTS.MISSIONS.DETAILS.SEGMENTS.VIEW' | translate }}
          </ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="AssignedUserEnum.Unassigned">
          <ion-label>
            {{ 'COMPONENTS.MISSIONS.DETAILS.SEGMENTS.REPORT' | translate }}
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-spinner *ngIf="isLoading$ | async"></ion-spinner>

    <router-outlet *ngIf="!(isLoading$ | async)"></router-outlet>
  </ion-content>
</div>
