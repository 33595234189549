import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {InputEmailComponent} from "@shared/components/custom-property/inputs/email/input-email.component";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {ShowHidePasswordComponent} from "@features/show-hide-password/show-hide-password.component";
import {techPasswordMatchValidator} from "@tech/pages/change-password/custom-validators/passwords-match.validator";

@Component({
  selector: 'app-register-user-form',
  templateUrl: './register-user-form.component.html',
  styleUrls: ['./register-user-form.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SafePipe,
    UserHtmlComponent,
    InputEmailComponent,
    InputTextComponent,
    ShowHidePasswordComponent
  ],
  standalone: true
})
export class RegisterUserFormComponent implements OnInit {

  @Input() existingEmail: string;
  registerUserForm: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;
  @Output() submitChange: EventEmitter<FormGroup>;

  constructor(
    private _modalControllerService: ModalControllerService,
    public formBuilder: FormBuilder,
  ) {
    this.existingEmail = "";
    this.registerUserForm = new FormGroup({});
    this.formChange = new EventEmitter<FormGroup>();
    this.submitChange = new EventEmitter<FormGroup>();
  }

  getControl(controlName: string) {
    return this.registerUserForm.get(controlName) as FormControl;
  }

  ngOnInit() {
    this.initializeRegisterPasswordForm();
    this.registerUserForm.valueChanges.subscribe(() => {
      this.formChange.emit(this.registerUserForm);
    });
  }

  /**
   * @name initializeRegisterPasswordForm
   * @description
   * init register password form
   * @memberof RegisterUserFormComponent
   */
  initializeRegisterPasswordForm() {
    this.registerUserForm = this.formBuilder.group({
      email: [this.existingEmail ? this.existingEmail : "", Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    }, {validators: [techPasswordMatchValidator('password', 'confirmPassword')]});
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof RegisterUserFormComponent
   */
  exit(): void {
    this._modalControllerService.closeModal();
  }

  /**
   * @name submitForm
   * @description
   * emit the changes
   * @memberof RegisterUserFormComponent
   */
  submitForm() {
    this.submitChange.emit(this.registerUserForm);
  }

  /**
   * @name formatPin
   * @description
   * format the pin to a full number
   * @memberof RegisterUserFormComponent
   * @param pinArray
   * @returns {number}
   */
  formatPin(pinArray: number[]): number {
    return parseInt(pinArray.join(''));
  }

  /**
   * @name isRequired
   * @description
   * check if a control is required
   * @memberof RegisterUserFormComponent
   * @param controlName
   */
  isRequired(controlName: string): boolean {
    const control = this.registerUserForm.get(controlName);
    if (control) {
      const validator = control.validator ? control.validator({} as any) : null;
      return !!(validator && validator['required']);
    }
    return false;
  }
}
