import {Injectable} from '@angular/core';
import {EAssignedUser} from '@tech/pages/inventory/enums/assigned-user.emun';
import {
  CombineOperator,
  FilterDto,
  FilterKind,
  FilterOperators,
  FilterTypes,
  GroupedFilterDto,
  MissionState,
} from '@server-models';
import {IInventoryMissionFilterSettings} from '@tech/pages/inventory/interfaces/mission-filter.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ETreeMenuSegment} from "@tech/pages/inventory/enums/tree-menu-segment.enum";

@Injectable({
  providedIn: 'root',
})
export class TechMissionFilterService {
  filters$: Observable<FilterDto[]>;

  filterSettingsSubject: BehaviorSubject<IInventoryMissionFilterSettings> =
    new BehaviorSubject<IInventoryMissionFilterSettings>({
      assignedUser: EAssignedUser.Mine,
      state: MissionState.Instructed,
      stereotypeId: undefined,
      resource: undefined,
      titleToSearch: undefined,
      parentResource: undefined,
      onlyList: {
        sortBy: undefined,
        locationNumber: undefined,
      },
    });

  constructor() {
    this.filters$ = this.filterSettingsSubject.pipe(
      map((filterSettings) => this._createFilters(filterSettings))
    );
  }

  clearOnlyList() {
    this.filterSettingsSubject.next({
        ...this.filterSettingsSubject.getValue(),
        onlyList: {
          sortBy: undefined,
          locationNumber: undefined
        }
      }
    )
  }

  /**
   * @name _createFilters
   * @description
   * hold the filter by this.filter and works adding or removing need it on demand
   * @memberof TechMissionFilterService
   * @param filterSettings
   */
  private _createFilters(
    filterSettings: IInventoryMissionFilterSettings
  ): FilterDto[] {
    const filters: (FilterDto | GroupedFilterDto)[] = [];

    switch (filterSettings.assignedUser) {
      case EAssignedUser.Mine:
        filters.push(<FilterDto>{
          type: FilterTypes.DataTransferObject,
          property: 'solutionContact',
          operator: FilterOperators.Default,
          kind: FilterKind.Relative,
        });
        break;
      case EAssignedUser.Team:
        filters.push(<FilterDto>{
          property: 'solutionContact',
          operator: FilterOperators.NotEqual,
          kind: FilterKind.Relative,
        });
        break;
      case EAssignedUser.Unassigned:
        filters.push(<FilterDto>{
          property: 'solutionContact.displayName',
          value: null,
          operator: FilterOperators.Equal,
        });
        break;
      default:
        filters.push(<FilterDto>{
          type: FilterTypes.DataTransferObject,
          property: 'solutionContact',
          operator: FilterOperators.Default,
          kind: FilterKind.Relative,
        });
    }

    if (filterSettings.titleToSearch) {
      filters.push(<FilterDto>{
        property: 'title',
        value: filterSettings.titleToSearch,
        operator: FilterOperators.Contains,
      })
    }

    if (filterSettings.parentResource) {
      if (filterSettings.parentResource.type && filterSettings.parentResource.id) {
        switch (filterSettings.parentResource.type) {
          case ETreeMenuSegment.Type:
            filters.push(<FilterDto>{
              property: 'sourceResource.stereotypeId',
              value: filterSettings.parentResource.id.toString(),
              operator: FilterOperators.Equal
            });
            break;
          case ETreeMenuSegment.Location:
            filters.push(<FilterDto>{
              property: 'location.locationId',
              value: filterSettings.parentResource.id.toString(),
              operator: FilterOperators.Equal
            });
            break;
          case ETreeMenuSegment.Model:
            filters.push(<FilterDto>{
              property: 'sourceResource.model.resourceModelId',
              value: filterSettings.parentResource.id.toString(),
              operator: FilterOperators.Equal
            });
            break;
          default:
            filters.push(<FilterDto>{
              property: 'location.locationId',
              value: filterSettings.parentResource.id.toString(),
              operator: FilterOperators.Equal
            });
        }
      }
    }

    if (filterSettings.stereotypeId) {
      filters.push(<FilterDto>{
        property: 'stereotypeId',
        value: filterSettings.stereotypeId.toString(),
        operator: FilterOperators.Equal,
      });
    }


    if (filterSettings.resource) {
      filters.push(<FilterDto>{
        property: 'sourceResource.resourceId',
        value: filterSettings.resource.resourceId!.toString(),
        operator: FilterOperators.Equal,
      });
    }

    if (filterSettings.state) {
      filters.push(<FilterDto>{
        property: 'state',
        value: filterSettings.state,
        operator: FilterOperators.Equal,
      });
    } else {
      filters.push(<GroupedFilterDto>{
        children: [
          <FilterDto>{
            property: 'state',
            value: MissionState.Created.toString(),
          },
          <FilterDto>{
            property: 'state',
            value: MissionState.Instructed.toString(),
          },
        ],
        combinedAs: CombineOperator.Or,
        type: FilterTypes.Grouped,
      });
    }

    if (filterSettings.onlyList) {
      if (filterSettings.onlyList.locationNumber) {
        filters.push(<FilterDto>{
          property: 'locationNumber',
          value: filterSettings.onlyList.locationNumber,
          operator: FilterOperators.Equal
        })
      }
    }

    return filters;
  }
}

